export const UPDATE_LOADING = '@@app/UPDATE_LOADING';

export interface AppState {
  loading: boolean;
}

export interface UpdateLoadingAction {
  type: typeof UPDATE_LOADING;
  newValue: boolean;
}

export type AppActionTypes = UpdateLoadingAction;
