import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk, { ThunkDispatch, ThunkMiddleware } from 'redux-thunk';
import accountReducer from './account/reducers';
import appReducer from './app/reducers';
import authReducer from './auth/reducers';
import campaignReducer from './campaign/reducers';
import contactReducer from './contact/reducers';
import devicesReducer from './devices/reducers';
import enhancers from './enhancers';
import middleware from './middleware';
import notificationReducer from './notification/reducers';
import programEventReducer from './programEvent/reducers';
import registrationReducer from './registration/reducers';
import resourceReducer from './resource/reducers';
import settingReducer from './setting/reducers';
import studentsReducer from './students/reducers';
import { Actions, State } from './types';

function configureStore() {
  const middlewares: any[] = [thunk as ThunkMiddleware<State, Actions>];

  if (process.env.NODE_ENV === 'development') {
    middlewares.push(middleware.logger);
  }

  const middlewareEnhancer = applyMiddleware<ThunkDispatch<State, any, Actions>>(...middlewares);

  const reducers = combineReducers({
    app: appReducer,
    account: accountReducer,
    auth: authReducer,
    campaign: campaignReducer,
    contact: contactReducer,
    devices: devicesReducer,
    notification: notificationReducer,
    programEvent: programEventReducer,
    registration: registrationReducer,
    resource: resourceReducer,
    setting: settingReducer,
    students: studentsReducer,
  });

  const store = createStore(reducers, composeWithDevTools(compose(middlewareEnhancer, ...enhancers)));

  return store;
}

const store = configureStore();

export default store;
