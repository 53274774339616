import { ProgramEvent } from '../../models';
import { programEventService } from '../../services';
import { ThunkResult } from '../types';
import { ProgramEventActionTypes, SET_PROGRAM_EVENT, SET_PROGRAM_EVENTS, UPDATE_ERROR, UPDATE_LOADING } from './types';

export function updateLoading(newValue: boolean): ProgramEventActionTypes {
  return {
    type: UPDATE_LOADING,
    newValue,
  };
}

export function setProgramEvent(currentProgramEvent: ProgramEvent): ProgramEventActionTypes {
  return {
    type: SET_PROGRAM_EVENT,
    currentProgramEvent,
  };
}

export function setProgramEvents(programEvents: ProgramEvent[]): ProgramEventActionTypes {
  return {
    type: SET_PROGRAM_EVENTS,
    programEvents,
  };
}

export function updateProgramEventError(error?: string): ProgramEventActionTypes {
  return {
    type: UPDATE_ERROR,
    error,
  };
}

export function getProgramEvents(): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(updateLoading(true));
    return programEventService
      .getProgramEvents()
      .then((programEvents) => {
        if (programEvents) {
          dispatch(setProgramEvents(programEvents));
        }
      })
      .catch((error) => {
        dispatch(updateProgramEventError(error.message));
      })
      .finally(() => {
        // Delay reset of loading indicator to improve user experience
        setTimeout(() => {
          dispatch(updateLoading(false));
        }, 1000);
      });
  };
}

export const programEventActions = {
  getProgramEvents,
  setProgramEvent,
  setProgramEvents,
  updateProgramEventError,
  updateLoading,
};
