import { AUTH0_API_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '@env';
import * as AuthSession from 'expo-auth-session';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import { colors } from '../styles';
import BaseService from './BaseService';

export class AuthService extends BaseService {
  discovery: AuthSession.DiscoveryDocument;
  authRequest: AuthSession.AuthRequest;
  initialized = false;
  redirectUri: string;

  constructor() {
    super();

    this.init();
  }

  async init() {
    if (this.initialized) {
      return;
    }

    this.initialized = true;

    this.discovery = await AuthSession.fetchDiscoveryAsync(AUTH0_DOMAIN);
    this.redirectUri = AuthSession.makeRedirectUri({ path: 'redirect' });
    const authRequestOptions: AuthSession.AuthRequestConfig = {
      responseType: AuthSession.ResponseType.Code,
      clientId: AUTH0_CLIENT_ID,
      redirectUri: this.redirectUri,
      scopes: ['openid', 'profile', 'email', 'offline_access'],
      extraParams: {
        audience: AUTH0_API_AUDIENCE,
      },
    };
    this.authRequest = new AuthSession.AuthRequest(authRequestOptions);
    this.authRequest.makeAuthUrlAsync(this.discovery);
  }

  async refreshSession() {
    return this.refreshAuth0Session();
  }

  async signIn() {
    if (!this.initialized) {
      throw new Error('AuthService is not initialized!');
    }

    console.log('AuthService.signIn: Auth request', this.authRequest);
    const authResult = await this.authRequest.promptAsync(this.discovery);

    if (authResult.type === 'success') {
      console.log('AuthService.signIn: Success result', authResult);

      const tokenRequestOptions: AuthSession.AccessTokenRequestConfig = {
        code: authResult.params.code,
        clientId: AUTH0_CLIENT_ID,
        redirectUri: this.redirectUri,
        extraParams: {
          code_verifier: this.authRequest.codeVerifier || '',
        },
      };
      const tokenResult = await AuthSession.exchangeCodeAsync(tokenRequestOptions, this.discovery);
      console.log('AuthService.signIn: Token result', tokenResult);

      await this.setAuth0TokenResponseData(tokenResult);

      return true;
    } else {
      console.log('AuthService.signIn: Error result', authResult);
      return false;
    }
  }

  async signOut(includeBrowserSessionLogout = true) {
    if (includeBrowserSessionLogout) {
      const returnUrl = encodeURI(Linking.createURL('/', { queryParams: { logout: 'true' } }));
      // It doesn't necessarily matter if this fails
      await WebBrowser.openAuthSessionAsync(
        `${AUTH0_DOMAIN}/v2/logout?client_id=${AUTH0_CLIENT_ID}&returnTo=${returnUrl}&federated`,
        returnUrl,
        {
          showInRecents: false,
          controlsColor: colors.primary,
        },
      );
    }

    await this.clear();
  }

  async clear() {
    await super.clear();
  }
}

export default new AuthService();
