import { Campaign } from '../../models';
import { campaignService } from '../../services';
import { ThunkResult } from '../types';
import { CampaignActionTypes, SET_CAMPAIGN, SET_CAMPAIGNS, UPDATE_ERROR, UPDATE_LOADING } from './types';

export function updateLoading(newValue: boolean): CampaignActionTypes {
  return {
    type: UPDATE_LOADING,
    newValue,
  };
}

export function setCampaign(currentCampaign: Campaign): CampaignActionTypes {
  return {
    type: SET_CAMPAIGN,
    currentCampaign,
  };
}

export function setCampaigns(campaigns: Campaign[]): CampaignActionTypes {
  return {
    type: SET_CAMPAIGNS,
    campaigns,
  };
}

export function updateCampaignError(error?: string): CampaignActionTypes {
  return {
    type: UPDATE_ERROR,
    error,
  };
}

export function getCampaign(): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(updateLoading(true));
    return campaignService
      .getCampaign()
      .then((campaign) => {
        dispatch(setCampaign(campaign || null));
      })
      .catch((error) => {
        dispatch(updateCampaignError(error.message));
      })
      .finally(() => dispatch(updateLoading(false)));
  };
}

export const campaignActions = {
  getCampaign,
  setCampaign,
  setCampaigns,
  updateCampaignError,
  updateLoading,
};
