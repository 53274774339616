import { AppActionTypes, AppState, UPDATE_LOADING } from './types';

const initialState: AppState = {
  loading: true,
};

export default function appReducer(state = initialState, action: AppActionTypes): AppState {
  switch (action.type) {
    case UPDATE_LOADING:
      return { ...state, loading: action.newValue };
    default:
      return state;
  }
}
