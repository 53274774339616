import { ProgramEvent } from '../../models';

export const SET_PROGRAM_EVENT = '@@programEvents/SET_PROGRAM_EVENT';
export const SET_PROGRAM_EVENTS = '@@programEvents/SET_PROGRAM_EVENTS';
export const UPDATE_LOADING = '@@programEvents/UPDATE_LOADING';
export const UPDATE_ERROR = '@@programEvents/UPDATE_ERROR';

export interface ProgramEventState {
  currentProgramEvent: ProgramEvent | null;
  programEvents: ProgramEvent[];
  loading: boolean;
  error?: string;
}

export interface SetProgramEventAction {
  type: typeof SET_PROGRAM_EVENT;
  currentProgramEvent: ProgramEvent;
}

export interface SetProgramEventsAction {
  type: typeof SET_PROGRAM_EVENTS;
  programEvents: ProgramEvent[];
}

export interface UpdateLoadingAction {
  type: typeof UPDATE_LOADING;
  newValue: boolean;
}

export interface UpdateErrorAction {
  type: typeof UPDATE_ERROR;
  error?: string;
}

export type ProgramEventActionTypes =
  | SetProgramEventAction
  | SetProgramEventsAction
  | UpdateLoadingAction
  | UpdateErrorAction;
