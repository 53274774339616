import * as Device from 'expo-device';
import { Button, Text, View } from 'native-base';
import React, { useState } from 'react';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    alignItems: 'center',
    width: '100%',
  },

  topContainer: {
    flex: 1,
    display: 'flex',
    width: '100vw',
  },

  middleContainer: {
    flex: 3,
    display: 'flex',
    width: '80vw',
  },

  bottomContainer: {
    justifyContent: 'flex-end',
    margin: 20,
    padding: 10,
  },

  text: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 20,
    textAlign: 'center',
    paddingLeft: 30,
    paddingRight: 30,
  },
  subtext: {
    marginTop: 20,
    textAlign: 'center',
    fontSize: 14,
    paddingLeft: 30,
    paddingRight: 30,
  },
});

export default function ConvenienceMessage({ showMessage }) {
  const [messageIsDismissed, setMessageIsDismissed] = useState(false);

  const dismissMessage = async () => {
    setMessageIsDismissed(true);
  };

  const isAndroidDevice = Device.osName?.toLowerCase() === 'android';
  const isIosDevice = Device.osName?.toLowerCase() === 'ios';

  if (isAndroidDevice || isIosDevice) {
    return null;
  }

  if (showMessage && !messageIsDismissed) {
    return (
      <View style={styles.container}>
        <View style={styles.middleContainer}>
          <Text style={styles.text}>For your convenience, this is accessible as an app on your mobile device.</Text>
          <Text style={styles.subtext}>
            Open up your browser [ iOS - use Safari | Android - use Chrome ] go to
            https://app.rockthestreetwallstreet.com and add it to your home screen.
          </Text>
        </View>

        <View style={styles.bottomContainer}>
          <Button onPress={() => dismissMessage()} full>
            <Text>DISMISS</Text>
          </Button>
        </View>
      </View>
    );
  }

  return null;
}
