import { Feather } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as Device from 'expo-device';
import { Icon } from 'native-base';
import React, { useEffect } from 'react';
import { Dimensions, Image, Platform, Text, View } from 'react-native';
import { DrawerContent, RedirectToUrl } from '../components';
import AppInstallationAlert from '../components/AppInstallationAlert';
import HeaderRight from '../components/HeaderRight';
import config from '../config';
import {
  AboutScreen,
  ContactsScreen,
  HomeScreen,
  NotificationDetailScreen,
  NotificationsScreen,
  ProgramEventDetailScreen,
  ProgramEventsScreen,
  ReenrollmentScreen,
  ResourcesScreen,
  SpringEnrollmentScreen,
  UserProfileScreen,
  VolunteerReenrollmentScreen,
} from '../screens';

const defaultScreenOptions = (navigation: any, title: string, otherOptions: any = {}) => {
  const commonOptions = {
    headerBackTitle: 'Back',
    headerRight: () => <HeaderRight />,
    title,
    headerTintColor: '#044959',
  };

  const state = navigation.getState();

  if (state.index === 0) {
    return {
      ...commonOptions,
      headerLeft: () => (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', width: 40, height: 40 }}>
          <Image
            // eslint-disable-next-line global-require
            source={require('../../assets/images/cropped-rtsws-widget-navy-2021-192x192.jpeg')}
            resizeMode="contain"
            style={{ width: 48, height: 48, left: 10 }}
          />
        </View>
      ),
      ...otherOptions,
    };
  }

  return { ...commonOptions, ...otherOptions };
};

const isMobileDevice = (): boolean => {
  return Device.osName?.toLowerCase() === 'android' || Device.osName?.toLowerCase() === 'ios';
};

const HomeStack = createStackNavigator();

function HomeStackComponent() {
  return (
    <HomeStack.Navigator>
      <HomeStack.Screen
        name="Home"
        component={HomeScreen}
        options={({ navigation }) => defaultScreenOptions(navigation, 'Home')}
      />
    </HomeStack.Navigator>
  );
}

const ProgramEventsStack = createStackNavigator();

function ProgramEventsStackComponent() {
  return (
    <ProgramEventsStack.Navigator>
      <ProgramEventsStack.Screen
        name="ProgramEvents"
        component={ProgramEventsScreen}
        options={({ navigation }) => defaultScreenOptions(navigation, 'Schedule')}
      />
      <ProgramEventsStack.Screen
        name="ProgramEventDetail"
        component={ProgramEventDetailScreen}
        options={
          ({ navigation }) =>
            defaultScreenOptions(navigation, 'Event Details', {
              headerRight: null,
              headerBackImage: () => <Feather name="x" size={25} style={{ left: 5 }} />,
              headerBackTitleVisible: false,
              headerStyle: { borderBottomWidth: 0 },
              animationEnabled: true,
              guestureEnable: true,
            })
          // eslint-disable-next-line react/jsx-curly-newline
        }
      />
    </ProgramEventsStack.Navigator>
  );
}

const ResourcesStack = createStackNavigator();

function ResourcesStackComponent() {
  return (
    <ResourcesStack.Navigator>
      <ResourcesStack.Screen
        name="Resources"
        component={ResourcesScreen}
        options={({ navigation }) => defaultScreenOptions(navigation, 'Resources')}
      />
    </ResourcesStack.Navigator>
  );
}

const NotificationsStack = createStackNavigator();

function NotificationsStackComponent() {
  return (
    <NotificationsStack.Navigator>
      <NotificationsStack.Screen
        name="Notifications"
        component={NotificationsScreen}
        options={({ navigation }) => defaultScreenOptions(navigation, 'Notifications')}
      />
      <NotificationsStack.Screen
        name="NotificationDetail"
        component={NotificationDetailScreen}
        options={
          ({ navigation }) =>
            defaultScreenOptions(navigation, 'Notification Details', {
              headerRight: null,
              headerBackImage: () => <Feather name="x" size={25} style={{ left: 5 }} />,
              headerBackTitleVisible: false,
              headerStyle: { borderBottomWidth: 0 },
              animationEnabled: true,
              guestureEnable: true,
            })
          // eslint-disable-next-line react/jsx-curly-newline
        }
      />
    </NotificationsStack.Navigator>
  );
}

const SijPortalStack = createStackNavigator();

function SijPortalStackComponent() {
  return (
    <SijPortalStack.Navigator>
      <SijPortalStack.Screen
        name="InternshipJobs"
        component={RedirectToUrl}
        options={({ navigation }) => defaultScreenOptions(navigation, 'Internship/Jobs')}
      />
    </SijPortalStack.Navigator>
  );
}

const isTablet = (() => {
  const { height, width } = Dimensions.get('window');
  const aspectRatio = height / width;

  return aspectRatio < 1.6;
})();

const bottomTabLabelStyle = isTablet ? { marginLeft: 20, marginTop: 4 } : { fontSize: 12, marginBottom: 1.5 };

const generateBottomTabLabel = (label: string) =>
  Platform.OS === 'ios' ? ({ color }) => <Text style={{ ...bottomTabLabelStyle, color }}>{label}</Text> : label;

interface IconProps {
  color: string;
}

const bottomNavRoutes = (Tabs: any, navigatorOptions: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tabs.Navigator {...navigatorOptions}>
    <Tabs.Screen
      name="HomeStack"
      component={HomeStackComponent}
      options={{
        tabBarIcon: ({ color }: IconProps) => <Icon type="MaterialIcons" style={{ color, fontSize: 24 }} name="home" />,
        tabBarLabel: generateBottomTabLabel('Home'),
        tabBarTestID: 'HomeTabBtn',
        headerShown: false,
      }}
    />

    <Tabs.Screen
      name="ProgramEventsStack"
      component={ProgramEventsStackComponent}
      options={{
        tabBarIcon: ({ color }: IconProps) => (
          <Icon type="MaterialIcons" style={{ color, fontSize: 24 }} name="class" />
        ),
        tabBarLabel: generateBottomTabLabel('Schedule'),
        tabBarTestID: 'ScheduleTabBtn',
        headerShown: false,
      }}
    />

    <Tabs.Screen
      name="ResourcesStack"
      component={ResourcesStackComponent}
      options={{
        tabBarIcon: ({ color }: IconProps) => (
          <Icon type="MaterialIcons" style={{ color, fontSize: 24 }} name="library-books" />
        ),
        tabBarLabel: generateBottomTabLabel('Resources'),
        tabBarTestID: 'ResourcesTabBtn',
        headerShown: false,
      }}
    />

    <Tabs.Screen
      name="SijPortalStack"
      component={SijPortalStackComponent}
      options={{
        tabBarIcon: ({ color }: IconProps) => (
          <Icon type="MaterialIcons" style={{ color, fontSize: 24 }} name="library-books" />
        ),
        tabBarLabel: generateBottomTabLabel('Internship/Jobs'),
        tabBarTestID: 'SijPortalLink',
        headerShown: false,
      }}
    />

    {/* <Tabs.Screen
      name="ContactsStack"
      component={ContactsStackComponent}
      options={{
        tabBarIcon: ({ color }: IconProps) => (
          <Icon type="MaterialIcons" style={{ color, fontSize: 24 }} name="contact-mail" />
        ),
        tabBarLabel: generateBottomTabLabel('Contacts'),
        tabBarTestID: 'ContactsTabBtn',
      }}
    /> */}

    <Tabs.Screen
      name="NotificationsStack"
      component={NotificationsStackComponent}
      options={{
        tabBarIcon: ({ color }: IconProps) => (
          <Icon type="MaterialIcons" style={{ color, fontSize: 24 }} name="notifications" />
        ),
        tabBarLabel: generateBottomTabLabel('Notifications'),
        tabBarTestID: 'NotificationsTabBtn',
        headerShown: false,
      }}
    />
  </Tabs.Navigator>
);

const tabs = createBottomTabNavigator();
const Tabs = () =>
  bottomNavRoutes(tabs, {
    initialRouteName: config.initialRouteName,
    screenOptions: {
      tabBarActiveTintColor: '#044959',
      tabBarInactiveTintColor: '#bdbdbd',
      tabBarStyle: [
        {
          display: 'flex',
        },
        null,
      ],
    },
  });

const Drawer = createDrawerNavigator();

function DrawerComponent() {
  return (
    <Drawer.Navigator
      drawerContent={(props) => <DrawerContent {...props} />}
      screenOptions={{ drawerPosition: 'right', drawerHideStatusBarOnOpen: true }}
    >
      <Drawer.Screen name="Tabs" component={Tabs} options={{ headerShown: false }} />
      <Drawer.Screen name="SpringEnrollment" component={SpringEnrollmentScreen} />
      <Drawer.Screen name="Reenrollment" component={ReenrollmentScreen} />
      <Drawer.Screen name="VolunteerReenrollment" component={VolunteerReenrollmentScreen} />
      <Drawer.Screen name="UserProfile" component={UserProfileScreen} />
      <Drawer.Screen name="About" component={AboutScreen} options={{ headerShown: false }} />
      <Drawer.Screen name="Contacts" component={ContactsScreen} options={{ headerShown: false }} />
    </Drawer.Navigator>
  );
}

const AppStack = createStackNavigator();

function AppStackComponent() {
  const navigation = useNavigation() as any;

  useEffect(() => {
    if (isMobileDevice() && window.matchMedia('(display-mode: browser)').matches) {
      navigation.navigate('AppInstallationAlert');
    }
  }, []);

  return (
    <AppStack.Navigator
      initialRouteName={config.initialRouteName}
      screenOptions={{ animationEnabled: false, gestureEnabled: false, presentation: 'modal' }}
    >
      <AppStack.Screen
        name="AppInstallationAlert"
        component={AppInstallationAlert}
        options={({ navigation }) => defaultScreenOptions(navigation, '', { headerRight: null })}
      />
      <AppStack.Screen name="Drawer" component={DrawerComponent} options={{ headerShown: false }} />
    </AppStack.Navigator>
  );
}

const RootStack = createStackNavigator();

export const AppNavigation = () => {
  return (
    <RootStack.Navigator initialRouteName="App" screenOptions={{ gestureEnabled: false }}>
      <RootStack.Screen name="App" component={AppStackComponent} options={{ headerShown: false }} />
    </RootStack.Navigator>
  );
};
