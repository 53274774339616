import { useFormik } from 'formik';
import { Body, Button, Container, Content, Form, Item, Label, Picker, Text, View } from 'native-base';
import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import AwesomeAlert from 'react-native-awesome-alerts';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { updateAuthError } from '../store/auth/actions';
import { submitReenrollment, updateRegistrationError } from '../store/registration/actions';
import { State } from '../store/types';
import { colors } from '../styles';
import { referrerOptions } from './registration/StudentRegistrationScreen';
import { SplashScreen } from './SplashScreen';

const styles = StyleSheet.create({
  content: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 40,
  },
  form: {
    marginBottom: 40,
  },
  headerText: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  errorText: {
    color: colors.red,
    fontSize: 12,
  },
  requiredAsterisk: {
    fontSize: 12,
    top: -6,
    position: 'relative',
  },
});

const registrationSchema = Yup.object().shape({
  schoolAccountSfid: Yup.string().required('Please select a school'),
  referrer: Yup.string().required('Required'),
});

export default function ReenrollmentScreen({ navigation }) {
  const dispatch = useDispatch();

  const authState = useSelector((state: State) => state.auth);
  const { currentUser, loading, error } = authState;

  const accountState = useSelector((state: State) => state.account);
  const { schoolAccounts } = accountState;

  const registrationState = useSelector((state: State) => state.registration);
  const { registrationData, loading: registrationLoading, error: registrationError } = registrationState;

  const [alertIsVisible, setAlertIsVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      schoolAccountSfid: registrationData?.schoolAccountSfid || '',
      referrer: registrationData?.referrer || '',
    },
    validationSchema: registrationSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });

  const submit = async (values) => {
    if (!currentUser) {
      return;
    }

    const data = {
      schoolAccountSfid: values.schoolAccountSfid,
      referrer: values.referrer,
    };

    const response = await dispatch(submitReenrollment(currentUser, data));

    if (response as any) {
      setSuccess(true);
    }
  };

  const schoolPickerItems = schoolAccounts
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((account) => {
      return <Picker.Item label={account.name} value={account.sfid} key={account.id} />;
    });

  useEffect(() => {
    if (error) {
      setAlertTitle('Error');
      setAlertMessage(error);
      setAlertIsVisible(true);
      dispatch(updateAuthError(undefined));
    }

    if (registrationError) {
      setAlertTitle('Enrollment Unsuccessful');
      setAlertMessage(registrationError);
      setAlertIsVisible(true);
      dispatch(updateRegistrationError(undefined));
    }
  }, [error, registrationError]);

  if (loading || registrationLoading) {
    return <SplashScreen />;
  }

  return (
    <Container>
      <Content style={styles.content}>
        <Body>
          <Text style={styles.headerText}>Reenrollment</Text>

          {success ? (
            <View>
              <Text style={{ fontSize: 16, marginTop: 30, marginBottom: 20, textAlign: 'center' }}>
                Your reenrollment has been submitted.
              </Text>
              <Text style={{ fontSize: 18, marginTop: 0, marginBottom: 20, textAlign: 'center' }}>Thanks!</Text>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Button bordered style={{ flex: 1, margin: 5 }} onPress={() => navigation.navigate('Tabs')} full>
                  <Text>CLOSE</Text>
                </Button>
              </View>
            </View>
          ) : (
            <View>
              <Text style={{ fontSize: 16, marginTop: 30, marginBottom: 20, textAlign: 'center' }}>
                Fill out the form below to reenroll in the Rock The Street, Wall Street program for the upcoming school
                year.
              </Text>
              <Form style={{ marginBottom: 20 }}>
                <Item
                  stackedLabel
                  error={formik.touched.schoolAccountSfid && formik.errors.schoolAccountSfid ? true : false}
                >
                  <Label>
                    School<Text style={styles.requiredAsterisk}>*</Text>
                  </Label>
                  <Text style={styles.errorText}>
                    {formik.touched.schoolAccountSfid && formik.errors.schoolAccountSfid
                      ? formik.errors.schoolAccountSfid
                      : null}
                  </Text>
                  <Picker
                    mode="dropdown"
                    style={{ width: '100%' }}
                    selectedValue={formik.values.schoolAccountSfid}
                    onValueChange={formik.handleChange('schoolAccountSfid')}
                  >
                    <Picker.Item label="" value="" key={schoolPickerItems.length + 1} />
                    {schoolPickerItems}
                  </Picker>
                </Item>
                <Item stackedLabel error={formik.touched.referrer && formik.errors.referrer ? true : false}>
                  <Label>
                    How did you hear about RTSWS?<Text style={styles.requiredAsterisk}>*</Text>
                  </Label>
                  <Text style={styles.errorText}>
                    {formik.touched.referrer && formik.errors.referrer ? formik.errors.referrer : null}
                  </Text>
                  <Picker
                    mode="dropdown"
                    style={{ width: '100%' }}
                    selectedValue={formik.values.referrer}
                    onValueChange={formik.handleChange('referrer')}
                  >
                    <Picker.Item label="" value="" key={referrerOptions.length + 1} />
                    {referrerOptions.map((option, index) => (
                      <Picker.Item label={option.label} value={option.value} key={index} />
                    ))}
                  </Picker>
                </Item>
              </Form>
              <View style={{ display: 'flex', flexDirection: 'row', marginLeft: '16px' }}>
                <Button bordered style={{ flex: 1, margin: 5 }} onPress={() => navigation.navigate('Tabs')} full>
                  <Text>CLOSE</Text>
                </Button>
                <Button style={{ flex: 2, margin: 5 }} onPress={() => formik.handleSubmit()} full>
                  <Text numberOfLines={1}>RE-ENROLL NOW</Text>
                </Button>
              </View>
            </View>
          )}
        </Body>

        <AwesomeAlert
          show={alertIsVisible}
          showProgress={false}
          title={alertTitle}
          message={alertMessage}
          closeOnHardwareBackPress={false}
          showConfirmButton
          confirmText="OK"
          confirmButtonColor="#5cb85c"
          alertContainerStyle={{ height: 'auto', minHeight: 700, position: 'absolute', left: 0 }}
          onConfirmPressed={() => {
            setAlertIsVisible(false);
          }}
        />
      </Content>
    </Container>
  );
}
