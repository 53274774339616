import { Button, Text, View } from 'native-base';
import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../store/actions';
import { State } from '../store/types';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    alignItems: 'center',
    width: '100%',
    padding: 20,
  },

  topContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  middleContainer: {
    flex: 1,
    justifyContent: 'flex-start',
  },

  bottomContainer: {
    justifyContent: 'flex-end',
    margin: 20,
    padding: 10,
  },
  text: {
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 10,
    textAlign: 'center',
  },
  subtext: {
    marginTop: 20,
    textAlign: 'center',
    fontSize: 12,
  },
});

export const InvalidRoleScreen: React.FC = () => {
  const dispatch = useDispatch();
  const currentDevice = useSelector((state: State) => state.devices.currentDevice);

  const signOut = useCallback(() => {
    dispatch(authActions.signOutUser(currentDevice?.id, true));
  }, [dispatch]);

  return (
    <View style={styles.container}>
      <View style={styles.topContainer}>
        <Text style={styles.text}>INVALID ROLE</Text>
        <Text style={styles.subtext}>This app is designed only for Students and Volunteers. Please sign out.</Text>
      </View>

      <View style={styles.middleContainer}></View>
      <View style={styles.bottomContainer}>
        <Button style={{ marginTop: 20 }} onPress={() => signOut()}>
          <Text>Log Out</Text>
        </Button>
      </View>
    </View>
  );
};
