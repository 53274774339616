import { Account } from '../../models';

export const SET_SCHOOL_ACCOUNTS = '@@account/SET_SCHOOL_ACCOUNTS';
export const SET_SPONSOR_ACCOUNTS = '@@account/SET_SPONSOR_ACCOUNTS';
export const UPDATE_LOADING = '@@account/UPDATE_LOADING';
export const UPDATE_ERROR = '@@account/UPDATE_ERROR';

export interface AccountState {
  schoolAccounts: Account[];
  sponsorAccounts: Account[];
  loading: boolean;
  error?: string;
}

export interface SetSchoolAccountsAction {
  type: typeof SET_SCHOOL_ACCOUNTS;
  schoolAccounts: Account[];
}

export interface SetSponsorAccountsAction {
  type: typeof SET_SPONSOR_ACCOUNTS;
  sponsorAccounts: Account[];
}

export interface UpdateLoadingAction {
  type: typeof UPDATE_LOADING;
  newValue: boolean;
}

export interface UpdateErrorAction {
  type: typeof UPDATE_ERROR;
  error?: string;
}

export type AccountActionTypes =
  | SetSponsorAccountsAction
  | SetSchoolAccountsAction
  | UpdateLoadingAction
  | UpdateErrorAction;
