import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RoleNames } from '../../constants';
import * as actions from '../../store/actions';
import { State } from '../../store/types';

export const useDataLoader = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: State) => state.auth);

  const preAuthLoad = useCallback(async () => {
    const actionsToDispatch: any[] = [];

    await Promise.all(actionsToDispatch.map((action) => dispatch(action)));
  }, [dispatch]);

  const load = useCallback(async () => {
    const actionsToDispatch: any[] = [
      actions.accountActions.getSponsorAccounts(),
      actions.accountActions.getSchoolAccounts(),
      actions.settingActions.getSettings(),
      actions.deviceActions.getDevices(),
    ];

    if (currentUser?.role) {
      if ([RoleNames.STUDENT, RoleNames.VOLUNTEER].includes(currentUser.role.name)) {
        actionsToDispatch.push(
          actions.campaignActions.getCampaign(),
          actions.contactActions.getContacts(),
          actions.notificationActions.getNotifications(),
          actions.programEventActions.getProgramEvents(),
          actions.resourceActions.getResources(),
        );
      }

      if (currentUser.role.name === RoleNames.STUDENT) {
      }

      if (currentUser.role.name === RoleNames.VOLUNTEER) {
        actionsToDispatch.push(actions.studentActions.getStudents());
      }
    }

    await Promise.all(actionsToDispatch.map((action) => dispatch(action)));
  }, [currentUser]);

  return { load, preAuthLoad };
};
