import { CampaignActionTypes, CampaignState, SET_CAMPAIGN, SET_CAMPAIGNS, UPDATE_ERROR, UPDATE_LOADING } from './types';

const initialState: CampaignState = {
  currentCampaign: null,
  campaigns: [],
  loading: false,
};

export default function campaignReducer(state = initialState, action: CampaignActionTypes): CampaignState {
  switch (action.type) {
    case UPDATE_LOADING:
      return { ...state, loading: action.newValue };
    case SET_CAMPAIGN:
      return { ...state, currentCampaign: action.currentCampaign };
    case SET_CAMPAIGNS:
      return { ...state, campaigns: action.campaigns };
    case UPDATE_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
}
