export const UPDATE_ERROR = '@@registration/UPDATE_ERROR';
export const UPDATE_LOADING = '@@registration/UPDATE_LOADING';
export const UPDATE_REGISTRATION = '@@registration/UPDATE_REGISTRATION';
export const UPDATE_VOLUNTEER_REGISTRATION = '@@registration/UPDATE_VOLUNTEER_REGISTRATION';
export const UPDATE_REGISTRATION_SUBMITTED = '@@registration/UPDATE_REGISTRATION_SUBMITTED';

export interface RegistrationState {
  registrationData: Partial<RegistrationData> | null;
  volunteerRegistrationData: Partial<VolunteerRegistrationData> | null;
  loading: boolean;
  registrationSubmitted: boolean;
  error?: string;
}

export interface RegistrationData {
  type: string;
  firstName: string;
  lastName: string;
  middleInitial: string;
  mobilePhone: string;
  email: string;
  alternateEmail: string;
  schoolAccountSfid: string;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressState: string;
  addressStateOther: string;
  addressCountry: string;
  addressZip: string;
  dateOfBirth: string;
  graduationYear: string;
  genderIdentification: string;
  race: string;
  referrer: string;
  parentFirstName: string;
  parentLastName: string;
  parentEmail: string;
  parentMobilePhone: string;
  parentAcknowledged: boolean;
  parentAcknowledgementTimestamp: number;
}

export interface VolunteerRegistrationData {
  type: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  email: string;
  alternateEmail: string;
  schoolAccountSfid: string;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressState: string;
  addressStateOther: string;
  addressCountry: string;
  addressZip: string;
  company: string;
  title: string;
  designation: string;
  hundredWomenInFinance: boolean;
  collegeAttended: string;
  fallWorkshopInterested: boolean;
  springMentorInterested: boolean;
  race: string;
}

export interface UpdateRegistrationAction {
  type: typeof UPDATE_REGISTRATION;
  newRegistrationData: Partial<RegistrationData>;
}

export interface UpdateVolunteerRegistrationAction {
  type: typeof UPDATE_VOLUNTEER_REGISTRATION;
  newRegistrationData: Partial<VolunteerRegistrationData>;
}

export interface UpdateLoadingAction {
  type: typeof UPDATE_LOADING;
  newValue: boolean;
}

export interface UpdateErrorAction {
  type: typeof UPDATE_ERROR;
  error?: string;
}

export interface UpdateRegistrationSubmittedAction {
  type: typeof UPDATE_REGISTRATION_SUBMITTED;
  registrationSubmitted: boolean;
}

export type RegistrationActionTypes =
  | UpdateVolunteerRegistrationAction
  | UpdateRegistrationAction
  | UpdateLoadingAction
  | UpdateErrorAction
  | UpdateRegistrationSubmittedAction;
