import { Text } from 'native-base';
import React from 'react';
import { Linking } from 'react-native';
import { colors } from '../styles';

export default function NotificationLink({ notification }) {
  const openLink = async (url: string) => {
    Linking.openURL(url);
  };

  return notification.link?.length > 0 ? (
    <Text
      style={{ marginTop: 5, fontSize: 14, fontWeight: 'bold', color: colors.secondary }}
      onPress={() => openLink(notification.link)}
    >
      Open Link
    </Text>
  ) : null;
}
