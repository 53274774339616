import { UserNotification } from '../../models';
import { notificationService } from '../../services';
import { ThunkResult } from '../types';
import { NotificationActionTypes, SET_NOTIFICATIONS, UPDATE_ERROR, UPDATE_LOADING } from './types';

export function updateLoading(newValue: boolean): NotificationActionTypes {
  return {
    type: UPDATE_LOADING,
    newValue,
  };
}

export function setNotifications(userNotifications: UserNotification[]): NotificationActionTypes {
  return {
    type: SET_NOTIFICATIONS,
    userNotifications,
  };
}

export function updateNotificationError(error?: string): NotificationActionTypes {
  return {
    type: UPDATE_ERROR,
    error,
  };
}

export function getNotifications(page?: number, count?: number): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(updateLoading(true));
    return notificationService
      .getNotifications(page, count)
      .then((notifications) => {
        dispatch(setNotifications(Array.isArray(notifications) ? notifications : []));
      })
      .catch((error) => {
        dispatch(updateNotificationError(error.message));
      })
      .finally(() => {
        // Delay reset of loading indicator to improve user experience
        setTimeout(() => {
          dispatch(updateLoading(false));
        }, 1000);
      });
  };
}

export const notificationActions = {
  getNotifications,
  setNotifications,
  updateNotificationError,
  updateLoading,
};
