import { API_URL, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '@env';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import * as AuthSession from 'expo-auth-session';

export interface Auth0UserResponse {
  sub: string;
  nickname: string;
  name: string;
  picture: string;
  updated_at: string;
  email: string;
  email_verified: string;
}

export interface Auth0User {
  raw: Auth0UserResponse;
  id: string;
  name: string;
  nickname: string;
  pictureUrl: string;
  updatedAt: Date;
  email: string;
  emailVerified: boolean;
}

export interface PaginatedResponse {
  PageCount: number;
}

export interface ApiRequest extends AxiosRequestConfig {}

export interface ApiResponse<T> extends AxiosResponse<T> {}

const AUTH0_TOKEN_RESPONSE_KEY = 'auth0TokenResponse';

export default class BaseService {
  protected client = axios.create({ baseURL: API_URL + '/api' });
  private inFlightRefreshRequest: Promise<boolean> | null = null;

  constructor() {
    this.client.interceptors.request.use(async (request) => {
      if (!request.headers) {
        request.headers = {};
      }

      request.headers.Authorization = `Bearer ${await this.getAccessToken()}`;

      return request;
    });

    this.client.interceptors.response.use(
      async (response) => response,
      async (error) => {
        if (!(error instanceof axios.AxiosError)) {
          return Promise.reject(error);
        }

        const config = error.config;

        if (error.response?.status === 401 && !(config as any).retried) {
          console.log('BaseService: Unauthorized request, refreshing token');

          if (!this.inFlightRefreshRequest) {
            console.log('BaseService: No in-flight refresh request, refreshing token');
            this.inFlightRefreshRequest = this.refreshAuth0Session();
          }

          const refreshed = await this.inFlightRefreshRequest;
          this.inFlightRefreshRequest = null;

          if (refreshed) {
            console.log('BaseService: Token refreshed, retrying request');
            (config as any).retried = true;
            return this.client.request(config ?? {});
          } else {
            console.log('BaseService: Token refresh failed, returning original response');
            return Promise.reject(error);
          }
        } else if (error.response?.status === 401) {
          console.log('BaseService: Unauthorized request, already retried');
          // Refresh has failed, we need to return to the login screen
          console.error(new Error('BaseService: Refresh token has failed, returning to login screen'));
        }

        return Promise.reject(error);
      },
    );
  }

  async clear() {
    await Promise.all([this.clearAuth0TokenResponseData()]);
  }

  protected async getJson<T>(url: string, request: ApiRequest = {}): Promise<ApiResponse<T>> {
    return this.executeRequest<T>({
      ...request,
      url,
      method: 'get',
    });
  }

  protected async postJson<T>(url: string, data: any, request: ApiRequest = {}): Promise<ApiResponse<T>> {
    return this.executeRequest<T>({
      ...request,
      url,
      method: 'post',
      data,
    });
  }

  protected async putJson<T>(url: string, data: any, request: ApiRequest = {}): Promise<ApiResponse<T>> {
    return this.executeRequest<T>({
      ...request,
      url,
      method: 'put',
      data,
    });
  }

  protected async deleteJson<T>(url: string, request: ApiRequest = {}): Promise<ApiResponse<T>> {
    return this.executeRequest<T>({
      ...request,
      url,
      method: 'delete',
    });
  }

  protected async executeRequest<T>(request: ApiRequest): Promise<ApiResponse<T>> {
    return this.client.request(request);
  }

  protected async refreshAuth0Session() {
    const tokenResponse = await this.getAuth0TokenResponseData();

    if (!tokenResponse) {
      return false;
    }

    if (tokenResponse.shouldRefresh()) {
      try {
        console.log('BaseService.refreshAuth0Session: Token should be refreshed, refreshing token');
        const discovery = await AuthSession.fetchDiscoveryAsync(AUTH0_DOMAIN);
        const newTokenResponse = await tokenResponse.refreshAsync(
          {
            clientId: AUTH0_CLIENT_ID,
          },
          discovery,
        );

        console.log('BaseService.refreshAuth0Session: New token response', newTokenResponse);
        await this.setAuth0TokenResponseData(newTokenResponse);
      } catch (error) {
        console.log('BaseService.refreshAuth0Session: Failed to refresh token', error);
        await this.clear();
        return false;
      }
    } else {
      console.log('BaseService.refreshAuth0Session: Token is still valid');
    }

    return true;
  }

  protected async getAccessToken() {
    const tokenResponse = await this.getAuth0TokenResponseData();
    return tokenResponse?.accessToken;
  }

  protected async getAuth0TokenResponseData(): Promise<AuthSession.TokenResponse | null> {
    const data = await AsyncStorage.getItem(AUTH0_TOKEN_RESPONSE_KEY);

    if (data) {
      return new AuthSession.TokenResponse(JSON.parse(data));
    }

    return null;
  }

  protected setAuth0TokenResponseData(tokenResponse: AuthSession.TokenResponse) {
    console.log('BaseService.setAuth0TokenResponseData: Storing token response', tokenResponse);
    tokenResponse.idToken = undefined;
    return AsyncStorage.setItem(AUTH0_TOKEN_RESPONSE_KEY, JSON.stringify(tokenResponse));
  }

  protected async clearAuth0TokenResponseData() {
    await AsyncStorage.removeItem(AUTH0_TOKEN_RESPONSE_KEY);
  }
}
