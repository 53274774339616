import Checkbox from 'expo-checkbox';
import { useFormik } from 'formik';
import { Body, Button, Container, Content, Form, Input, Item, Label, ListItem, Text, View } from 'native-base';
import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import AwesomeAlert from 'react-native-awesome-alerts';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { updateAuthError, updateCurrentUser } from '../store/auth/actions';
import { State } from '../store/types';
import { colors } from '../styles';
import { SplashScreen } from './SplashScreen';

const styles = StyleSheet.create({
  content: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 40,
  },
  form: {
    marginBottom: 40,
  },
  headerText: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  errorText: {
    color: colors.red,
    fontSize: 12,
  },
});

const userProfileSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  mobilePhone: Yup.string().required('Required'),
});

export default function UserProfileScreen({ navigation }) {
  const dispatch = useDispatch();

  const authState = useSelector((state: State) => state.auth);
  const { currentUser, loading, error } = authState;

  const [inputErrorIsVisible, setInputErrorIsVisible] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: currentUser?.firstName || '',
      lastName: currentUser?.lastName || '',
      mobilePhone: currentUser?.contact?.mobilePhone || '',
      receiveSmsNotifications: currentUser?.receiveSmsNotifications || false,
      receivePushNotifications: currentUser?.receivePushNotifications || false,
    },
    validationSchema: userProfileSchema,
    onSubmit: (values) => {
      submitUpdate(values);
    },
  });

  useEffect(() => {
    if (error) {
      setInputErrorIsVisible(true);
      dispatch(updateAuthError(undefined));
    }
  });

  const submitUpdate = (values) => {
    dispatch(
      updateCurrentUser({
        ...currentUser,
        firstName: values.firstName,
        lastName: values.lastName,
        receivePushNotifications: values.receivePushNotifications,
        receiveSmsNotifications: values.receiveSmsNotifications,
        contactMobilePhone: values.mobilePhone,
      }),
    );
  };

  if (loading) {
    return <SplashScreen />;
  }

  return (
    <Container>
      <Content style={styles.content}>
        <Body>
          <Text style={styles.headerText}>User Profile</Text>
        </Body>
        <Form style={{ marginTop: 20, marginBottom: 20 }}>
          <Item stackedLabel error={formik.touched.firstName && formik.errors.firstName ? true : false}>
            <Label>First Name</Label>
            <Text style={styles.errorText}>
              {formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : null}
            </Text>
            <Input
              placeholder=""
              onChangeText={formik.handleChange('firstName')}
              autoCapitalize="none"
              returnKeyType="next"
              value={formik.values.firstName}
            />
          </Item>
          <Item stackedLabel error={formik.touched.lastName && formik.errors.lastName ? true : false}>
            <Label>Last Name</Label>
            <Text style={styles.errorText}>
              {formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : null}
            </Text>
            <Input
              placeholder=""
              onChangeText={formik.handleChange('lastName')}
              autoCapitalize="none"
              returnKeyType="next"
              value={formik.values.lastName}
            />
          </Item>
          <Item stackedLabel error={formik.touched.mobilePhone && formik.errors.mobilePhone ? true : false}>
            <Label>Mobile Phone</Label>
            <Text style={styles.errorText}>
              {formik.touched.mobilePhone && formik.errors.mobilePhone ? formik.errors.mobilePhone : null}
            </Text>
            <Input
              placeholder=""
              onChangeText={formik.handleChange('mobilePhone')}
              autoCapitalize="none"
              returnKeyType="next"
              value={formik.values.mobilePhone}
            />
          </Item>
          <ListItem>
            <Checkbox
              value={formik.values.receiveSmsNotifications}
              onValueChange={(value) => formik.setFieldValue('receiveSmsNotifications', value)}
              color={formik.values.receiveSmsNotifications ? colors.primary : undefined}
            />
            <Body>
              <Text>Receive SMS Notifications</Text>
            </Body>
          </ListItem>
          <ListItem>
            <Checkbox
              value={formik.values.receivePushNotifications}
              onValueChange={(value) => formik.setFieldValue('receivePushNotifications', value)}
              color={formik.values.receivePushNotifications ? colors.primary : undefined}
            />
            <Body>
              <Text>Receive Push Notifications</Text>
            </Body>
          </ListItem>
        </Form>

        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Button bordered style={{ flex: 1, margin: 5 }} onPress={() => navigation.navigate('Tabs')} full>
            <Text>CLOSE</Text>
          </Button>
          <Button style={{ flex: 1, margin: 5 }} onPress={() => formik.handleSubmit()} full>
            <Text>SAVE</Text>
          </Button>
        </View>

        <AwesomeAlert
          show={inputErrorIsVisible}
          showProgress={false}
          title="Update failed"
          message="Please try again."
          closeOnHardwareBackPress={false}
          showConfirmButton
          confirmText="OK"
          confirmButtonColor="#DD6B55"
          alertContainerStyle={{ postion: 'absolute', left: 0 }}
          onConfirmPressed={() => {
            setInputErrorIsVisible(false);
          }}
        />
      </Content>
    </Container>
  );
}
