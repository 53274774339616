import { StyleSheet } from 'react-native';

export const colors = {
  black: '#000',
  darkGrey: '#666666',
  darkestGrey: '#362C3C',
  grey: '#242429',
  lightGrey: 'lightgrey',
  lighterGrey: '#e6e6e6',
  lightestGrey: '#f7f7f7',
  primary: '#044959',
  secondary: '#4ba396',
  white: '#fff',
  red: '#d7263d',
  yellow: '#f0ad4e',
};

const rootStyles = {
  container: {
    flex: 1,
  },
  formInput: {
    borderRadius: 4,
    borderColor: colors.lightestGrey,
    marginTop: 10,
    marginBottom: 10,
    flex: 1,
    width: '100%',
    height: 50,
    maxHeight: 50,
  },
  bottomModal: {
    justifyContent: 'flex-end' as 'flex-end',
    margin: 0,
  },
  modalContent: {
    flex: 1,
    backgroundColor: 'white',
    borderRadius: 4,
    borderColor: 'rgba(0, 0, 0, 0.1)',
  },
  bottomModalContent: {
    padding: 22,
    justifyContent: 'center' as 'center',
    alignItems: 'center' as 'center',
    backgroundColor: 'white',
    borderRadius: 4,
    borderColor: 'rgba(0, 0, 0, 0.1)',
  },
  centeredContent: {
    flex: 1,
    justifyContent: 'center' as 'center',
    alignItems: 'center' as 'center',
  },

  accentBorder: {
    borderBottomWidth: 2,
    borderBottomColor: '#FCC731',
  },
};

export default StyleSheet.create(rootStyles);
