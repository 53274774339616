import { Account } from '../../models';
import { accountService } from '../../services';
import { ThunkResult } from '../types';
import { AccountActionTypes, SET_SCHOOL_ACCOUNTS, SET_SPONSOR_ACCOUNTS, UPDATE_ERROR, UPDATE_LOADING } from './types';

export function updateLoading(newValue: boolean): AccountActionTypes {
  return {
    type: UPDATE_LOADING,
    newValue,
  };
}

export function setSchoolAccounts(schoolAccounts: Account[]): AccountActionTypes {
  return {
    type: SET_SCHOOL_ACCOUNTS,
    schoolAccounts,
  };
}

export function setSponsorAccounts(sponsorAccounts: Account[]): AccountActionTypes {
  return {
    type: SET_SPONSOR_ACCOUNTS,
    sponsorAccounts,
  };
}

export function updateAccountError(error?: string): AccountActionTypes {
  return {
    type: UPDATE_ERROR,
    error,
  };
}

export function getSchoolAccounts(): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(updateLoading(true));
    return accountService
      .getSchoolAccounts()
      .then((schoolAccounts) => {
        dispatch(setSchoolAccounts(Array.isArray(schoolAccounts) ? schoolAccounts : []));
      })
      .catch((error) => {
        dispatch(updateAccountError(error));
      })
      .finally(() => dispatch(updateLoading(false)));
  };
}

export function getSponsorAccounts(): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(updateLoading(true));
    return accountService
      .getSponsorAccounts()
      .then((sponsorAccounts) => {
        dispatch(setSponsorAccounts(Array.isArray(sponsorAccounts) ? sponsorAccounts : []));
      })
      .catch((error) => {
        dispatch(updateAccountError(error));
      })
      .finally(() => dispatch(updateLoading(false)));
  };
}

export const accountActions = {
  getSchoolAccounts,
  getSponsorAccounts,
  setSchoolAccounts,
  setSponsorAccounts,
  updateAccountError,
  updateLoading,
};
