import { Text } from 'native-base';
import React from 'react';
import Marquee from 'react-fast-marquee';
import { Image, StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import { State } from '../store/types';

const styles = StyleSheet.create({
  loadingText: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 14,
  },

  content: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    width: '101%',
    whiteSpace: 'nowrap',
    marginBottom: 5,
    height: 50,
    display: 'flex',
  },

  image: {
    maxWidth: 80,
    aspectRatio: 1,
    height: 40,
    resizeMode: 'center',
    width: 'auto',
    top: 5,
    padding: '0 10px',
    flex: 1,
    marginRight: 'auto',
  },
});

export default function LogoBar() {
  const accountState = useSelector((state: State) => state.account);
  const { sponsorAccounts, error: accountError, loading: accountLoading } = accountState;

  const marqueeWidth = () => {
    const sponsorAccountsWithLogos = sponsorAccounts.filter((account) => {
      return account.logoUrl;
    });
    return sponsorAccountsWithLogos.length * 120;
  };

  if (accountLoading || sponsorAccounts.length < 1) {
    return (
      <View style={styles.content}>
        <Text style={styles.loadingText}>...</Text>
      </View>
    );
  }

  return (
    <View style={{ position: 'absolute', bottom: 0, width: marqueeWidth(), minWidth: '100%' }}>
      <Marquee gradient={false}>
        <View style={styles.content}>
          {sponsorAccounts
            .filter((account) => {
              return account.logoUrl;
            })
            .map((account, index) => {
              const imageUri = {
                uri: account.logoUrl,
              };
              return <Image key={index} source={imageUri} style={styles.image} />;
            })}
        </View>
      </Marquee>
    </View>
  );
}
