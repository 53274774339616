import Checkbox from 'expo-checkbox';
import { useFormik } from 'formik';
import { Body, Button, Container, Content, Form, Input, Item, Label, ListItem, Picker, Text, View } from 'native-base';
import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import AwesomeAlert from 'react-native-awesome-alerts';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  REGISTRATION_OTHER,
  REGISTRATION_RACE_AMERICAN_INDIAN,
  REGISTRATION_RACE_ASIAN,
  REGISTRATION_RACE_BLACK,
  REGISTRATION_RACE_HISPANIC,
  REGISTRATION_RACE_NATIVE_HAWAIIAN,
  REGISTRATION_RACE_NO_DISCLOSURE,
  REGISTRATION_RACE_TWO_OR_MORE,
  REGISTRATION_RACE_WHITE,
} from '../constants/Registration';
import { updateAuthError } from '../store/auth/actions';
import { submitReenrollment, updateRegistrationError } from '../store/registration/actions';
import { State } from '../store/types';
import { colors } from '../styles';
import { SplashScreen } from './SplashScreen';

const styles = StyleSheet.create({
  content: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 40,
  },
  form: {
    marginBottom: 40,
  },
  headerText: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  errorText: {
    color: colors.red,
    fontSize: 12,
  },
  requiredAsterisk: {
    fontSize: 12,
    top: -6,
    position: 'relative',
  },
});

const registrationSchema = Yup.object().shape({
  schoolAccountSfid: Yup.string().required('Please select a school'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  company: Yup.string().required('Required'),
  title: Yup.string(),
  designation: Yup.string(),
  hundredWomenInFinance: Yup.boolean(),
  collegeAttended: Yup.string(),
  race: Yup.string().required('Required'),
});

export default function ReenrollmentScreen({ navigation }) {
  const dispatch = useDispatch();

  const authState = useSelector((state: State) => state.auth);
  const { currentUser, loading, error } = authState;

  const accountState = useSelector((state: State) => state.account);
  const { schoolAccounts, sponsorAccounts } = accountState;

  const registrationState = useSelector((state: State) => state.registration);
  const { volunteerRegistrationData, loading: registrationLoading, error: registrationError } = registrationState;

  const [alertIsVisible, setAlertIsVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const raceOptions = [
    { name: REGISTRATION_RACE_AMERICAN_INDIAN, value: REGISTRATION_RACE_AMERICAN_INDIAN },
    { name: REGISTRATION_RACE_ASIAN, value: REGISTRATION_RACE_ASIAN },
    { name: REGISTRATION_RACE_BLACK, value: REGISTRATION_RACE_BLACK },
    { name: REGISTRATION_RACE_HISPANIC, value: REGISTRATION_RACE_HISPANIC },
    { name: REGISTRATION_RACE_NATIVE_HAWAIIAN, value: REGISTRATION_RACE_NATIVE_HAWAIIAN },
    { name: REGISTRATION_RACE_WHITE, value: REGISTRATION_RACE_WHITE },
    { name: REGISTRATION_RACE_TWO_OR_MORE, value: REGISTRATION_RACE_TWO_OR_MORE },
    { name: REGISTRATION_RACE_NO_DISCLOSURE, value: REGISTRATION_RACE_NO_DISCLOSURE },
  ];

  const formik = useFormik({
    initialValues: {
      schoolAccountSfid: volunteerRegistrationData?.schoolAccountSfid || '',
      firstName: volunteerRegistrationData?.firstName || currentUser?.firstName || '',
      lastName: volunteerRegistrationData?.lastName || currentUser?.lastName || '',
      company: volunteerRegistrationData?.company || '',
      title: volunteerRegistrationData?.title || '',
      designation: volunteerRegistrationData?.designation || '',
      hundredWomenInFinance: volunteerRegistrationData?.hundredWomenInFinance || false,
      collegeAttended: volunteerRegistrationData?.collegeAttended || '',
      race: volunteerRegistrationData?.race || '',
    },
    validationSchema: registrationSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });

  const submit = async (values) => {
    if (!currentUser) {
      return;
    }

    const data = {
      schoolAccountSfid: values.schoolAccountSfid,
      firstName: values.firstName,
      lastName: values.lastName,
      company: values.company,
      title: values.title,
      designation: values.designation,
      hundredWomenInFinance: values.hundredWomenInFinance,
      collegeAttended: values.collegeAttended,
      race: values.race,
    };

    const response = await dispatch(submitReenrollment(currentUser, data));

    if (response as any) {
      setSuccess(true);
    }
  };

  const schoolPickerItems = schoolAccounts
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((account) => {
      return <Picker.Item label={account.name} value={account.sfid} key={account.id} />;
    });

  const sponsorItems = sponsorAccounts
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((account) => {
      return <Picker.Item label={account.name} value={account.sfid} key={account.id} />;
    });

  const racePickerItems = raceOptions.map((option, index) => {
    return <Picker.Item label={option.name} value={option.value} key={index} />;
  });

  useEffect(() => {
    if (error) {
      setAlertTitle('Error');
      setAlertMessage(error);
      setAlertIsVisible(true);
      dispatch(updateAuthError(undefined));
    }

    if (registrationError) {
      setAlertTitle('Enrollment unsuccessful');
      setAlertMessage(registrationError);
      setAlertIsVisible(true);
      dispatch(updateRegistrationError(undefined));
    }
  }, [error, registrationError]);

  if (loading || registrationLoading) {
    return <SplashScreen />;
  }

  return (
    <Container>
      <Content style={styles.content}>
        <Body>
          <Text style={styles.headerText}>Reenrollment</Text>

          {success ? (
            <View>
              <Text style={{ fontSize: 16, marginTop: 30, marginBottom: 20, textAlign: 'center' }}>
                Your reenrollment has been submitted.
              </Text>
              <Text style={{ fontSize: 18, marginTop: 0, marginBottom: 20, textAlign: 'center' }}>Thanks!</Text>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Button bordered style={{ flex: 1, margin: 5 }} onPress={() => navigation.navigate('Tabs')} full>
                  <Text>CLOSE</Text>
                </Button>
              </View>
            </View>
          ) : (
            <View>
              <Text style={{ fontSize: 16, marginTop: 30, marginBottom: 20, textAlign: 'center' }}>
                Fill out the form below to reenroll in the Rock The Street, Wall Street program for the upcoming school
                year.
              </Text>
              <Form style={{ marginBottom: 20 }}>
                <Item
                  stackedLabel
                  error={formik.touched.schoolAccountSfid && formik.errors.schoolAccountSfid ? true : false}
                >
                  <Label>
                    School<Text style={styles.requiredAsterisk}>*</Text>
                  </Label>
                  <Text style={styles.errorText}>
                    {formik.touched.schoolAccountSfid && formik.errors.schoolAccountSfid
                      ? formik.errors.schoolAccountSfid
                      : null}
                  </Text>
                  <Picker
                    mode="dropdown"
                    style={{ width: '100%' }}
                    selectedValue={formik.values.schoolAccountSfid}
                    onValueChange={formik.handleChange('schoolAccountSfid')}
                  >
                    <Picker.Item label="" value="" key={schoolPickerItems.length + 1} />
                    {schoolPickerItems}
                  </Picker>
                </Item>
                <Item stackedLabel error={formik.touched.firstName && formik.errors.firstName ? true : false}>
                  <Label>
                    First Name<Text style={styles.requiredAsterisk}>*</Text>
                  </Label>
                  <Text style={styles.errorText}>
                    {formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : null}
                  </Text>
                  <Input
                    placeholder=""
                    autoCapitalize="none"
                    returnKeyType="next"
                    onChangeText={formik.handleChange('firstName')}
                    value={formik.values.firstName}
                  />
                </Item>

                <Item stackedLabel error={formik.touched.lastName && formik.errors.lastName ? true : false}>
                  <Label>
                    Last Name<Text style={styles.requiredAsterisk}>*</Text>
                  </Label>
                  <Text style={styles.errorText}>
                    {formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : null}
                  </Text>
                  <Input
                    placeholder=""
                    onChangeText={formik.handleChange('lastName')}
                    autoCapitalize="none"
                    returnKeyType="next"
                    value={formik.values.lastName}
                  />
                </Item>

                <Item stackedLabel error={formik.touched.race && formik.errors.race ? true : false}>
                  <Label>
                    Race<Text style={styles.requiredAsterisk}>*</Text>
                  </Label>
                  <Text style={styles.errorText}>
                    {formik.touched.race && formik.errors.race ? formik.errors.race : null}
                  </Text>
                  <Picker
                    mode="dropdown"
                    style={{ width: '100%' }}
                    selectedValue={formik.values.race}
                    onValueChange={formik.handleChange('race')}
                  >
                    <Picker.Item label="" value="" />
                    {racePickerItems}
                  </Picker>
                </Item>

                <Item stackedLabel error={formik.touched.company && formik.errors.company ? true : false}>
                  <Label>
                    Firm (Company)<Text style={styles.requiredAsterisk}>*</Text>
                  </Label>
                  <Text style={styles.errorText}>
                    {formik.touched.company && formik.errors.company ? formik.errors.company : null}
                  </Text>
                  <Picker
                    mode="dropdown"
                    style={{ width: '100%' }}
                    selectedValue={formik.values.company}
                    onValueChange={formik.handleChange('company')}
                  >
                    <Picker.Item label="" value="" />
                    {sponsorItems}
                    <Picker.Item label="Other" value={REGISTRATION_OTHER} />
                  </Picker>
                </Item>

                <Item stackedLabel error={formik.touched.title && formik.errors.title ? true : false}>
                  <Label>Title</Label>
                  <Text style={styles.errorText}>
                    {formik.touched.title && formik.errors.title ? formik.errors.title : null}
                  </Text>
                  <Input
                    placeholder=""
                    onChangeText={formik.handleChange('title')}
                    autoCapitalize="none"
                    returnKeyType="next"
                    value={formik.values.title}
                  />
                </Item>
                <Item
                  stackedLabel
                  error={formik.touched.collegeAttended && formik.errors.collegeAttended ? true : false}
                >
                  <Label>Undergraduate University</Label>
                  <Text style={styles.errorText}>
                    {formik.touched.collegeAttended && formik.errors.collegeAttended
                      ? formik.errors.collegeAttended
                      : null}
                  </Text>
                  <Input
                    placeholder=""
                    onChangeText={formik.handleChange('collegeAttended')}
                    autoCapitalize="none"
                    returnKeyType="next"
                    value={formik.values.collegeAttended}
                  />
                </Item>
                <Item stackedLabel error={formik.touched.designation && formik.errors.designation ? true : false}>
                  <Label>Designation (CFA/CFP/CAIA, etc)</Label>
                  <Text style={styles.errorText}>
                    {formik.touched.designation && formik.errors.designation ? formik.errors.designation : null}
                  </Text>
                  <Input
                    placeholder=""
                    onChangeText={formik.handleChange('designation')}
                    autoCapitalize="none"
                    returnKeyType="next"
                    value={formik.values.designation}
                  />
                </Item>
                <ListItem>
                  <Checkbox
                    value={formik.values.hundredWomenInFinance}
                    onValueChange={(value) => formik.setFieldValue('hundredWomenInFinance', value)}
                    color={formik.values.hundredWomenInFinance ? colors.primary : undefined}
                  />
                  <Body>
                    <Text>100 Women in Finance</Text>
                  </Body>
                </ListItem>
              </Form>
              <View style={{ display: 'flex', flexDirection: 'row', marginLeft: '16px' }}>
                <Button bordered style={{ flex: 1, margin: 5 }} onPress={() => navigation.navigate('Tabs')} full>
                  <Text>CLOSE</Text>
                </Button>
                <Button style={{ flex: 2, margin: 5 }} onPress={() => formik.handleSubmit()} full>
                  <Text numberOfLines={1}>RE-ENROLL NOW</Text>
                </Button>
              </View>
            </View>
          )}
        </Body>

        <AwesomeAlert
          show={alertIsVisible}
          showProgress={false}
          title={alertTitle}
          message={alertMessage}
          closeOnHardwareBackPress={false}
          showConfirmButton
          confirmText="OK"
          confirmButtonColor="#5cb85c"
          alertContainerStyle={{ height: 'auto', minHeight: 700, position: 'absolute', left: 0 }}
          onConfirmPressed={() => {
            setAlertIsVisible(false);
          }}
        />
      </Content>
    </Container>
  );
}
