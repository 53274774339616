import { ResourceActionTypes, ResourceState, SET_RESOURCE, SET_RESOURCES, UPDATE_ERROR, UPDATE_LOADING } from './types';

const initialState: ResourceState = {
  currentResource: null,
  resources: [],
  loading: false,
};

export default function resourceReducer(state = initialState, action: ResourceActionTypes): ResourceState {
  switch (action.type) {
    case UPDATE_LOADING:
      return { ...state, loading: action.newValue };
    case SET_RESOURCE:
      return { ...state, currentResource: action.currentResource };
    case SET_RESOURCES:
      return { ...state, resources: action.resources };
    case UPDATE_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
}
