import Checkbox from 'expo-checkbox';
import { useFormik } from 'formik';
import { Body, Button, Container, Content, Form, Input, Item, Label, ListItem, Picker, Text } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Dimensions, ScrollView, StyleSheet } from 'react-native';
import AwesomeAlert from 'react-native-awesome-alerts';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { states } from '../../constants/FormOptions';
import {
  RegistrationTypes,
  REGISTRATION_OTHER,
  REGISTRATION_RACE_AMERICAN_INDIAN,
  REGISTRATION_RACE_ASIAN,
  REGISTRATION_RACE_BLACK,
  REGISTRATION_RACE_HISPANIC,
  REGISTRATION_RACE_NATIVE_HAWAIIAN,
  REGISTRATION_RACE_NO_DISCLOSURE,
  REGISTRATION_RACE_TWO_OR_MORE,
  REGISTRATION_RACE_WHITE,
} from '../../constants/Registration';
import { updateAccountError } from '../../store/account/actions';
import {
  setVolunteerRegistration,
  submitRegistration,
  updateRegistrationError,
} from '../../store/registration/actions';
import { VolunteerRegistrationData } from '../../store/registration/types';
import { State } from '../../store/types';
import { colors } from '../../styles';
import { SplashScreen } from '../SplashScreen';

const screenHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  content: {
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 40,
    maxHeight: screenHeight - 64,
  },
  form: {
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 10,
    marginBottom: 40,
  },
  headerText: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },

  releaseHeader: {
    fontSize: 14,
    textAlign: 'center',
  },

  releaseSectionTitle: {
    fontWeight: 'bold',
    fontSize: 12,
  },

  bodyText: {
    fontSize: 14,
  },
  errorText: {
    color: colors.red,
    fontSize: 12,
  },
  releaseText: {
    fontSize: 12,
    marginTop: 5,
    textAlign: 'justify',
  },
  requiredAsterisk: {
    fontSize: 12,
    top: -6,
    position: 'relative',
  },
});

let registrationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  alternateEmail: Yup.string().email('Please provide a valid email address'),
  schoolAccountSfid: Yup.string().required('Please select a school'),
  mobilePhone: Yup.string().required('Required'),
  addressLine1: Yup.string().required('Required'),
  addressLine2: Yup.string(),
  addressCity: Yup.string().required('Required'),
  addressState: Yup.string().required('Required'),
  addressStateOther: Yup.string(),
  addressCountry: Yup.string().required('Required'),
  addressZip: Yup.string().required('Required'),
  company: Yup.string().required('Required'),
  title: Yup.string(),
  designation: Yup.string(),
  hundredWomenInFinance: Yup.boolean(),
  collegeAttended: Yup.string(),
  fallWorkshopInterested: Yup.boolean(),
  springMentorInterested: Yup.boolean(),
  race: Yup.string().required('Required'),
});

// extended validation
registrationSchema = registrationSchema.test('programCheckboxTest', 'Please select at least one program.', (obj) => {
  if (obj.fallWorkshopInterested || obj.springMentorInterested) {
    return true;
  }

  return new Yup.ValidationError('Please select at least one program.', null, 'fallWorkshopInterested');
});

export default function VolunteerRegistrationScreen() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: State) => state.auth.currentUser);

  const registrationState = useSelector((state: State) => state.registration);
  const { volunteerRegistrationData, error: registrationError, loading: registrationLoading } = registrationState;

  const accountState = useSelector((state: State) => state.account);
  const { sponsorAccounts, schoolAccounts, error: accountError, loading: accountLoading } = accountState;

  const [alertIsVisible, setAlertIsVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const raceOptions = [
    { name: REGISTRATION_RACE_AMERICAN_INDIAN, value: REGISTRATION_RACE_AMERICAN_INDIAN },
    { name: REGISTRATION_RACE_ASIAN, value: REGISTRATION_RACE_ASIAN },
    { name: REGISTRATION_RACE_BLACK, value: REGISTRATION_RACE_BLACK },
    { name: REGISTRATION_RACE_HISPANIC, value: REGISTRATION_RACE_HISPANIC },
    { name: REGISTRATION_RACE_NATIVE_HAWAIIAN, value: REGISTRATION_RACE_NATIVE_HAWAIIAN },
    { name: REGISTRATION_RACE_WHITE, value: REGISTRATION_RACE_WHITE },
    { name: REGISTRATION_RACE_TWO_OR_MORE, value: REGISTRATION_RACE_TWO_OR_MORE },
    { name: REGISTRATION_RACE_NO_DISCLOSURE, value: REGISTRATION_RACE_NO_DISCLOSURE },
  ];

  const formik = useFormik({
    initialValues: {
      schoolAccountSfid: volunteerRegistrationData?.schoolAccountSfid || '',
      firstName: volunteerRegistrationData?.firstName || currentUser?.firstName || '',
      lastName: volunteerRegistrationData?.lastName || currentUser?.lastName || '',
      alternateEmail: volunteerRegistrationData?.alternateEmail || '',
      mobilePhone: volunteerRegistrationData?.mobilePhone || '',
      addressLine1: volunteerRegistrationData?.addressLine1 || '',
      addressLine2: volunteerRegistrationData?.addressLine2 || '',
      addressCity: volunteerRegistrationData?.addressCity || '',
      addressState: volunteerRegistrationData?.addressState || '',
      addressStateOther: volunteerRegistrationData?.addressStateOther || '',
      addressCountry: volunteerRegistrationData?.addressCountry || 'USA',
      addressZip: volunteerRegistrationData?.addressZip || '',
      company: volunteerRegistrationData?.company || '',
      title: volunteerRegistrationData?.title || '',
      designation: volunteerRegistrationData?.designation || '',
      hundredWomenInFinance: volunteerRegistrationData?.hundredWomenInFinance || false,
      collegeAttended: volunteerRegistrationData?.collegeAttended || '',
      fallWorkshopInterested: volunteerRegistrationData?.fallWorkshopInterested || false,
      springMentorInterested: volunteerRegistrationData?.springMentorInterested || false,
      race: volunteerRegistrationData?.race || '',
    },
    validationSchema: registrationSchema,
    onSubmit: (values) => {
      saveAndSubmit(values);
    },
  });

  const saveAndSubmit = (values) => {
    if (!currentUser) {
      return;
    }

    const addressState = values.addressState === 'other' ? values.addressStateOther : values.addressState;
    const newRegistrationData: Partial<VolunteerRegistrationData> = {
      type: RegistrationTypes.VOLUNTEER,
      email: currentUser?.email,
      firstName: values.firstName,
      lastName: values.lastName,
      alternateEmail: values.alternateEmail,
      schoolAccountSfid: values.schoolAccountSfid,
      mobilePhone: values.mobilePhone,
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      addressCity: values.addressCity,
      addressState: addressState,
      addressStateOther: values.addressStateOther,
      addressCountry: values.addressCountry,
      addressZip: values.addressZip,
      company: values.company,
      title: values.title,
      designation: values.designation,
      hundredWomenInFinance: values.hundredWomenInFinance,
      collegeAttended: values.collegeAttended,
      fallWorkshopInterested: values.fallWorkshopInterested,
      springMentorInterested: values.springMentorInterested,
      race: values.race,
    };

    dispatch(setVolunteerRegistration(newRegistrationData));
    dispatch(submitRegistration(newRegistrationData, currentUser));
  };

  const schoolItems = schoolAccounts
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((account) => {
      return <Picker.Item label={account.name} value={account.sfid} key={account.id} />;
    });

  const sponsorItems = sponsorAccounts
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((account) => {
      return <Picker.Item label={account.name} value={account.sfid} key={account.id} />;
    });

  const racePickerItems = raceOptions.map((option, index) => {
    return <Picker.Item label={option.name} value={option.value} key={index} />;
  });

  useEffect(() => {
    if (accountError) {
      setAlertTitle('Error');
      setAlertMessage(accountError);
      setAlertIsVisible(true);
      dispatch(updateAccountError(undefined));
    }

    if (registrationError) {
      setAlertTitle('Please try again');
      setAlertMessage(registrationError);
      setAlertIsVisible(true);
      dispatch(updateRegistrationError(undefined));
    }
  }, [accountError, registrationError]);

  if (registrationLoading || accountLoading) {
    return <SplashScreen />;
  }

  return (
    <Container style={{ flex: 1 }}>
      <ScrollView>
        <Content style={styles.content}>
          <Body>
            <Text style={styles.headerText}>
              Please review the release agreement and fill out the form below to complete your registration.
            </Text>
          </Body>
          <ListItem>
            <Body>
              <Text style={styles.releaseHeader}>VOLUNTEER RELEASE AGREEMENT</Text>
              <Text style={styles.releaseText}>
                In consideration for the instructor training (“Training”) to be provided by Rock The Street, Wall
                Street, a Tennessee non-profit organization, and its present and former affiliates, subsidiaries,
                parents, successors, predecessors, directors, officers, owners, board members, agents, assigns, estates,
                heirs and employees (“RTSWS”) to ________________________ (“Volunteer”), RTSWS’ permission for Volunteer
                to instruct students in a financial literacy and mentorship program and to accompany the students on
                field trips from time-to-time to various financial institutions and events (collectively the “Program”),
                and for other good and valuable consideration, the receipt and sufficiency of which is hereby
                acknowledged, Volunteer hereby agrees as follows:
              </Text>

              <Text style={styles.releaseText}>
                <Text style={styles.releaseSectionTitle}>1. Background Checks.</Text> Volunteer acknowledges that
                Volunteer’s participation in the Training and the Program will involve working directly with children
                (including minors). Accordingly, Volunteer agrees to provide RTSWS with any reasonably requested
                information to allow RTSWS, in its reasonable discretion, to perform a background check (including
                inquiries of criminal records) on Volunteer for the purpose of ensuring the protection of the children
                involved in the Program. Background checks are conducted by Verified First, 1550 South Tech Lane, Suite
                200, Meridian, Idaho 83642.{' '}
              </Text>
              <Text style={styles.releaseText}>
                <Text style={styles.releaseSectionTitle}>2. Use of Name, Likeness or Image.</Text> Volunteer
                acknowledges that Volunteer may be photographed or videotaped during Training or participation in the
                Program. Volunteer hereby releases all rights to any such photographs or video footage and hereby agrees
                that RTSWS, its sponsors and third-party media may, without any compensation to Volunteer or additional
                authorization form, use Volunteer’s likeness or image in connection with RTSWS organization or promotion
                thereof.
              </Text>
              <Text style={styles.releaseText}>
                <Text style={styles.releaseSectionTitle}>3. Release.</Text> Volunteer understands, appreciates, and
                acknowledges there is a risk of injury or other harm related to the Training and the instruction of
                students in the Program (including field trips). Volunteer voluntarily assumes the risk of any injuries
                and/or harm Volunteer may incur during Training or during Volunteer’s participation in the Program.
                Volunteer agrees that if Volunteer suffers any harm, including without limitation, personal injury or
                any loss of or damage to personal property, Volunteer will not attempt to claim coverage under any RTSWS
                insurance policy or otherwise make any claim against RTSWS related to such harm. Further, in
                consideration of the opportunity to receive the Training and participate in the Program, Volunteer, on
                behalf of Volunteer, Volunteer’s agents, heirs and next of kin, hereby releases RTSWS from any
                responsibility or liability for any harm, damage, or injury that Volunteer may incur during Training or
                participation in the Program, regardless of whether such harm is the result of RTSWS’ negligence.
              </Text>

              <Text style={styles.releaseText}>
                <Text style={styles.releaseSectionTitle}>4. No Employment.</Text> Nothing contained in this release will
                constitute a partnership, joint venture or employer/employee relationship between Volunteer and RTSWS.
                Volunteer is performing Volunteer’s obligations under this Agreement as an uncompensated volunteer and
                not as RTSWS’ agent or employee. Neither party to this release will hold itself out contrary to the
                terms of this paragraph, and neither Volunteer nor RTSWS will become liable for any representation, act
                or omission of the other contrary to the provisions of this release.
              </Text>

              <Text style={styles.releaseText}>
                <Text style={styles.releaseSectionTitle}>5. Indemnification.</Text> RTSWS and Volunteer shall each fully
                indemnify each other and hold harmless from and against any and all judgments, settlement payments,
                fines, sanctions and all other costs, liabilities, obligations and expenses (including, but not limited
                to, attorneys’ fees and expenses) incurred by the other in connection with any action, suit, claim,
                allegation or proceeding (whether prosecution, defense, or otherwise) that is brought, made or
                threatened by any individual or entity in connection with any dispute or potential dispute to the
                proportionate extent arising directly or indirectly out of or in connection with intentional, willful,
                wanton, reckless or grossly negligent acts related to the Program including but not limited to (i) the
                Training provided by RTSWS to Volunteer, (ii) any instructional materials or manuals provided to
                Volunteer by RTSWS in connection with the Training, or (iii) Volunteer’s instruction of students through
                the Program.
              </Text>

              <Text style={styles.releaseText}>
                <Text style={styles.releaseSectionTitle}>6. Non-Compete.</Text> Volunteer agrees that Volunteer shall
                not directly or indirectly compete with the defined Program of RTSWS during the period that Volunteer
                participates in the Program and for one (1) year following termination of Volunteer’s participation in
                the Program.
              </Text>

              <Text style={styles.releaseText}>
                <Text style={styles.releaseSectionTitle}>7. Confidential Information.</Text> Volunteer acknowledges that
                RTSWS shall or may in reliance on this release provide Volunteer access to trade secrets, proprietary
                information, and other communications reasonable expected to be confidential (“Confidential
                Information”) and that the provisions of this release are reasonably necessary to protect RTSWS and its
                good will and Confidential Information. Volunteer agrees to retain said Confidential Information as
                confidential and not to use said information on his or her own behalf or disclose same to any third
                party not involved in the Program. Confidential Information shall not include information that is or
                becomes available to the public due to no fault of the Volunteer.
              </Text>

              <Text style={styles.releaseText}>
                <Text style={styles.releaseSectionTitle}>8. Termination.</Text> This Agreement shall commence on the
                last date set forth below and may be terminated by either party upon ten (10) days prior written notice
                provided, however, that this Agreement may be terminated immediately upon written notice in the event
                the other party breaches any material term of this Agreement (the “Term”).
              </Text>

              <Text style={styles.releaseText}>
                <Text style={styles.releaseSectionTitle}>9. Information Exchanged.</Text> This Agreement does not grant
                any warranty, express or implied, with respect to the quality validity or usefulness of exchanged
                information. Neither party shall be liable in damages of any kind as a result of any of the other
                party’s reliance on, or use of the information exchanged.
              </Text>

              <Text style={styles.releaseText}>
                <Text style={styles.releaseSectionTitle}>10. Disparaging Remarks / Morals.</Text> Each party hereto will
                refrain from publicly making any negative, derogatory or non-flattering comments about the other or any
                of the other’s affiliates, or employees, independent contractors, volunteers, agents, officers, or
                directors, at any time during or after the Term of this Agreement. In WITNESS WHEREOF, the parties have
                executed this Agreement effective the date first written above.
              </Text>
              <Text style={styles.releaseText}>
                In WITNESS WHEREOF, the parties have executed this Agreement effective the date first written above.
              </Text>
            </Body>
          </ListItem>
          <Body style={{ width: '100%' }}>
            <Text style={{ fontSize: 12, alignSelf: 'flex-end' }}>* Required</Text>
          </Body>
          <Form style={styles.form}>
            <Item
              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginTop: 10 }}
              error={formik.errors.fallWorkshopInterested ? true : false}
            >
              <Text style={{ fontSize: 12, fontWeight: 'bold', alignSelf: 'flex-start' }}>
                Please choose a program to volunteer{' '}
              </Text>
              <Text style={{ fontSize: 12, alignSelf: 'flex-start' }}>(select one or both)</Text>
              <Text style={[styles.errorText, { alignSelf: 'flex-start' }]}>
                {formik.errors.fallWorkshopInterested ? formik.errors.fallWorkshopInterested : null}
              </Text>
            </Item>
            <ListItem>
              <Checkbox
                value={formik.values.fallWorkshopInterested}
                onValueChange={(value) => formik.setFieldValue('fallWorkshopInterested', value)}
                color={formik.values.fallWorkshopInterested ? colors.primary : undefined}
              />
              <Body>
                <Text>Fall Workshop</Text>
              </Body>
            </ListItem>
            <ListItem>
              <Checkbox
                value={formik.values.springMentorInterested}
                onValueChange={(value) => formik.setFieldValue('springMentorInterested', value)}
                color={formik.values.springMentorInterested ? colors.primary : undefined}
              />
              <Body>
                <Text>Spring Mentorship</Text>
              </Body>
            </ListItem>
            <Item
              stackedLabel
              error={formik.touched.schoolAccountSfid && formik.errors.schoolAccountSfid ? true : false}
            >
              <Label>
                School<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.schoolAccountSfid && formik.errors.schoolAccountSfid
                  ? formik.errors.schoolAccountSfid
                  : null}
              </Text>
              <Picker
                mode="dropdown"
                style={{ width: '100%' }}
                selectedValue={formik.values.schoolAccountSfid}
                onValueChange={formik.handleChange('schoolAccountSfid')}
              >
                <Picker.Item label="" value="" />
                {schoolItems}
                <Picker.Item label="Other" value={REGISTRATION_OTHER} />
              </Picker>
            </Item>

            <Item stackedLabel error={formik.touched.firstName && formik.errors.firstName ? true : false}>
              <Label>
                First Name<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : null}
              </Text>
              <Input
                placeholder=""
                autoCapitalize="none"
                returnKeyType="next"
                onChangeText={formik.handleChange('firstName')}
                value={formik.values.firstName}
              />
            </Item>

            <Item stackedLabel error={formik.touched.lastName && formik.errors.lastName ? true : false}>
              <Label>
                Last Name<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : null}
              </Text>
              <Input
                placeholder=""
                onChangeText={formik.handleChange('lastName')}
                autoCapitalize="none"
                returnKeyType="next"
                value={formik.values.lastName}
              />
            </Item>
            <Item stackedLabel error={formik.touched.mobilePhone && formik.errors.mobilePhone ? true : false}>
              <Label>
                Mobile Phone<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.mobilePhone && formik.errors.mobilePhone ? formik.errors.mobilePhone : null}
              </Text>
              <Input
                placeholder=""
                onChangeText={formik.handleChange('mobilePhone')}
                autoCapitalize="none"
                returnKeyType="next"
                value={formik.values.mobilePhone}
              />
            </Item>
            <Item stackedLabel error={formik.touched.alternateEmail && formik.errors.alternateEmail ? true : false}>
              <Label>Alternate Email Address</Label>
              <Text style={styles.errorText}>
                {formik.touched.alternateEmail && formik.errors.alternateEmail ? formik.errors.alternateEmail : null}
              </Text>
              <Input
                placeholder=""
                onChangeText={formik.handleChange('alternateEmail')}
                autoCapitalize="none"
                returnKeyType="next"
                value={formik.values.alternateEmail}
              />
            </Item>
            <Item stackedLabel error={formik.touched.addressLine1 && formik.errors.addressLine1 ? true : false}>
              <Label>
                Address Line 1<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.addressLine1 && formik.errors.addressLine1 ? formik.errors.addressLine1 : null}
              </Text>
              <Input
                placeholder=""
                onChangeText={formik.handleChange('addressLine1')}
                autoCapitalize="none"
                returnKeyType="next"
                value={formik.values.addressLine1}
              />
            </Item>
            <Item stackedLabel error={formik.touched.addressLine2 && formik.errors.addressLine2 ? true : false}>
              <Label>
                Address Line 2<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.addressLine2 && formik.errors.addressLine2 ? formik.errors.addressLine2 : null}
              </Text>
              <Input
                placeholder=""
                onChangeText={formik.handleChange('addressLine2')}
                autoCapitalize="none"
                returnKeyType="next"
                value={formik.values.addressLine2}
              />
            </Item>
            <Item stackedLabel error={formik.touched.addressCity && formik.errors.addressCity ? true : false}>
              <Label>
                City<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.addressCity && formik.errors.addressCity ? formik.errors.addressCity : null}
              </Text>
              <Input
                placeholder=""
                onChangeText={formik.handleChange('addressCity')}
                autoCapitalize="none"
                returnKeyType="next"
                value={formik.values.addressCity}
              />
            </Item>
            <Item stackedLabel error={formik.touched.addressState && formik.errors.addressState ? true : false}>
              <Label>
                State<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.addressState && formik.errors.addressState ? formik.errors.addressState : null}
              </Text>

              <Picker
                mode="dropdown"
                style={{ width: '100%', flex: 2, marginRight: 2 }}
                selectedValue={formik.values.addressState}
                onValueChange={formik.handleChange('addressState')}
              >
                <Picker.Item label="" value="" key={states.length + 1} />
                {states.map((state, index) => (
                  <Picker.Item label={state.label} value={state.value} key={index} />
                ))}
              </Picker>
            </Item>
            {formik.values.addressState === 'other' ? (
              <Item
                stackedLabel
                error={formik.touched.addressStateOther && formik.errors.addressStateOther ? true : false}
              >
                <Label>
                  State/Province/Region<Text style={styles.requiredAsterisk}>*</Text>
                </Label>
                <Text style={styles.errorText}>
                  {formik.touched.addressStateOther && formik.errors.addressStateOther
                    ? formik.errors.addressStateOther
                    : null}
                </Text>
                <Input
                  placeholder=""
                  onChangeText={formik.handleChange('addressStateOther')}
                  autoCapitalize="none"
                  returnKeyType="next"
                  value={formik.values.addressStateOther}
                />
              </Item>
            ) : null}
            <Item stackedLabel error={formik.touched.addressZip && formik.errors.addressZip ? true : false}>
              <Label>
                Zip Code<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.addressZip && formik.errors.addressZip ? formik.errors.addressZip : null}
              </Text>
              <Input
                placeholder=""
                onChangeText={formik.handleChange('addressZip')}
                autoCapitalize="none"
                returnKeyType="next"
                value={formik.values.addressZip}
              />
            </Item>
            <Item stackedLabel error={formik.touched.addressCountry && formik.errors.addressCountry ? true : false}>
              <Label>
                Country<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.addressCountry && formik.errors.addressCountry ? formik.errors.addressCountry : null}
              </Text>

              <Picker
                mode="dropdown"
                style={{ width: '100%', flex: 2, marginRight: 2 }}
                selectedValue={formik.values.addressCountry}
                onValueChange={formik.handleChange('addressCountry')}
              >
                <Picker.Item label="Canada" value="Canada" key="1" />
                <Picker.Item label="United Kingdom" value="UK" key="2" />
                <Picker.Item label="United States" value="USA" key="3" />
              </Picker>
            </Item>
            <Item stackedLabel error={formik.touched.race && formik.errors.race ? true : false}>
              <Label>
                Race<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.race && formik.errors.race ? formik.errors.race : null}
              </Text>
              <Picker
                mode="dropdown"
                style={{ width: '100%' }}
                selectedValue={formik.values.race}
                onValueChange={formik.handleChange('race')}
              >
                <Picker.Item label="" value="" />
                {racePickerItems}
              </Picker>
            </Item>
            <Item stackedLabel error={formik.touched.company && formik.errors.company ? true : false}>
              <Label>
                Firm (Company)<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.company && formik.errors.company ? formik.errors.company : null}
              </Text>
              <Picker
                mode="dropdown"
                style={{ width: '100%' }}
                selectedValue={formik.values.company}
                onValueChange={formik.handleChange('company')}
              >
                <Picker.Item label="" value="" />
                {sponsorItems}
                <Picker.Item label="Other" value={REGISTRATION_OTHER} />
              </Picker>
            </Item>

            <Item stackedLabel error={formik.touched.title && formik.errors.title ? true : false}>
              <Label>Title</Label>
              <Text style={styles.errorText}>
                {formik.touched.title && formik.errors.title ? formik.errors.title : null}
              </Text>
              <Input
                placeholder=""
                onChangeText={formik.handleChange('title')}
                autoCapitalize="none"
                returnKeyType="next"
                value={formik.values.title}
              />
            </Item>
            <Item stackedLabel error={formik.touched.collegeAttended && formik.errors.collegeAttended ? true : false}>
              <Label>Undergraduate University</Label>
              <Text style={styles.errorText}>
                {formik.touched.collegeAttended && formik.errors.collegeAttended ? formik.errors.collegeAttended : null}
              </Text>
              <Input
                placeholder=""
                onChangeText={formik.handleChange('collegeAttended')}
                autoCapitalize="none"
                returnKeyType="next"
                value={formik.values.collegeAttended}
              />
            </Item>
            <Item stackedLabel error={formik.touched.designation && formik.errors.designation ? true : false}>
              <Label>Designation (CFA/CFP/CAIA, etc)</Label>
              <Text style={styles.errorText}>
                {formik.touched.designation && formik.errors.designation ? formik.errors.designation : null}
              </Text>
              <Input
                placeholder=""
                onChangeText={formik.handleChange('designation')}
                autoCapitalize="none"
                returnKeyType="next"
                value={formik.values.designation}
              />
            </Item>
            <ListItem>
              <Checkbox
                value={formik.values.hundredWomenInFinance}
                onValueChange={(value) => formik.setFieldValue('hundredWomenInFinance', value)}
                color={formik.values.hundredWomenInFinance ? colors.primary : undefined}
              />
              <Body>
                <Text>100 Women in Finance</Text>
              </Body>
            </ListItem>
          </Form>
          <Button onPress={() => formik.handleSubmit()} full>
            <Text>COMPLETE REGISTRATION</Text>
          </Button>
        </Content>
        <AwesomeAlert
          show={alertIsVisible}
          showProgress={false}
          title={alertTitle}
          message={alertMessage}
          closeOnHardwareBackPress={false}
          showConfirmButton
          confirmText="OK"
          confirmButtonColor="#5cb85c"
          alertContainerStyle={{ height: 'auto', minHeight: 700, position: 'absolute', left: 0 }}
          onConfirmPressed={() => {
            setAlertIsVisible(false);
          }}
        />
      </ScrollView>
    </Container>
  );
}

// If I choose to register as a Volunteer…
// Check a box to sign up as a “Fall Workshop Volunteer”
// Check a box to sign up as a “Spring Mentorship Volunteer”
// User is required to check at least one of the boxes presented in items 1.1.1.1 and 1.1.1.2.
// User will be allowed to check both boxes presented in items 1.1.1.1 and 1.1.1.2 to sign up as a volunteer for both programs.
