import { Resource } from '../models';
import BaseService from './BaseService';

export class ResourceService extends BaseService {
  async getResources() {
    return (await this.getJson<Resource[]>('/resources')).data;
  }
}

export default new ResourceService();
