import { SettingActionTypes, SettingState, SET_SETTING, SET_SETTINGS, UPDATE_ERROR, UPDATE_LOADING } from './types';

const initialState: SettingState = {
  currentSetting: null,
  settings: [],
  loading: false,
};

export default function settingReducer(state = initialState, action: SettingActionTypes): SettingState {
  switch (action.type) {
    case UPDATE_LOADING:
      return { ...state, loading: action.newValue };
    case SET_SETTING:
      return { ...state, currentSetting: action.currentSetting };
    case SET_SETTINGS:
      return { ...state, settings: action.settings };
    case UPDATE_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
}
