import { Button, Text, View } from 'native-base';
import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../store/actions';
import { State } from '../../store/types';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    alignItems: 'center',
    width: '100%',
  },

  topContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  middleContainer: {
    flex: 1,
    width: '100%',
    padding: 20,
  },

  bottomContainer: {
    justifyContent: 'flex-end',
    margin: 20,
    padding: 10,
  },
  text: {
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 10,
    textAlign: 'center',
  },
  subtext: {
    marginTop: 20,
    textAlign: 'center',
    fontSize: 12,
  },
});

export default function RegistrationSelectScreen({ navigation }) {
  const dispatch = useDispatch();

  const currentDevice = useSelector((state: State) => state.devices.currentDevice);

  const signOut = useCallback(() => {
    dispatch(authActions.signOutUser(currentDevice?.id, true));
  }, [currentDevice, dispatch]);

  return (
    <View style={styles.container}>
      <View style={styles.topContainer}>
        <Text style={styles.text}>Please select your role for the Rock The Street, Wall Street program.</Text>
        <Text style={styles.subtext}>Note: If you are a School Champion, please register as a Student.</Text>
      </View>

      <View style={styles.middleContainer}>
        <Button style={{ width: '100%' }} onPress={() => navigation.navigate('StudentRegistration')} full>
          <Text style={{ fontWeight: 'bold' }}>I AM A STUDENT</Text>
        </Button>
        <Button
          style={{ width: '100%', marginTop: 20 }}
          onPress={() => navigation.navigate('VolunteerRegistration')}
          full
        >
          <Text style={{ fontWeight: 'bold' }}>I AM A VOLUNTEER</Text>
        </Button>
      </View>
      <View style={styles.bottomContainer}>
        <Button transparent style={{ marginTop: 20 }} onPress={() => signOut()}>
          <Text>Log Out</Text>
        </Button>
      </View>
    </View>
  );
}
