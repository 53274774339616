import { useFocusEffect } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { Body, Card, CardItem, Container, Content } from 'native-base';
import React from 'react';
import { Image, StyleSheet, TouchableWithoutFeedback } from 'react-native';

const styles = StyleSheet.create({
  content: {
    paddingTop: 'calc(10vh)',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: 0,
    paddingRight: 0,
  },
  image: {
    height: 280,
    width: 'calc(85vw)',
    justifyContent: 'center',
    resizeMode: 'contain',
  },
  cardContent: {
    backgroundColor: '#f95702',
  },
});

export default function RedirectToUrl() {
  const sijUrl = 'https://rockthestreetwallstreet.com/scholarships-internships-jobs/';

  const _handlePressButtonAsync = async (url: string) => {
    await WebBrowser.openBrowserAsync(url);
  };

  useFocusEffect(
    React.useCallback(() => {
      _handlePressButtonAsync(sijUrl);
    }, []),
  );

  return (
    <Container>
      <Content style={styles.content}>
        <TouchableWithoutFeedback onPress={() => _handlePressButtonAsync(sijUrl)}>
          <Card>
            <CardItem style={[styles.cardContent]}>
              <Body>
                <Image source={require('../../assets/images/sij_portal_image_app_final.jpg')} style={styles.image} />
              </Body>
            </CardItem>
          </Card>
        </TouchableWithoutFeedback>
      </Content>
    </Container>
  );
}
