import { Device } from '../../models';

export const SET_DEVICE = '@@devices/SET_DEVICE';
export const SET_DEVICES = '@@devices/SET_DEVICES';
export const UPDATE_LOADING = '@@devices/UPDATE_LOADING';
export const UPDATE_ERROR = '@@devices/UPDATE_ERROR';

export interface DeviceState {
  currentDevice: Device | null;
  devices: Device[];
  loading: boolean;
  error?: string;
}

export interface SetDeviceAction {
  type: typeof SET_DEVICE;
  currentDevice: Device;
}

export interface SetDevicesAction {
  type: typeof SET_DEVICES;
  devices: Device[];
}

export interface UpdateLoadingAction {
  type: typeof UPDATE_LOADING;
  newValue: boolean;
}

export interface UpdateErrorAction {
  type: typeof UPDATE_ERROR;
  error?: string;
}

export type DeviceActionTypes = SetDeviceAction | SetDevicesAction | UpdateLoadingAction | UpdateErrorAction;
