import { SET_STUDENT, SET_STUDENTS, StudentActionTypes, StudentState, UPDATE_ERROR, UPDATE_LOADING } from './types';

const initialState: StudentState = {
  currentStudent: null,
  students: [],
  loading: false,
};

export default function studentReducer(state = initialState, action: StudentActionTypes): StudentState {
  switch (action.type) {
    case UPDATE_LOADING:
      return { ...state, loading: action.newValue };
    case SET_STUDENT:
      return { ...state, currentStudent: action.currentStudent };
    case SET_STUDENTS:
      return { ...state, students: action.students };
    case UPDATE_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
}
