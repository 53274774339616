import { Campaign } from '../models';
import BaseService from './BaseService';

export class CampaignService extends BaseService {
  async getCampaign() {
    return (await this.getJson<Campaign>('/campaigns')).data;
  }
}

export default new CampaignService();
