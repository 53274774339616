import { Campaign } from '../../models';

export const SET_CAMPAIGN = '@@campaigns/SET_CAMPAIGN';
export const SET_CAMPAIGNS = '@@campaigns/SET_CAMPAIGNS';
export const UPDATE_LOADING = '@@campaigns/UPDATE_LOADING';
export const UPDATE_ERROR = '@@campaigns/UPDATE_ERROR';

export interface CampaignState {
  currentCampaign: Campaign | null;
  campaigns: Campaign[];
  loading: boolean;
  error?: string;
}

export interface SetCampaignAction {
  type: typeof SET_CAMPAIGN;
  currentCampaign: Campaign;
}

export interface SetCampaignsAction {
  type: typeof SET_CAMPAIGNS;
  campaigns: Campaign[];
}

export interface UpdateLoadingAction {
  type: typeof UPDATE_LOADING;
  newValue: boolean;
}

export interface UpdateErrorAction {
  type: typeof UPDATE_ERROR;
  error?: string;
}

export type CampaignActionTypes = SetCampaignAction | SetCampaignsAction | UpdateLoadingAction | UpdateErrorAction;
