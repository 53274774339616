// @flow

export default () => {
  const leftTheme = {
    flex: 1,
    alignSelf: 'center',
    alignItems: 'flex-start',
  };

  return leftTheme;
};
