import Constants from 'expo-constants';
import { Button, Icon, Text, View } from 'native-base';
import React, { useCallback } from 'react';
import { SafeAreaView, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../store/actions';
import { State } from '../store/types';
import { colors } from '../styles';

const styles = StyleSheet.create({
  baseText: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  nameText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  subText: {
    fontSize: 12,
  },
  bottomText: {
    fontSize: 12,
    position: 'absolute',
    bottom: 0,
    right: 10,
    marginTop: 20,
  },
  justified: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
  },
});

export default function DrawerContent({ navigation }) {
  const dispatch = useDispatch();

  const currentUser = useSelector((state: State) => state.auth.currentUser);
  const currentDevice = useSelector((state: State) => state.devices.currentDevice);

  const signOut = useCallback(() => {
    dispatch(authActions.signOutUser(currentDevice?.id, true));
  }, [dispatch, currentDevice]);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={[{ flex: 1 }, styles.justified]}>
        <View
          style={[
            {
              width: '100%',
              marginTop: 30,
              marginBottom: 10,
              paddingBottom: 30,
              borderBottomColor: colors.lightestGrey,
              borderBottomWidth: 1,
            },
            styles.justified,
          ]}
        >
          <Button
            style={{ left: 15 }}
            transparent
            onPress={() => navigation.navigate('UserProfile')}
            testID="drawerContentStudentsBtn"
          >
            <View style={{ marginRight: 5 }}>
              <Icon type="FontAwesome5" style={{ fontSize: 36, color: colors.primary }} name="user-cog" />
            </View>
            <View>
              <Text style={styles.nameText}>
                {currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : ''}
              </Text>
              <Text style={styles.subText}>{currentUser ? currentUser.email : ''}</Text>
            </View>
          </Button>
        </View>
        <View
          style={[
            {
              width: '100%',
              marginTop: 10,
            },
            styles.justified,
          ]}
        >
          <Button style={{ left: 15 }} transparent onPress={() => navigation.navigate('Contacts')}>
            <Icon
              type="MaterialIcons"
              style={{ color: colors.primary, marginLeft: 0, marginRight: 0 }}
              name="contact-mail"
            />
            <Text style={styles.baseText}>Contacts</Text>
          </Button>
          <Button style={{ left: 15 }} transparent onPress={() => navigation.navigate('About')}>
            <Icon
              type="MaterialIcons"
              style={{ color: colors.primary, marginLeft: 0, marginRight: 0 }}
              name="info-outline"
            />
            <Text style={styles.baseText}>About RTSWS</Text>
          </Button>
          <Button style={{ left: 15 }} transparent onPress={() => signOut()} testID="drawerContentSignOutBtn">
            <Icon type="MaterialIcons" style={{ color: colors.primary, marginLeft: 0, marginRight: 0 }} name="logout" />
            <Text style={styles.baseText}>Sign Out</Text>
          </Button>
          <Text style={styles.bottomText}>v{Constants.manifest.version}</Text>
        </View>
      </View>
    </SafeAreaView>
  );
}
