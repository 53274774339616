import { Feather } from '@expo/vector-icons';
import * as Device from 'expo-device';
import { Button, List, ListItem, Text, View } from 'native-base';
import React from 'react';
import { StyleSheet } from 'react-native';
import { colors } from '../styles';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    alignItems: 'center',
    width: '100%',
  },

  topContainer: {
    flex: 1,
    display: 'flex',
    width: '100vw',
  },

  middleContainer: {
    flex: 4,
    display: 'flex',
    width: '90vw',
  },

  bottomContainer: {
    justifyContent: 'flex-end',
    margin: 20,
    padding: 10,
  },

  text: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 30,
    textAlign: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    verticalAlign: 'middle',
  },
  subtext: {
    fontSize: 16,
  },
  steptext: {
    fontSize: 16,
    fontWeight: 'bold',
    display: 'flex',
  },
});

export default function AppInstallationAlert({ navigation }) {
  const isAndroidDevice = Device.osName?.toLowerCase() === 'android';
  const isIosDevice = Device.osName?.toLowerCase() === 'ios';

  if (isIosDevice) {
    return (
      <View style={styles.container}>
        <View style={styles.topContainer}>
          <Text style={styles.text}>
            IMPORTANT: The app is not on your phone yet. Follow the next steps to add the app to your home screen.
          </Text>
        </View>

        <View style={styles.middleContainer}>
          <List>
            <ListItem>
              <View style={{ width: '100%' }}>
                <Text style={styles.steptext}>Step 1</Text>
                <Text style={styles.subtext}>Ensure you are viewing this page in a Safari browser</Text>
              </View>
            </ListItem>
            <ListItem>
              <View style={{ width: '100%' }}>
                <Text style={styles.steptext}>Step 2</Text>
                <Text style={styles.subtext}>
                  Tap the <Feather name="share" size={24} color={colors.primary} /> button at the bottom of your screen
                </Text>
              </View>
            </ListItem>
            <ListItem>
              <View style={{ width: '100%' }}>
                <Text style={styles.steptext}>Step 3</Text>
                <Text style={styles.subtext}>Select "Add to Home Screen"</Text>
              </View>
            </ListItem>
            <ListItem>
              <View style={{ width: '100%' }}>
                <Text style={styles.steptext}>Step 4</Text>
                <Text style={styles.subtext}>Select "Add"</Text>
              </View>
            </ListItem>
          </List>
        </View>

        <View style={styles.bottomContainer}>
          <Button onPress={() => navigation.navigate('Drawer')} full>
            <Text>DISMISS</Text>
          </Button>
        </View>
      </View>
    );
  }

  if (isAndroidDevice) {
    return (
      <View style={styles.container}>
        <View style={styles.topContainer}>
          <Text style={styles.text}>
            IMPORTANT: The app is not on your phone yet. Follow the next steps to add the app to your home screen.
          </Text>
        </View>

        <View style={styles.middleContainer}>
          <List>
            <ListItem>
              <View style={{ width: '100%' }}>
                <Text style={styles.steptext}>Step 1</Text>
                <Text style={styles.subtext}>Ensure you are viewing this page in a Chrome browser</Text>
              </View>
            </ListItem>
            <ListItem>
              <View style={{ width: '100%' }}>
                <Text style={styles.steptext}>Step 2</Text>
                <Text style={styles.subtext}>Tap three dot menu in the top right of your screen</Text>
              </View>
            </ListItem>
            <ListItem>
              <View style={{ width: '100%' }}>
                <Text style={styles.steptext}>Step 3</Text>
                <Text style={styles.subtext}>Select "Add to Home Screen"</Text>
              </View>
            </ListItem>
            <ListItem>
              <View style={{ width: '100%' }}>
                <Text style={styles.steptext}>Step 4</Text>
                <Text style={styles.subtext}>Select "Add"</Text>
              </View>
            </ListItem>
          </List>
        </View>

        <View style={styles.bottomContainer}>
          <Button onPress={() => navigation.navigate('Drawer')} full>
            <Text>DISMISS</Text>
          </Button>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.bottomContainer}>
      <Button onPress={() => navigation.navigate('Drawer')} full>
        <Text>DISMISS</Text>
      </Button>
    </View>
  );
}
