import { Device } from '../../models';
import { deviceService } from '../../services';
import { ThunkResult } from '../types';
import { DeviceActionTypes, SET_DEVICE, SET_DEVICES, UPDATE_ERROR, UPDATE_LOADING } from './types';

export function updateLoading(newValue: boolean): DeviceActionTypes {
  return {
    type: UPDATE_LOADING,
    newValue,
  };
}

export function setDevice(currentDevice: Device): DeviceActionTypes {
  return {
    type: SET_DEVICE,
    currentDevice,
  };
}

export function setDevices(devices: Device[]): DeviceActionTypes {
  return {
    type: SET_DEVICES,
    devices,
  };
}

export function updateDeviceError(error?: string): DeviceActionTypes {
  return {
    type: UPDATE_ERROR,
    error,
  };
}

export function getDevices(): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(updateLoading(true));
    return deviceService
      .getDevices()
      .then((devices) => {
        dispatch(setDevices(Array.isArray(devices) ? devices : []));
      })
      .catch((error) => {
        dispatch(updateDeviceError(error.message));
      })
      .finally(() => dispatch(updateLoading(false)));
  };
}

export function createDevice(device: Partial<Device>): ThunkResult<Promise<void>> {
  return async (dispatch, getState): Promise<void> => {
    dispatch(updateLoading(true));
    return deviceService
      .createDevice(device)
      .then((device) => {
        const { devices } = getState().devices;

        let exists = false;
        const nextDevices = [...devices].map((d) => {
          if (d.id === device.id) {
            exists = true;
            return device;
          }

          return d;
        });

        if (!exists) {
          nextDevices.push(device);
        }

        dispatch(setDevices(nextDevices));
        dispatch(setDevice(device));
      })
      .catch((error) => {
        dispatch(updateDeviceError(error.message));
      })
      .finally(() => dispatch(updateLoading(false)));
  };
}

export function deleteDevice(id: string): ThunkResult<Promise<void>> {
  return async (dispatch, getState): Promise<void> => {
    dispatch(updateLoading(true));
    return deviceService
      .deleteDevice(id)
      .then(() => {
        const { devices } = getState().devices;
        const nextDevices = devices.filter((d) => d.id !== id);
        dispatch(setDevices(nextDevices));
      })
      .catch((error) => {
        dispatch(updateDeviceError(error.message));
      })
      .finally(() => dispatch(updateLoading(false)));
  };
}

export const deviceActions = {
  getDevices,
  createDevice,
  deleteDevice,
  setDevice,
  setDevices,
  updateDeviceError,
  updateLoading,
};
