import { Student } from '../../models';
import { studentService } from '../../services';
import { ThunkResult } from '../types';
import { SET_STUDENT, SET_STUDENTS, StudentActionTypes, UPDATE_ERROR, UPDATE_LOADING } from './types';

export function updateLoading(newValue: boolean): StudentActionTypes {
  return {
    type: UPDATE_LOADING,
    newValue,
  };
}

export function setStudent(currentStudent: Student): StudentActionTypes {
  return {
    type: SET_STUDENT,
    currentStudent,
  };
}

export function setStudents(students: Student[]): StudentActionTypes {
  return {
    type: SET_STUDENTS,
    students,
  };
}

export function updateStudentError(error?: string): StudentActionTypes {
  return {
    type: UPDATE_ERROR,
    error,
  };
}

export function getStudents(): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(updateLoading(true));
    return studentService
      .getStudents()
      .then((students) => {
        dispatch(setStudents(Array.isArray(students) ? students : []));
      })
      .catch((error) => {
        dispatch(updateStudentError(error.message));
      })
      .finally(() => dispatch(updateLoading(false)));
  };
}

export const studentActions = {
  getStudents,
  setStudent,
  setStudents,
  updateStudentError,
  updateLoading,
};
