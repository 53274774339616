import { Body, Card, CardItem, Icon, Left, Text, View } from 'native-base';
import React, { useCallback } from 'react';
import { Button, Image, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../store/auth/actions';
import { State } from '../store/types';
import { colors } from '../styles';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    alignItems: 'center',
    width: '100%',
  },
  h1: {
    color: colors.primary,
    fontSize: 40,
  },
  h2: {
    color: colors.primary,
    fontSize: 14,
    fontWeight: 'bold',
  },
  copy: {
    color: colors.primary,
    fontSize: 12,
  },
  topContainer: {
    flex: 4,
    display: 'flex',
    width: '90vw',
  },
  middleContainer: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  bottomContainer: {
    justifyContent: 'flex-end',
    width: '90%',
    margin: 20,
    padding: 10,
  },
  copyContainer: {
    alignItems: 'center',
    padding: 8,
  },
  image: {
    flex: 1,
    resizeMode: 'contain',
  },
  buttonContainer: {
    backgroundColor: '#044959',
    borderRadius: 5,
    padding: 8,
    margin: 8,
  },
  instructionContainer: {
    alignItems: 'center',
    paddingTop: 8,
  },
  largeInstructionText: {
    color: colors.primary,
    fontSize: 16,
    fontWeight: 'bold',
    paddingBottom: 20,
    textAlign: 'center',
  },
  instructionText: {
    color: colors.primary,
    fontSize: 14,
    fontWeight: 'bold',
    paddingBottom: 10,
    textAlign: 'center',
  },
});

export default function LoginScreen() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state: State) => state.auth);

  const handleLogin = useCallback(async () => {
    dispatch(authActions.signInUser());
  }, [dispatch]);

  return (
    <View style={styles.container}>
      <View style={styles.topContainer}>
        <Image source={require('../../assets/images/RTSWS-Logo_-tag-line_-NAVY-2021.jpeg')} style={styles.image} />
      </View>
      <View style={styles.middleContainer}></View>
      <View style={styles.bottomContainer}>
        <View style={styles.instructionContainer}>
          <Text style={styles.largeInstructionText}>Please read installation instructions carefully.</Text>
          <Text style={styles.instructionText}>
            If on a desktop/laptop, press the login button below and turn off your pop-up blocker.
          </Text>
          <Text style={styles.instructionText}>
            If already installed on mobile device, press the login button below.
          </Text>
        </View>
        <View style={styles.copyContainer}>
          <Card>
            <CardItem>
              <Left>
                <Icon name="logo-apple" ios="logo-apple" android="logo-apple" style={{ color: colors.primary }} />
                <Body>
                  <Text style={styles.h2}>To install this app on iOS</Text>
                  <Text style={styles.copy}>
                    <Text style={[styles.copy, { fontWeight: 'bold' }]}>
                      Important: To add to iOS, you must be in a Safari Browser to install.{' '}
                    </Text>
                    In Safari, tap the ‘Share’ button. Scroll down and tap ‘Add to Home Screen.’
                  </Text>
                </Body>
              </Left>
            </CardItem>
          </Card>

          <Card>
            <CardItem>
              <Left>
                <Icon name="logo-android" ios="logo-android" android="logo-android" style={{ color: colors.primary }} />
                <Body>
                  <Text style={styles.h2}>To install this app on Android</Text>
                  <Text style={styles.copy}>
                    In Chrome, tap the three-dot overflow menu in the top-right corner and then select ‘Add to Home
                    screen.’
                  </Text>
                </Body>
              </Left>
            </CardItem>
          </Card>
        </View>
        <View style={styles.buttonContainer}>
          <Button title="LOGIN" disabled={loading} onPress={handleLogin} color="#044959" />
        </View>
      </View>
    </View>
  );
}
