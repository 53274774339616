import { User } from '../../models';

export const UPDATE_STATUS = '@@auth/UPDATE_STATUS';
export const UPDATE_USER = '@@auth/UPDATE_USER';
export const UPDATE_TOKEN = '@@auth/UPDATE_TOKEN';
export const UPDATE_LOADING = '@@auth/UPDATE_LOADING';
export const UPDATE_ERROR = '@@auth/UPDATE_ERROR';

export enum AuthStatus {
  IDLE = 'idle',
  AUTHENTICATING = 'authenticating',
  AUTHENTICATED = 'authenticated',
  UNAUTHENTICATING = 'unauthenticating',
  UNAUTHENTICATED = 'unauthenticated',
}

export interface AuthState {
  status: AuthStatus;
  currentUser: User | null;
  loading: boolean;
  token?: string;
  error?: string;
}

export interface UpdateStatusAction {
  type: typeof UPDATE_STATUS;
  newStatus: AuthStatus;
}

export interface UpdateUserAction {
  type: typeof UPDATE_USER;
  newUser: User | null;
}

export interface UpdateTokenAction {
  type: typeof UPDATE_TOKEN;
  token?: string;
}

export interface UpdateLoadingAction {
  type: typeof UPDATE_LOADING;
  newValue: boolean;
}

export interface UpdateErrorAction {
  type: typeof UPDATE_ERROR;
  error?: string;
}

export type AuthActionTypes =
  | UpdateStatusAction
  | UpdateUserAction
  | UpdateTokenAction
  | UpdateLoadingAction
  | UpdateErrorAction;
