import { Contact } from '../../models';

export const SET_CONTACT = '@@contact/SET_CONTACT';
export const SET_CONTACTS = '@@contact/SET_CONTACTS';
export const UPDATE_LOADING = '@@contact/UPDATE_LOADING';
export const UPDATE_ERROR = '@@contact/UPDATE_ERROR';

export interface ContactState {
  currentContact: Contact | null;
  contacts: Contact[];
  loading: boolean;
  error?: string;
}

export interface SetContactAction {
  type: typeof SET_CONTACT;
  currentContact: Contact;
}

export interface SetContactsAction {
  type: typeof SET_CONTACTS;
  contacts: Contact[];
}

export interface UpdateLoadingAction {
  type: typeof UPDATE_LOADING;
  newValue: boolean;
}

export interface UpdateErrorAction {
  type: typeof UPDATE_ERROR;
  error?: string;
}

export type ContactActionTypes = SetContactAction | SetContactsAction | UpdateLoadingAction | UpdateErrorAction;
