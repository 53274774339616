import * as WebBrowser from 'expo-web-browser';
import { Body, Button, Card, CardItem, Container, Content, Icon, Left, ListItem, Text, View } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Image, StyleSheet } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';
import Curricula from '../../constants/Curricula';
import { RoleNames } from '../../constants/Roles';
import {
  SETTING_SLUG_FACEBOOK_URL,
  SETTING_SLUG_INSTAGRAM_URL,
  SETTING_SLUG_LINKEDIN_ALUMNI_URL,
  SETTING_SLUG_LINKEDIN_URL,
  SETTING_SLUG_LINKEDIN_VOLUNTEER_URL,
  SETTING_SLUG_PAYPAL_URL,
  SETTING_SLUG_RTSWS_HOME_URL,
  SETTING_SLUG_SNAPCHAT_URL,
  SETTING_SLUG_TWITTER_URL,
  SETTING_SLUG_YOUTUBE_URL,
} from '../../constants/SettingSlugs';
import { Resource } from '../../models';
import { State } from '../../store/types';
import rootStyles, { colors } from '../../styles';
import { getValueForSlug } from '../../utils';
import { SplashScreen } from '../SplashScreen';

const styles = StyleSheet.create({
  content: {
    paddingTop: 5,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
  image: {
    height: 50,
    justifyContent: 'center',
    resizeMode: 'contain',
    margin: 10,
  },

  sijImage: {
    height: 120,
    width: '100%',
    justifyContent: 'center',
    resizeMode: 'contain',
  },
  passwordText: {
    fontSize: 12,
  },
  socialCardItem: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 24,
  },
  linkText: {
    fontSize: 16,
  },
  linkedInLinkText: {
    fontSize: 16,
    color: '#2867b2',
  },
  socialIcon: {
    marginLeft: 6,
    marginRight: 6,
  },
  headerText: {
    fontSize: 22,
  },
  cardHeader: {
    paddingLeft: 0,
    paddingBottom: 5,
  },
  cardContent: {
    paddingLeft: 8,
  },
  curriculumCardContent: {
    paddingLeft: 0,
  },
  cardShadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.27,
    shadowRadius: 4.65,
    elevation: 10,
  },
  greyBg: {
    backgroundColor: colors.lighterGrey,
  },
});

export default function ResourcesScreen() {
  const currentUser = useSelector((state: State) => state.auth.currentUser);
  const currentRoleName = currentUser?.role.name;
  const currentCampaign = useSelector((state: State) => state.campaign.currentCampaign);

  const resourceState = useSelector((state: State) => state.resource);
  const { loading: resourceLoading, resources } = resourceState;

  const settingState = useSelector((state: State) => state.setting);
  const { loading: settingLoading, settings } = settingState;

  const [curriculum, setCurriculum] = useState<typeof Curricula[0] | null>(null);

  const homePageResource: Resource = {
    id: 'homepage',
    name: 'Rock The Street, Wall Street',
    link: getValueForSlug(settings, SETTING_SLUG_RTSWS_HOME_URL),
    icon: 'web',
  };

  useEffect(() => {
    const matchingCurricula = Curricula.filter((curriculum) => {
      return currentCampaign?.curriculumSet === curriculum.name;
    });

    if (matchingCurricula) {
      setCurriculum(matchingCurricula[0]);
    }
  }, [setCurriculum, Curricula, currentCampaign]);

  if (resourceLoading || settingLoading) {
    return <SplashScreen />;
  }

  const _handlePressButtonAsync = async (url: string) => {
    await WebBrowser.openBrowserAsync(url);
  };

  var resourceKey = 0;

  const ResourceLink = ({ resource, iconType, altBackground }) => {
    let icon = resource.icon || 'link';
    return (
      <TouchableOpacity onPress={() => _handlePressButtonAsync(resource.link)}>
        <CardItem style={altBackground ? [styles.cardContent, styles.greyBg] : styles.cardContent}>
          <ListItem icon style={altBackground ? styles.greyBg : null}>
            <Left style={{ marginRight: 10 }}>
              <Button style={{ width: '32px', height: '32px' }}>
                <Icon active name={icon} type={iconType || 'MaterialIcons'} android={icon} ios={icon} />
              </Button>
            </Left>
            <Body>
              <Text style={styles.linkText}>{resource.name}</Text>
            </Body>
          </ListItem>
        </CardItem>
      </TouchableOpacity>
    );
  };

  const renderResourceLink = (resource, iconType?, altBackground?) => {
    return <ResourceLink resource={resource} iconType={iconType} altBackground={altBackground} key={resourceKey++} />;
  };

  const renderCourseCurriculum = () => {
    if (!curriculum) {
      return null;
    }

    const curriculumUrl: string = getValueForSlug(settings, curriculum.curriculum_url_slug);
    const curriculumPassword: string = getValueForSlug(settings, curriculum.curriculum_password_slug);
    const curriculumDisplayName: string = curriculum.curriculum_display_name;
    return (
      <View style={{}}>
        <TouchableOpacity onPress={() => _handlePressButtonAsync(curriculumUrl)}>
          <Card style={styles.cardShadow}>
            <CardItem header style={styles.cardHeader}>
              <Left>
                <Body>
                  <Text style={[rootStyles.accentBorder, styles.headerText]}>Course Curriculum</Text>
                  <Text note>Course Agenda &amp; Reference Material</Text>
                </Body>
              </Left>
            </CardItem>
            <CardItem style={styles.curriculumCardContent}>
              <Left>
                <Body>
                  <Text style={{ color: '#808080', fontSize: 18 }}>{curriculumDisplayName}</Text>
                  <Text style={styles.passwordText} note>
                    Password: {curriculumPassword}
                  </Text>
                </Body>
              </Left>
            </CardItem>
          </Card>
        </TouchableOpacity>
      </View>
    );
  };

  const renderLinkedInLink = () => {
    const linkedInUrlSlug: string =
      currentRoleName === RoleNames.VOLUNTEER ? SETTING_SLUG_LINKEDIN_VOLUNTEER_URL : SETTING_SLUG_LINKEDIN_ALUMNI_URL;
    const linkedInUrlName: string = 'LinkedIn RTSWS Networking Group';
    const linkedInUrl: string = getValueForSlug(settings, linkedInUrlSlug);

    return (
      <TouchableOpacity onPress={() => _handlePressButtonAsync(linkedInUrl)}>
        <CardItem style={[styles.cardContent, styles.greyBg]}>
          <ListItem icon style={styles.greyBg}>
            <Left style={{ marginRight: 10 }}>
              <Button style={{ backgroundColor: '#2867b2', width: '32px', height: '32px' }}>
                <Icon type="FontAwesome" active name="linkedin" android="linkedin" ios="linkedin" />
              </Button>
            </Left>
            <Body>
              <Text style={styles.linkedInLinkText}>{linkedInUrlName}</Text>
            </Body>
          </ListItem>
        </CardItem>
      </TouchableOpacity>
    );
  };

  const renderPaypalLink = () => {
    if (currentRoleName === RoleNames.VOLUNTEER) {
      const paypalResource: Resource = {
        id: 'paypal',
        name: 'Donate to RTSWS',
        link: getValueForSlug(settings, SETTING_SLUG_PAYPAL_URL),
        icon: 'paypal',
      };

      return renderResourceLink(paypalResource, 'FontAwesome', true);
    }
  };

  if (resourceLoading) {
    return <Text>Loading</Text>;
  }

  return (
    <Container>
      <Content style={styles.content}>
        {curriculum ? renderCourseCurriculum() : <View></View>}

        <Card style={styles.cardShadow}>
          <CardItem header style={[styles.cardHeader, styles.greyBg]}>
            <Left>
              <Body>
                <Text style={[rootStyles.accentBorder, styles.headerText]}>Professional Networks</Text>
              </Body>
            </Left>
          </CardItem>
          {renderLinkedInLink()}
          <TouchableOpacity
            onPress={() => _handlePressButtonAsync(getValueForSlug(settings, SETTING_SLUG_LINKEDIN_URL))}
          >
            <CardItem style={[styles.cardContent, styles.greyBg]}>
              <ListItem icon style={styles.greyBg}>
                <Left style={{ marginRight: 10 }}>
                  <Button style={{ backgroundColor: '#2867b2', width: '32px', height: '32px' }}>
                    <Icon type="FontAwesome" active name="linkedin" android="linkedin" ios="linkedin" />
                  </Button>
                </Left>
                <Body>
                  <Text style={styles.linkedInLinkText}>Rock The Street, Wall Street LinkedIn</Text>
                </Body>
              </ListItem>
            </CardItem>
          </TouchableOpacity>
        </Card>

        <Card style={styles.cardShadow}>
          <TouchableOpacity
            onPress={() =>
              _handlePressButtonAsync('https://rockthestreetwallstreet.com/scholarships-internships-jobs/')
            }
          >
            <CardItem style={[{ backgroundColor: '#f95702' }]}>
              <Body>
                <Image
                  source={require('../../../assets/images/sij_portal_image_app_final.jpg')}
                  style={styles.sijImage}
                />
              </Body>
            </CardItem>
          </TouchableOpacity>
        </Card>

        <Card style={styles.cardShadow}>
          <CardItem header style={[styles.cardHeader, styles.greyBg]}>
            <Left>
              <Body>
                <Text style={[rootStyles.accentBorder, styles.headerText]}>Additional Links</Text>
              </Body>
            </Left>
          </CardItem>
          {renderResourceLink(homePageResource, 'MaterialCommunityIcons', true)}
          {renderPaypalLink()}
          {resources.map((resource) => {
            return renderResourceLink(resource, 'MaterialIcons', true);
          })}
        </Card>

        <Card style={styles.cardShadow}>
          <CardItem header style={{ paddingLeft: 0 }}>
            <Left>
              <Body>
                <Text style={[rootStyles.accentBorder, styles.headerText]}>Social Media</Text>
              </Body>
            </Left>
          </CardItem>

          <CardItem style={{ paddingTop: 0, paddingLeft: 10, paddingRight: 24, paddingBottom: 12 }}>
            <Body style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button
                style={{
                  backgroundColor: '#405DE6',
                  width: '32px',
                  height: '32px',
                  borderRadius: 6,
                  justifyContent: 'center',
                }}
                onPress={() => _handlePressButtonAsync(getValueForSlug(settings, SETTING_SLUG_INSTAGRAM_URL))}
              >
                <Icon type="FontAwesome" active name="instagram" android="instagram" ios="instagram" />
              </Button>
              <Button
                style={{
                  backgroundColor: '#1DA1F2',
                  width: '32px',
                  height: '32px',
                  borderRadius: 6,
                  justifyContent: 'center',
                }}
                onPress={() => _handlePressButtonAsync(getValueForSlug(settings, SETTING_SLUG_TWITTER_URL))}
              >
                <Icon type="FontAwesome" active name="twitter" android="twitter" ios="twitter" />
              </Button>
              <Button
                style={{
                  backgroundColor: '#4267B2',
                  width: '32px',
                  height: '32px',
                  borderRadius: 6,
                  justifyContent: 'center',
                }}
                onPress={() => _handlePressButtonAsync(getValueForSlug(settings, SETTING_SLUG_FACEBOOK_URL))}
              >
                <Icon type="FontAwesome" active name="facebook" android="facebook" ios="facebook" />
              </Button>
              <Button
                style={{
                  backgroundColor: '#FF0000',
                  width: '32px',
                  height: '32px',
                  borderRadius: 6,
                  justifyContent: 'center',
                }}
                onPress={() => _handlePressButtonAsync(getValueForSlug(settings, SETTING_SLUG_YOUTUBE_URL))}
              >
                <Icon type="FontAwesome" active name="youtube" android="youtube" ios="youtube" />
              </Button>
              <Button
                style={{
                  backgroundColor: '#FFFC00',
                  width: '32px',
                  height: '32px',
                  borderRadius: 6,
                  justifyContent: 'center',
                }}
                onPress={() => _handlePressButtonAsync(getValueForSlug(settings, SETTING_SLUG_SNAPCHAT_URL))}
              >
                <Icon type="FontAwesome" active name="snapchat-ghost" android="snapchat-ghost" ios="snapchat-ghost" />
              </Button>
            </Body>
          </CardItem>
        </Card>
      </Content>
    </Container>
  );
}
