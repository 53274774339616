import { FIREBASE_MESSAGING_VAPID_KEY } from '@env';
import * as Device from 'expo-device';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { useCallback } from 'react';
import { Platform } from 'react-native';
import { useDispatch } from 'react-redux';
import { createFirebaseApp } from '../../firebase';
import { DevicePlatform } from '../../models';
import { deviceActions } from '../../store/actions';

const isSupported = () => 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;

export const useRegisterForNotifications = () => {
  const dispatch = useDispatch();

  const registerForNotifications = useCallback(() => {
    if (!isSupported()) {
      return;
    }

    Notification.requestPermission().then(async (permission) => {
      if (permission !== 'granted') {
        return;
      }

      const app = createFirebaseApp();
      const messaging = getMessaging(app);

      try {
        const token = await getToken(messaging, {
          vapidKey: FIREBASE_MESSAGING_VAPID_KEY,
        });

        onMessage(messaging, (payload) => {
          console.log('Received foreground message ', payload);
          navigator.serviceWorker.ready.then((registration) => {
            if (!payload.notification) {
              return;
            }

            const { title, body } = payload.notification;

            if (title && body) {
              registration.showNotification(title, {
                body,
              });
            }
          });
        });

        dispatch(
          deviceActions.createDevice({
            expoPushToken: '', // can't send through null or undefined
            devicePushToken: token,
            platform: Platform.OS as DevicePlatform,
            name: Device.deviceName ?? undefined,
            brand: Device.brand ?? undefined,
            manufacturer: Device.manufacturer ?? undefined,
            modelName: Device.modelName ?? undefined,
            osName: Device.osName ?? undefined,
            osVersion: Device.osVersion ?? undefined,
          }),
        );
      } catch (error) {
        console.error('Could not get push token:', error);
      }
    });
  }, []);

  return { registerForNotifications };
};
