import { NotificationActionTypes, NotificationState, SET_NOTIFICATIONS, UPDATE_ERROR, UPDATE_LOADING } from './types';

const initialState: NotificationState = {
  userNotifications: [],
  loading: false,
};

export default function notificationReducer(state = initialState, action: NotificationActionTypes): NotificationState {
  switch (action.type) {
    case UPDATE_LOADING:
      return { ...state, loading: action.newValue };
    case SET_NOTIFICATIONS:
      return { ...state, userNotifications: action.userNotifications };
    case UPDATE_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
}
