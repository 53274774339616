import { Setting } from '../models';
import BaseService from './BaseService';

export class SettingService extends BaseService {
  async getSettings() {
    return (await this.getJson<Setting[]>('/settings')).data;
  }
}

export default new SettingService();
