import { Body, Button, Container, Content, Text, View } from 'native-base';
import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import AwesomeAlert from 'react-native-awesome-alerts';
import { useDispatch, useSelector } from 'react-redux';
import { updateAuthError } from '../store/auth/actions';
import { submitSpringEnrollment, updateRegistrationError } from '../store/registration/actions';
import { State } from '../store/types';
import { colors } from '../styles';
import { SplashScreen } from './SplashScreen';

const styles = StyleSheet.create({
  content: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 40,
  },
  form: {
    marginBottom: 40,
  },
  headerText: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  errorText: {
    color: colors.red,
    fontSize: 12,
  },
});

export default function SpringEnrollmentScreen({ navigation }) {
  const dispatch = useDispatch();

  const authState = useSelector((state: State) => state.auth);
  const { currentUser, loading, error } = authState;

  const registrationState = useSelector((state: State) => state.registration);
  const { loading: registrationLoading, error: registrationError } = registrationState;

  const [alertIsVisible, setAlertIsVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const submit = async () => {
    if (!currentUser) {
      return;
    }

    const response = await dispatch(submitSpringEnrollment(currentUser));

    if (response as any) {
      setAlertTitle('Success');
      setAlertMessage('Spring enrollment has been submitted.');
      setAlertIsVisible(true);
    }
  };

  useEffect(() => {
    if (error) {
      setAlertTitle('Error');
      setAlertMessage(error);
      setAlertIsVisible(true);
      dispatch(updateAuthError(undefined));
    }

    if (registrationError) {
      setAlertTitle('Enrollment Unsuccessful');
      setAlertMessage(registrationError);
      setAlertIsVisible(true);
      dispatch(updateRegistrationError(undefined));
    }
  }, [error, registrationError]);

  if (loading || registrationLoading) {
    return <SplashScreen />;
  }

  return (
    <Container>
      <Content style={styles.content}>
        <Body>
          <Text style={styles.headerText}>Spring Enrollment</Text>

          <Text style={{ fontSize: 16, marginTop: 30, marginBottom: 20, textAlign: 'center' }}>
            Enroll here to participate in the Rock The Street, Wall Street Spring Mentorship program.
          </Text>

          <Text style={{ fontSize: 14, marginBottom: 30, textAlign: 'center' }}>
            You will be meeting (at your school or via Zoom) approximately 1x per month (Jan - April) with female
            financial professionals who will coach you on interview skills, resume building, self advocacy, building a
            LinkedIn profile, and more!
          </Text>

          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Button bordered style={{ flex: 1, margin: 5 }} onPress={() => navigation.navigate('Tabs')} full>
              <Text>CLOSE</Text>
            </Button>
            <Button style={{ flex: 2, margin: 5 }} onPress={() => submit()} full>
              <Text numberOfLines={1}>ENROLL NOW</Text>
            </Button>
          </View>
        </Body>

        <AwesomeAlert
          show={alertIsVisible}
          showProgress={false}
          title={alertTitle}
          message={alertMessage}
          closeOnHardwareBackPress={false}
          showConfirmButton
          confirmText="OK"
          confirmButtonColor="#5cb85c"
          alertContainerStyle={{ height: 'auto', minHeight: 700, position: 'absolute', left: 0 }}
          onConfirmPressed={() => {
            setAlertIsVisible(false);
          }}
        />
      </Content>
    </Container>
  );
}
