import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RoleNames } from '../../constants';
import { AuthStatus } from '../../store/auth/types';
import { State } from '../../store/types';
import { useDataLoader } from '../data';

export interface UseSessionStartParams {
  onStartSession: () => void;
  onInvalidRole: () => void;
  onNoSession?: () => void;
}

export const useSessionStart = ({ onStartSession, onInvalidRole, onNoSession }: UseSessionStartParams) => {
  const { load: loadData, preAuthLoad } = useDataLoader();
  const { loading: authLoading, currentUser, status: authStatus } = useSelector((state: State) => state.auth);
  const [dataLoading, setDataLoading] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    preAuthLoad();
  }, []);

  const loadDataAndNavigate = useCallback(async () => {
    setDataLoading(true);

    await loadData();

    if (currentUser?.role && [RoleNames.STUDENT, RoleNames.VOLUNTEER].includes(currentUser?.role?.name)) {
      onStartSession();
    } else {
      onInvalidRole();
    }

    setDataLoading(false);
  }, [currentUser, onStartSession, onInvalidRole]);

  useEffect(() => {
    if (authLoading || dataLoading || ready) {
      return;
    }

    if (authStatus === AuthStatus.AUTHENTICATED && currentUser) {
      setReady(true);
      loadDataAndNavigate();
    } else {
      if (onNoSession) {
        onNoSession();
      }
    }
  }, [authLoading, dataLoading, ready, currentUser, authStatus, onNoSession]);

  return { dataLoading };
};
