import { ContactActionTypes, ContactState, SET_CONTACT, SET_CONTACTS, UPDATE_ERROR, UPDATE_LOADING } from './types';

const initialState: ContactState = {
  currentContact: null,
  contacts: [],
  loading: false,
};

export default function contactReducer(state = initialState, action: ContactActionTypes): ContactState {
  switch (action.type) {
    case UPDATE_LOADING:
      return { ...state, loading: action.newValue };
    case SET_CONTACT:
      return { ...state, currentContact: action.currentContact };
    case SET_CONTACTS:
      return { ...state, contacts: action.contacts };
    case UPDATE_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
}
