import { User } from '../../models';
import { userService } from '../../services';
import { ReenrollmentData, VolunteerReenrollmentData } from '../../services/UserService';
import { fetchUser } from '../auth/actions';
import { ThunkResult } from '../types';
import {
  RegistrationActionTypes,
  RegistrationData,
  UPDATE_ERROR,
  UPDATE_LOADING,
  UPDATE_REGISTRATION,
  UPDATE_REGISTRATION_SUBMITTED,
  UPDATE_VOLUNTEER_REGISTRATION,
  VolunteerRegistrationData,
} from './types';

export function updateLoading(newValue: boolean): RegistrationActionTypes {
  return {
    type: UPDATE_LOADING,
    newValue,
  };
}

export function setRegistration(newRegistrationData: Partial<RegistrationData>): RegistrationActionTypes {
  return {
    type: UPDATE_REGISTRATION,
    newRegistrationData,
  };
}

export function setVolunteerRegistration(
  newRegistrationData: Partial<VolunteerRegistrationData>,
): RegistrationActionTypes {
  return {
    type: UPDATE_VOLUNTEER_REGISTRATION,
    newRegistrationData,
  };
}

export function updateRegistrationError(error?: string): RegistrationActionTypes {
  return {
    type: UPDATE_ERROR,
    error,
  };
}

export function updateRegistrationSubmitted(registrationSubmitted: boolean): RegistrationActionTypes {
  return {
    type: UPDATE_REGISTRATION_SUBMITTED,
    registrationSubmitted,
  };
}

export function submitRegistration(
  registrationData: Partial<RegistrationData> | Partial<VolunteerRegistrationData>,
  currentUser: User,
): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(updateLoading(true));
    return userService
      .submitRegistration(registrationData, currentUser)
      .then(() => {
        dispatch(fetchUser());
      })
      .catch((error) => {
        dispatch(updateRegistrationError(error.message));
      })
      .finally(() => dispatch(updateLoading(false)));
  };
}

export function submitSpringEnrollment(currentUser: User): ThunkResult<Promise<any>> {
  return async (dispatch): Promise<any> => {
    dispatch(updateLoading(true));
    return userService
      .submitSpringEnrollment(currentUser)
      .then(() => {
        dispatch(fetchUser());
        dispatch(updateRegistrationSubmitted(true));
      })
      .catch((error) => {
        dispatch(updateRegistrationError(error.message));
      })
      .finally(() => dispatch(updateLoading(false)));
  };
}

export function submitReenrollment(
  currentUser: User,
  data: ReenrollmentData | VolunteerReenrollmentData,
): ThunkResult<Promise<any>> {
  return async (dispatch): Promise<any> => {
    dispatch(updateLoading(true));

    return userService
      .submitReenrollment(currentUser, data)
      .then(() => {
        dispatch(fetchUser());
        dispatch(updateRegistrationSubmitted(true));
      })
      .catch((error) => {
        dispatch(updateRegistrationError(error.message));
      })
      .finally(() => dispatch(updateLoading(false)));
  };
}

export const registrationActions = {
  updateLoading,
  setRegistration,
  setVolunteerRegistration,
  updateRegistrationError,
  updateRegistrationSubmitted,
  submitRegistration,
  submitSpringEnrollment,
  submitReenrollment,
};
