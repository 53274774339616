import { Feather } from '@expo/vector-icons';
import moment from 'moment-timezone';
import { Body, Container, Content, Icon, Left, List, ListItem, Right, Text } from 'native-base';
import React from 'react';
import { ScrollView, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import { RefreshControl } from 'react-native-web-refresh-control';
import { useDispatch, useSelector } from 'react-redux';
import { Notification, UserNotification } from '../../models';
import { getNotifications } from '../../store/notification/actions';
import { State } from '../../store/types';
import { colors } from '../../styles';
import { SplashScreen } from '../SplashScreen';

const styles = StyleSheet.create({
  content: {
    paddingTop: 5,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 5,
  },
  h2: {
    marginTop: 20,
    fontSize: 16,
  },
});

export default function NotificationsScreen({ navigation }) {
  const dispatch = useDispatch();

  const notificationState = useSelector((state: State) => state.notification);
  const { loading: notificationLoading, userNotifications } = notificationState;

  const [refreshing, setRefreshing] = React.useState(false);

  const getDateTimeFromString = (str) => {
    return moment(str).format('MMMM Do YYYY h:mm A');
  };

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    dispatch(getNotifications(1));
    setRefreshing(false);
  }, []);

  if (notificationLoading) {
    return <SplashScreen />;
  }

  const bodyLength: number = 40;

  const truncate = (str) => {
    return str.length > bodyLength ? str.substr(0, bodyLength - 1) + '...' : str;
  };

  const renderNotification = (userNotification: UserNotification, index: number) => {
    let notification: Notification = userNotification.notification;
    return (
      <TouchableWithoutFeedback
        onPress={() =>
          navigation.navigate('NotificationDetail', {
            id: userNotification.id,
          })
        }
        key={index}
      >
        <ListItem avatar>
          <Left style={{ marginRight: 10 }}>
            <Icon
              type="MaterialIcons"
              style={{ color: notification.urgency ? colors.red : colors.primary }}
              name="notifications"
            />
          </Left>
          <Body>
            <Text style={{ fontSize: 14 }} note>
              {getDateTimeFromString(notification.createdAt)}
            </Text>
            <Text
              style={{ fontSize: 16, fontWeight: 'bold', color: notification.urgency ? colors.red : colors.primary }}
            >
              {notification.subject}
            </Text>
            <Text style={{ fontSize: 14, color: notification.urgency ? colors.red : colors.primary }}>
              {truncate(notification.message)}
            </Text>
          </Body>
          <Right>
            <Feather name="chevron-right" size={25} style={{ color: colors.primary }} />
          </Right>
        </ListItem>
      </TouchableWithoutFeedback>
    );
  };

  return (
    <Container>
      <ScrollView refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
        <Content>
          {userNotifications.length > 0 ? (
            <List>
              {userNotifications.map((notification, index) => {
                return renderNotification(notification, index);
              })}
            </List>
          ) : (
            <Body>
              <Text style={styles.h2}>No notifications.</Text>
            </Body>
          )}
        </Content>
      </ScrollView>
    </Container>
  );
}
