import { UserNotification } from '../../models';

export const SET_NOTIFICATION = '@@notifications/SET_NOTIFICATION';
export const SET_NOTIFICATIONS = '@@notifications/SET_NOTIFICATIONS';
export const UPDATE_LOADING = '@@notifications/UPDATE_LOADING';
export const UPDATE_ERROR = '@@notifications/UPDATE_ERROR';

export interface NotificationState {
  userNotifications: UserNotification[];
  loading: boolean;
  error?: string;
}
export interface SetNotificationsAction {
  type: typeof SET_NOTIFICATIONS;
  userNotifications: UserNotification[];
}

export interface UpdateLoadingAction {
  type: typeof UPDATE_LOADING;
  newValue: boolean;
}

export interface UpdateErrorAction {
  type: typeof UPDATE_ERROR;
  error?: string;
}

export type NotificationActionTypes = SetNotificationsAction | UpdateLoadingAction | UpdateErrorAction;
