import { Setting } from '../../models';

export const SET_SETTING = '@@settings/SET_SETTING';
export const SET_SETTINGS = '@@settings/SET_SETTINGS';
export const UPDATE_LOADING = '@@settings/UPDATE_LOADING';
export const UPDATE_ERROR = '@@settings/UPDATE_ERROR';

export interface SettingState {
  currentSetting: Setting | null;
  settings: Setting[];
  loading: boolean;
  error?: string;
}

export interface SetSettingAction {
  type: typeof SET_SETTING;
  currentSetting: Setting;
}

export interface SetSettingsAction {
  type: typeof SET_SETTINGS;
  settings: Setting[];
}

export interface UpdateLoadingAction {
  type: typeof UPDATE_LOADING;
  newValue: boolean;
}

export interface UpdateErrorAction {
  type: typeof UPDATE_ERROR;
  error?: string;
}

export type SettingActionTypes = SetSettingAction | SetSettingsAction | UpdateLoadingAction | UpdateErrorAction;
