import {
  SETTING_SLUG_CURRICULUM_PASSWORD_INSTRUCTOR_CANADA,
  SETTING_SLUG_CURRICULUM_PASSWORD_INSTRUCTOR_COPPER,
  SETTING_SLUG_CURRICULUM_PASSWORD_INSTRUCTOR_GOLD,
  SETTING_SLUG_CURRICULUM_PASSWORD_INSTRUCTOR_GREEN,
  SETTING_SLUG_CURRICULUM_PASSWORD_INSTRUCTOR_SILVER,
  SETTING_SLUG_CURRICULUM_PASSWORD_INSTRUCTOR_UK,
  SETTING_SLUG_CURRICULUM_PASSWORD_MENTOR,
  SETTING_SLUG_CURRICULUM_PASSWORD_PROTEGE,
  SETTING_SLUG_CURRICULUM_PASSWORD_PROTEGEE,
  SETTING_SLUG_CURRICULUM_PASSWORD_STUDENT_CANADA,
  SETTING_SLUG_CURRICULUM_PASSWORD_STUDENT_COPPER,
  SETTING_SLUG_CURRICULUM_PASSWORD_STUDENT_GOLD,
  SETTING_SLUG_CURRICULUM_PASSWORD_STUDENT_GREEN,
  SETTING_SLUG_CURRICULUM_PASSWORD_STUDENT_SILVER,
  SETTING_SLUG_CURRICULUM_PASSWORD_STUDENT_UK,
  SETTING_SLUG_CURRICULUM_URL_INSTRUCTOR_CANADA,
  SETTING_SLUG_CURRICULUM_URL_INSTRUCTOR_COPPER,
  SETTING_SLUG_CURRICULUM_URL_INSTRUCTOR_GOLD,
  SETTING_SLUG_CURRICULUM_URL_INSTRUCTOR_GREEN,
  SETTING_SLUG_CURRICULUM_URL_INSTRUCTOR_SILVER,
  SETTING_SLUG_CURRICULUM_URL_INSTRUCTOR_UK,
  SETTING_SLUG_CURRICULUM_URL_MENTOR,
  SETTING_SLUG_CURRICULUM_URL_PROTEGE,
  SETTING_SLUG_CURRICULUM_URL_PROTEGEE,
  SETTING_SLUG_CURRICULUM_URL_STUDENT_CANADA,
  SETTING_SLUG_CURRICULUM_URL_STUDENT_COPPER,
  SETTING_SLUG_CURRICULUM_URL_STUDENT_GOLD,
  SETTING_SLUG_CURRICULUM_URL_STUDENT_GREEN,
  SETTING_SLUG_CURRICULUM_URL_STUDENT_SILVER,
  SETTING_SLUG_CURRICULUM_URL_STUDENT_UK,
} from './SettingSlugs';

const CURRICULUM_STUDENT_CANADA = {
  name: 'Canadian Student',
  curriculum_display_name: 'Canadian Student Workbook',
  curriculum_password_slug: SETTING_SLUG_CURRICULUM_PASSWORD_STUDENT_CANADA,
  curriculum_url_slug: SETTING_SLUG_CURRICULUM_URL_STUDENT_CANADA,
};

const CURRICULUM_STUDENT_COPPER = {
  name: 'Copper Student',
  curriculum_display_name: 'Copper Student Workbook',
  curriculum_password_slug: SETTING_SLUG_CURRICULUM_PASSWORD_STUDENT_COPPER,
  curriculum_url_slug: SETTING_SLUG_CURRICULUM_URL_STUDENT_COPPER,
};

const CURRICULUM_STUDENT_GREEN = {
  name: 'Green Student',
  curriculum_display_name: 'Green Student Workbook',
  curriculum_password_slug: SETTING_SLUG_CURRICULUM_PASSWORD_STUDENT_GREEN,
  curriculum_url_slug: SETTING_SLUG_CURRICULUM_URL_STUDENT_GREEN,
};

const CURRICULUM_STUDENT_SILVER = {
  name: 'Silver Student',
  curriculum_display_name: 'Silver Student Workbook',
  curriculum_password_slug: SETTING_SLUG_CURRICULUM_PASSWORD_STUDENT_SILVER,
  curriculum_url_slug: SETTING_SLUG_CURRICULUM_URL_STUDENT_SILVER,
};

const CURRICULUM_STUDENT_GOLD = {
  name: 'Gold Student',
  curriculum_display_name: 'Gold Student Workbook',
  curriculum_password_slug: SETTING_SLUG_CURRICULUM_PASSWORD_STUDENT_GOLD,
  curriculum_url_slug: SETTING_SLUG_CURRICULUM_URL_STUDENT_GOLD,
};

const CURRICULUM_STUDENT_UK = {
  name: 'UK Student',
  curriculum_display_name: 'UK Student Workbook',
  curriculum_password_slug: SETTING_SLUG_CURRICULUM_PASSWORD_STUDENT_UK,
  curriculum_url_slug: SETTING_SLUG_CURRICULUM_URL_STUDENT_UK,
};

const CURRICULUM_INSTRUCTOR_CANADA = {
  name: 'Canadian Instructor',
  curriculum_display_name: 'Canadian Instructor Workbook',
  curriculum_password_slug: SETTING_SLUG_CURRICULUM_PASSWORD_INSTRUCTOR_CANADA,
  curriculum_url_slug: SETTING_SLUG_CURRICULUM_URL_INSTRUCTOR_CANADA,
};

const CURRICULUM_INSTRUCTOR_COPPER = {
  name: 'Copper Instructor',
  curriculum_display_name: 'Copper Instructor Workbook',
  curriculum_password_slug: SETTING_SLUG_CURRICULUM_PASSWORD_INSTRUCTOR_COPPER,
  curriculum_url_slug: SETTING_SLUG_CURRICULUM_URL_INSTRUCTOR_COPPER,
};

const CURRICULUM_INSTRUCTOR_UK = {
  name: 'UK Instructor',
  curriculum_display_name: 'UK Instructor Workbook',
  curriculum_password_slug: SETTING_SLUG_CURRICULUM_PASSWORD_INSTRUCTOR_UK,
  curriculum_url_slug: SETTING_SLUG_CURRICULUM_URL_INSTRUCTOR_UK,
};

const CURRICULUM_INSTRUCTOR_GREEN = {
  name: 'Green Instructor',
  curriculum_display_name: 'Green Instructor Workbook',
  curriculum_password_slug: SETTING_SLUG_CURRICULUM_PASSWORD_INSTRUCTOR_GREEN,
  curriculum_url_slug: SETTING_SLUG_CURRICULUM_URL_INSTRUCTOR_GREEN,
};

const CURRICULUM_INSTRUCTOR_SILVER = {
  name: 'Silver Instructor',
  curriculum_display_name: 'Silver Instructor Workbook',
  curriculum_password_slug: SETTING_SLUG_CURRICULUM_PASSWORD_INSTRUCTOR_SILVER,
  curriculum_url_slug: SETTING_SLUG_CURRICULUM_URL_INSTRUCTOR_SILVER,
};

const CURRICULUM_INSTRUCTOR_GOLD = {
  name: 'Gold Instructor',
  curriculum_display_name: 'Gold Instructor Workbook',
  curriculum_password_slug: SETTING_SLUG_CURRICULUM_PASSWORD_INSTRUCTOR_GOLD,
  curriculum_url_slug: SETTING_SLUG_CURRICULUM_URL_INSTRUCTOR_GOLD,
};

const CURRICULUM_PROTEGE = {
  name: 'Protege',
  curriculum_display_name: 'Protege Workbook',
  curriculum_password_slug: SETTING_SLUG_CURRICULUM_PASSWORD_PROTEGE,
  curriculum_url_slug: SETTING_SLUG_CURRICULUM_URL_PROTEGE,
};

const CURRICULUM_PROTEGEE = {
  name: 'Protegee',
  curriculum_display_name: 'Protegee Workbook',
  curriculum_password_slug: SETTING_SLUG_CURRICULUM_PASSWORD_PROTEGEE,
  curriculum_url_slug: SETTING_SLUG_CURRICULUM_URL_PROTEGEE,
};

const CURRICULUM_MENTOR = {
  name: 'Mentor',
  curriculum_display_name: 'Mentor Workbook',
  curriculum_password_slug: SETTING_SLUG_CURRICULUM_PASSWORD_MENTOR,
  curriculum_url_slug: SETTING_SLUG_CURRICULUM_URL_MENTOR,
};

export default [
  CURRICULUM_STUDENT_CANADA,
  CURRICULUM_STUDENT_COPPER,
  CURRICULUM_STUDENT_GREEN,
  CURRICULUM_STUDENT_SILVER,
  CURRICULUM_STUDENT_GOLD,
  CURRICULUM_STUDENT_UK,
  CURRICULUM_INSTRUCTOR_CANADA,
  CURRICULUM_INSTRUCTOR_COPPER,
  CURRICULUM_INSTRUCTOR_GREEN,
  CURRICULUM_INSTRUCTOR_SILVER,
  CURRICULUM_INSTRUCTOR_GOLD,
  CURRICULUM_INSTRUCTOR_UK,
  CURRICULUM_PROTEGE,
  CURRICULUM_PROTEGEE,
  CURRICULUM_MENTOR,
];
