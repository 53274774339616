export const SETTING_SLUG_API_MODE = 'api_mode';
export const SETTING_SLUG_CURRICULUM_PASSWORD_STUDENT_CANADA = 'curriculum_password_student_canada';
export const SETTING_SLUG_CURRICULUM_PASSWORD_STUDENT_COPPER = 'curriculum_password_student_copper';
export const SETTING_SLUG_CURRICULUM_PASSWORD_STUDENT_GREEN = 'curriculum_password_student_green';
export const SETTING_SLUG_CURRICULUM_PASSWORD_STUDENT_SILVER = 'curriculum_password_student_silver';
export const SETTING_SLUG_CURRICULUM_PASSWORD_STUDENT_GOLD = 'curriculum_password_student_gold';
export const SETTING_SLUG_CURRICULUM_PASSWORD_STUDENT_UK = 'curriculum_password_student_uk';
export const SETTING_SLUG_CURRICULUM_PASSWORD_INSTRUCTOR_CANADA = 'curriculum_password_instructor_canada';
export const SETTING_SLUG_CURRICULUM_PASSWORD_INSTRUCTOR_COPPER = 'curriculum_password_instructor_copper';
export const SETTING_SLUG_CURRICULUM_PASSWORD_INSTRUCTOR_GREEN = 'curriculum_password_instructor_green';
export const SETTING_SLUG_CURRICULUM_PASSWORD_INSTRUCTOR_SILVER = 'curriculum_password_instructor_silver';
export const SETTING_SLUG_CURRICULUM_PASSWORD_INSTRUCTOR_GOLD = 'curriculum_password_instructor_gold';
export const SETTING_SLUG_CURRICULUM_PASSWORD_INSTRUCTOR_UK = 'curriculum_password_instructor_uk';
export const SETTING_SLUG_CURRICULUM_PASSWORD_PROTEGE = 'curriculum_password_protege';
export const SETTING_SLUG_CURRICULUM_PASSWORD_PROTEGEE = 'curriculum_password_protegee';
export const SETTING_SLUG_CURRICULUM_PASSWORD_MENTOR = 'curriculum_password_mentor';
export const SETTING_SLUG_CURRICULUM_URL_STUDENT_CANADA = 'curriculum_url_student_canada';
export const SETTING_SLUG_CURRICULUM_URL_STUDENT_COPPER = 'curriculum_url_student_copper';
export const SETTING_SLUG_CURRICULUM_URL_STUDENT_GREEN = 'curriculum_url_student_green';
export const SETTING_SLUG_CURRICULUM_URL_STUDENT_SILVER = 'curriculum_url_student_silver';
export const SETTING_SLUG_CURRICULUM_URL_STUDENT_GOLD = 'curriculum_url_student_gold';
export const SETTING_SLUG_CURRICULUM_URL_STUDENT_UK = 'curriculum_url_student_uk';
export const SETTING_SLUG_CURRICULUM_URL_INSTRUCTOR_CANADA = 'curriculum_url_instructor_canada';
export const SETTING_SLUG_CURRICULUM_URL_INSTRUCTOR_COPPER = 'curriculum_url_instructor_copper';
export const SETTING_SLUG_CURRICULUM_URL_INSTRUCTOR_GREEN = 'curriculum_url_instructor_green';
export const SETTING_SLUG_CURRICULUM_URL_INSTRUCTOR_SILVER = 'curriculum_url_instructor_silver';
export const SETTING_SLUG_CURRICULUM_URL_INSTRUCTOR_GOLD = 'curriculum_url_instructor_gold';
export const SETTING_SLUG_CURRICULUM_URL_INSTRUCTOR_UK = 'curriculum_url_instructor_uk';
export const SETTING_SLUG_CURRICULUM_URL_PROTEGE = 'curriculum_url_protege';
export const SETTING_SLUG_CURRICULUM_URL_PROTEGEE = 'curriculum_url_protegee';
export const SETTING_SLUG_CURRICULUM_URL_MENTOR = 'curriculum_url_mentor';
export const SETTING_SLUG_PROGRAM_TEAM_ONE_EMAIL = 'program_team_one_email';
export const SETTING_SLUG_PROGRAM_TEAM_ONE_NAME = 'program_team_one_name';
export const SETTING_SLUG_PROGRAM_TEAM_ONE_TITLE = 'program_team_one_title';
export const SETTING_SLUG_PROGRAM_TEAM_TWO_EMAIL = 'program_team_two_email';
export const SETTING_SLUG_PROGRAM_TEAM_TWO_NAME = 'program_team_two_name';
export const SETTING_SLUG_PROGRAM_TEAM_TWO_TITLE = 'program_team_two_title';
export const SETTING_SLUG_PROGRAM_TEAM_THREE_EMAIL = 'program_team_three_email';
export const SETTING_SLUG_PROGRAM_TEAM_THREE_NAME = 'program_team_three_name';
export const SETTING_SLUG_PROGRAM_TEAM_THREE_TITLE = 'program_team_three_title';
export const SETTING_SLUG_PROGRAM_TEAM_FOUR_EMAIL = 'program_team_four_email';
export const SETTING_SLUG_PROGRAM_TEAM_FOUR_NAME = 'program_team_four_name';
export const SETTING_SLUG_PROGRAM_TEAM_FOUR_TITLE = 'program_team_four_title';
export const SETTING_SLUG_PROGRAM_TEAM_FIVE_EMAIL = 'program_team_five_email';
export const SETTING_SLUG_PROGRAM_TEAM_FIVE_NAME = 'program_team_five_name';
export const SETTING_SLUG_PROGRAM_TEAM_FIVE_TITLE = 'program_team_five_title';
export const SETTING_SLUG_PROGRAM_TEAM_SIX_EMAIL = 'program_team_six_email';
export const SETTING_SLUG_PROGRAM_TEAM_SIX_NAME = 'program_team_six_name';
export const SETTING_SLUG_PROGRAM_TEAM_SIX_TITLE = 'program_team_six_title';
export const SETTING_SLUG_PROGRAM_TEAM_SEVEN_EMAIL = 'program_team_seven_email';
export const SETTING_SLUG_PROGRAM_TEAM_SEVEN_NAME = 'program_team_seven_name';
export const SETTING_SLUG_PROGRAM_TEAM_SEVEN_TITLE = 'program_team_seven_title';
export const SETTING_SLUG_PROGRAM_TEAM_EIGHT_EMAIL = 'program_team_eight_email';
export const SETTING_SLUG_PROGRAM_TEAM_EIGHT_NAME = 'program_team_eight_name';
export const SETTING_SLUG_PROGRAM_TEAM_EIGHT_TITLE = 'program_team_eight_title';
export const SETTING_SLUG_LINKEDIN_URL = 'linkedin_url';
export const SETTING_SLUG_LINKEDIN_ALUMNI_URL = 'linkedin_alumni_url';
export const SETTING_SLUG_LINKEDIN_VOLUNTEER_URL = 'linkedin_volunteer_url';
export const SETTING_SLUG_RTSWS_HOME_URL = 'rtsws_home_url';
export const SETTING_SLUG_RTSWS_JOBS_URL = 'rtsws_jobs_url';
export const SETTING_SLUG_PAYPAL_URL = 'paypal_url';
export const SETTING_SLUG_INSTAGRAM_URL = 'instagram_url';
export const SETTING_SLUG_TWITTER_URL = 'twitter_url';
export const SETTING_SLUG_YOUTUBE_URL = 'youtube_url';
export const SETTING_SLUG_FACEBOOK_URL = 'facebook_url';
export const SETTING_SLUG_SNAPCHAT_URL = 'snapchat_url';
