import {
  ProgramEventActionTypes,
  ProgramEventState,
  SET_PROGRAM_EVENT,
  SET_PROGRAM_EVENTS,
  UPDATE_ERROR,
  UPDATE_LOADING,
} from './types';

const initialState: ProgramEventState = {
  currentProgramEvent: null,
  programEvents: [],
  loading: false,
};

export default function programEventReducer(state = initialState, action: ProgramEventActionTypes): ProgramEventState {
  switch (action.type) {
    case UPDATE_LOADING:
      return { ...state, loading: action.newValue };
    case SET_PROGRAM_EVENT:
      return { ...state, currentProgramEvent: action.currentProgramEvent };
    case SET_PROGRAM_EVENTS:
      return { ...state, programEvents: action.programEvents };
    case UPDATE_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
}
