import { UserNotification } from '../models';
import BaseService from './BaseService';

export class NotificationService extends BaseService {
  async getNotifications(page?: number, count?: number) {
    const currentPage: number = page || 1;

    let params = {};

    if (page) {
      params['page'] = currentPage;
    }

    if (count) {
      params['count'] = count;
    }

    return (
      await this.getJson<UserNotification[]>('/user-notifications', {
        params,
      })
    ).data;
  }

  async getNotification(id: string) {
    return (await this.getJson<UserNotification>(`/users/${id}`)).data;
  }
}

export default new NotificationService();
