import { User } from '../../models';
import { authService, userService } from '../../services';
import { deviceActions } from '../actions';
import { ThunkResult } from '../types';
import {
  AuthActionTypes,
  AuthStatus,
  UPDATE_ERROR,
  UPDATE_LOADING,
  UPDATE_STATUS,
  UPDATE_TOKEN,
  UPDATE_USER,
} from './types';

export function updateStatus(newStatus: AuthStatus): AuthActionTypes {
  return {
    type: UPDATE_STATUS,
    newStatus,
  };
}

export function updateLoading(newValue: boolean): AuthActionTypes {
  return {
    type: UPDATE_LOADING,
    newValue,
  };
}

export function setUser(newUser: User | null): AuthActionTypes {
  return {
    type: UPDATE_USER,
    newUser,
  };
}

export function setToken(token: string): AuthActionTypes {
  return {
    type: UPDATE_TOKEN,
    token,
  };
}

export function updateAuthError(error?: string): AuthActionTypes {
  return {
    type: UPDATE_ERROR,
    error,
  };
}

export function notAuthenticated(): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(updateLoading(false));
    dispatch(updateStatus(AuthStatus.UNAUTHENTICATED));
  };
}

export function fetchUser(): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(updateLoading(true));

    return userService
      .getSelf()
      .then((user) => {
        dispatch(setUser(user));
        dispatch(updateStatus(AuthStatus.AUTHENTICATED));
      })
      .catch((error) => {
        dispatch(updateAuthError(error?.message ?? 'Unable to fetch user.'));
      })
      .finally(() => {
        dispatch(updateLoading(false));
      });
  };
}

export function updateCurrentUser(user: Partial<User>): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(updateLoading(true));

    return userService
      .updateSelf(user)
      .then((user) => {
        dispatch(setUser(user));
        dispatch(updateStatus(AuthStatus.AUTHENTICATED));
      })
      .catch((error) => {
        dispatch(updateAuthError(error?.message ?? 'Unable to update current user.'));
      })
      .finally(() => dispatch(updateLoading(false)));
  };
}

export function refreshSession(): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(updateLoading(true));
    dispatch(updateStatus(AuthStatus.AUTHENTICATING));

    try {
      const sessionRefreshed = await authService.refreshSession();

      if (sessionRefreshed) {
        await dispatch(fetchUser());
      } else {
        await dispatch(notAuthenticated());
      }
    } catch (err) {
      dispatch(updateStatus(AuthStatus.UNAUTHENTICATED));
      dispatch(updateAuthError(err?.message ?? 'Unable to refresh user.'));
    } finally {
      dispatch(updateLoading(false));
    }
  };
}

export function signInUser(): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(updateLoading(true));
    dispatch(updateStatus(AuthStatus.AUTHENTICATING));

    try {
      const signedIn = await authService.signIn();

      if (signedIn) {
        await dispatch(fetchUser());
      } else {
        await dispatch(notAuthenticated());
      }
    } catch (err) {
      dispatch(updateStatus(AuthStatus.UNAUTHENTICATED));
      dispatch(updateAuthError(err?.message ?? 'Unable to sign in user.'));
    } finally {
      dispatch(updateLoading(false));
    }
  };
}

export function signOutUser(
  currentDeviceId?: string,
  includeBrowserSessionLogout: boolean = true,
): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(updateLoading(true));
    dispatch(updateStatus(AuthStatus.UNAUTHENTICATING));

    if (currentDeviceId) {
      await dispatch(deviceActions.deleteDevice(currentDeviceId));
    }

    return authService
      .signOut(includeBrowserSessionLogout)
      .then(() => {
        dispatch(setUser(null));
        dispatch(updateStatus(AuthStatus.UNAUTHENTICATED));
        window.location.reload();
      })
      .catch((error) => {
        dispatch(updateAuthError(error?.message ?? 'Unable to sign out user.'));
      })
      .finally(() => dispatch(updateLoading(false)));
  };
}

export const authActions = {
  updateLoading,
  setUser,
  setToken,
  updateAuthError,
  fetchUser,
  updateCurrentUser,
  refreshSession,
  signInUser,
  signOutUser,
};
