import { Feather } from '@expo/vector-icons';
import { Body, Button, Container, Content, List, ListItem, Text, View } from 'native-base';
import React from 'react';
import { StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import {
  PROGRAM_TEAM_EIGHT,
  PROGRAM_TEAM_FIVE,
  PROGRAM_TEAM_FOUR,
  PROGRAM_TEAM_ONE,
  PROGRAM_TEAM_SEVEN,
  PROGRAM_TEAM_SIX,
  PROGRAM_TEAM_THREE,
  PROGRAM_TEAM_TWO,
} from '../../constants/ProgramTeams';
import {
  SETTING_SLUG_PROGRAM_TEAM_EIGHT_EMAIL,
  SETTING_SLUG_PROGRAM_TEAM_EIGHT_NAME,
  SETTING_SLUG_PROGRAM_TEAM_EIGHT_TITLE,
  SETTING_SLUG_PROGRAM_TEAM_FIVE_EMAIL,
  SETTING_SLUG_PROGRAM_TEAM_FIVE_NAME,
  SETTING_SLUG_PROGRAM_TEAM_FIVE_TITLE,
  SETTING_SLUG_PROGRAM_TEAM_FOUR_EMAIL,
  SETTING_SLUG_PROGRAM_TEAM_FOUR_NAME,
  SETTING_SLUG_PROGRAM_TEAM_FOUR_TITLE,
  SETTING_SLUG_PROGRAM_TEAM_ONE_EMAIL,
  SETTING_SLUG_PROGRAM_TEAM_ONE_NAME,
  SETTING_SLUG_PROGRAM_TEAM_ONE_TITLE,
  SETTING_SLUG_PROGRAM_TEAM_SEVEN_EMAIL,
  SETTING_SLUG_PROGRAM_TEAM_SEVEN_NAME,
  SETTING_SLUG_PROGRAM_TEAM_SEVEN_TITLE,
  SETTING_SLUG_PROGRAM_TEAM_SIX_EMAIL,
  SETTING_SLUG_PROGRAM_TEAM_SIX_NAME,
  SETTING_SLUG_PROGRAM_TEAM_SIX_TITLE,
  SETTING_SLUG_PROGRAM_TEAM_THREE_EMAIL,
  SETTING_SLUG_PROGRAM_TEAM_THREE_NAME,
  SETTING_SLUG_PROGRAM_TEAM_THREE_TITLE,
  SETTING_SLUG_PROGRAM_TEAM_TWO_EMAIL,
  SETTING_SLUG_PROGRAM_TEAM_TWO_NAME,
  SETTING_SLUG_PROGRAM_TEAM_TWO_TITLE,
} from '../../constants/SettingSlugs';
import { Contact } from '../../models';
import { State } from '../../store/types';
import { getValueForSlug, openEmail } from '../../utils';
import { SplashScreen } from '../SplashScreen';

const styles = StyleSheet.create({
  content: {
    paddingBottom: 40,
  },
  contactTitle: {
    margin: 5,
  },
  h2: {
    marginTop: 20,
    fontSize: 16,
    textAlign: 'center',
  },
  header: {
    paddingTop: 25,
    paddingBottom: 25,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    flex: 1,
  },
  headerText: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginLeft: 20,
  },
  headerButton: {
    marginLeft: 11,
    marginRight: 11,
  },
});

interface ProgramTeamContact {
  contact: Contact;
  title: string;
}

export default function ContactsScreen({ navigation }) {
  const { currentCampaign, loading: campaignLoading } = useSelector((state: State) => state.campaign);

  const contactState = useSelector((state: State) => state.contact);
  const { loading: contactLoading, contacts } = contactState;

  const settingState = useSelector((state: State) => state.setting);
  const { loading: settingLoading, settings } = settingState;

  if (contactLoading || settingLoading) {
    return <SplashScreen />;
  }

  const generateProgramTeamContact = (programTeam: string): ProgramTeamContact | null => {
    let email: string, name: string, title: string;

    switch (programTeam) {
      case PROGRAM_TEAM_ONE:
        name = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_ONE_NAME);
        email = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_ONE_EMAIL);
        title = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_ONE_TITLE);
        break;
      case PROGRAM_TEAM_TWO:
        name = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_TWO_NAME);
        email = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_TWO_EMAIL);
        title = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_TWO_TITLE);
        break;
      case PROGRAM_TEAM_THREE:
        name = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_THREE_NAME);
        email = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_THREE_EMAIL);
        title = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_THREE_TITLE);
        break;
      case PROGRAM_TEAM_FOUR:
        name = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_FOUR_NAME);
        email = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_FOUR_EMAIL);
        title = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_FOUR_TITLE);
        break;
      case PROGRAM_TEAM_FIVE:
        name = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_FIVE_NAME);
        email = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_FIVE_EMAIL);
        title = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_FIVE_TITLE);
        break;
      case PROGRAM_TEAM_SIX:
        name = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_SIX_NAME);
        email = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_SIX_EMAIL);
        title = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_SIX_TITLE);
        break;
      case PROGRAM_TEAM_SEVEN:
        name = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_SEVEN_NAME);
        email = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_SEVEN_EMAIL);
        title = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_SEVEN_TITLE);
        break;
      case PROGRAM_TEAM_EIGHT:
        name = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_EIGHT_NAME);
        email = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_EIGHT_EMAIL);
        title = getValueForSlug(settings, SETTING_SLUG_PROGRAM_TEAM_EIGHT_TITLE);
        break;
      default:
        return null;
    }

    const contact: Contact = {
      name,
      email,
    };

    return {
      contact,
      title,
    };
  };

  const renderContactListing = (contact: Contact, title: string | null, index: number) => {
    return (
      <ListItem key={index}>
        <Body>
          <Text>{contact.name}</Text>
          {title ? <Text style={{ fontSize: 14, fontWeight: 'bold' }}>{title}</Text> : null}
          {contact.email ? (
            <Text note onPress={() => openEmail(contact.email)}>
              {contact.email}
            </Text>
          ) : null}
        </Body>
      </ListItem>
    );
  };

  const renderProgramTeamContact = (programTeam: string, index: number) => {
    const programTeamContact = generateProgramTeamContact(programTeam);
    if (programTeamContact) {
      return renderContactListing(programTeamContact.contact, programTeamContact.title, index);
    }
  };

  if (!currentCampaign || campaignLoading || settingLoading || contactLoading) {
    return <SplashScreen />;
  }

  return (
    <Container>
      <Content style={styles.content}>
        <View style={styles.header}>
          <View style={styles.headerButton}>
            <Button transparent onPress={() => navigation.navigate('Tabs')}>
              <Feather name="x" size={25} style={{ marginLeft: 10 }} />
            </Button>
          </View>
          <Text style={styles.headerText}>Contacts</Text>
        </View>
        {!currentCampaign.sfid ? (
          <Body>
            <Text style={styles.h2}>You have no contacts at this time.</Text>
          </Body>
        ) : (
          <>
            <List>
              {currentCampaign.schoolChampion
                ? renderContactListing(currentCampaign.schoolChampion, 'School Champion', 0)
                : null}
              {currentCampaign.coSchoolChampion
                ? renderContactListing(currentCampaign.coSchoolChampion, 'Co-School Champion', 1)
                : null}
              {currentCampaign.coSchoolChampion2
                ? renderContactListing(currentCampaign.coSchoolChampion2, 'Co-School Champion', 2)
                : null}
              {currentCampaign.leadVolunteer
                ? renderContactListing(currentCampaign.leadVolunteer, 'Lead Volunteer', 3)
                : null}
              {currentCampaign.coLeadVolunteer
                ? renderContactListing(currentCampaign.coLeadVolunteer, 'Co-Lead Volunteer', 4)
                : null}
              {currentCampaign.studentCeo ? renderContactListing(currentCampaign.studentCeo, 'Student CEO', 5) : null}
              {currentCampaign.coStudentCeo
                ? renderContactListing(currentCampaign.coStudentCeo, 'Co-Student CEO', 6)
                : null}
              {renderProgramTeamContact(currentCampaign.rtswsContact, 7)}
            </List>
            <List>
              {contacts
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((contact, index) => {
                  return renderContactListing(contact, null, index);
                })}
            </List>
          </>
        )}
      </Content>
    </Container>
  );
}
