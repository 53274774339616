import { Resource } from '../../models';

export const SET_RESOURCE = '@@resources/SET_RESOURCE';
export const SET_RESOURCES = '@@resources/SET_RESOURCES';
export const UPDATE_LOADING = '@@resources/UPDATE_LOADING';
export const UPDATE_ERROR = '@@resources/UPDATE_ERROR';

export interface ResourceState {
  currentResource: Resource | null;
  resources: Resource[];
  loading: boolean;
  error?: string;
}

export interface SetResourceAction {
  type: typeof SET_RESOURCE;
  currentResource: Resource;
}

export interface SetResourcesAction {
  type: typeof SET_RESOURCES;
  resources: Resource[];
}

export interface UpdateLoadingAction {
  type: typeof UPDATE_LOADING;
  newValue: boolean;
}

export interface UpdateErrorAction {
  type: typeof UPDATE_ERROR;
  error?: string;
}

export type ResourceActionTypes = SetResourceAction | SetResourcesAction | UpdateLoadingAction | UpdateErrorAction;
