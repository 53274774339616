import * as WebBrowser from 'expo-web-browser';
import moment from 'moment-timezone';
import { Body, Button, Card, CardItem, Container, Content, Left, Text } from 'native-base';
import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { stripHtml } from 'string-strip-html';
import CalendarLinks from '../../components/CalendarLinks';
import { RoleNames } from '../../constants/Roles';
import { VolunteerJob } from '../../models/ProgramEvent';
import { State } from '../../store/types';
import rootStyles from '../../styles';
import { SplashScreen } from '../SplashScreen';

const styles = StyleSheet.create({
  content: {
    paddingTop: 5,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 5,
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: 10,
  },
  subHeaderText: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  text: {
    fontSize: 14,
    marginTop: 10,
  },
  cardShadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.27,
    shadowRadius: 4.65,
    elevation: 10,
  },
});

export default function ProgramEventDetailScreen({ route }) {
  const currentUser = useSelector((state: State) => state.auth.currentUser);
  const currentCampaign = useSelector((state: State) => state.campaign.currentCampaign);
  const { programEvents } = useSelector((state: State) => state.programEvent);

  const { id } = route.params;

  const programEvent = useMemo(() => {
    return programEvents.find((event) => event.id === parseInt(id));
  }, [id, programEvents]);

  const programEventState = useSelector((state: State) => state.programEvent);
  const { loading: programEventLoading } = programEventState;

  const getDateFromString = (str: string) => {
    const timezone = currentCampaign?.timezone || 'UTC';
    return moment(str).tz(timezone).format('MMMM Do YYYY');
  };

  const getTimeFromString = (str: string) => {
    const timezone = currentCampaign?.timezone || 'UTC';
    return moment(str).tz(timezone).format('h:mm A z');
  };

  const getEndTimeFromString = (startDateTime: string, duration: number) => {
    const timezone = currentCampaign?.timezone || 'UTC';
    const startMoment = moment(startDateTime).tz(timezone);
    return startMoment.add(duration, 'hours').format('h:mm A z');
  };

  const volunteerJobLocation = (volunteerJob: VolunteerJob) => {
    if (volunteerJob && volunteerJob.locationInformation && volunteerJob.locationInformation.length > 0) {
      const locInfo = stripHtml(volunteerJob.locationInformation).result;
      return <Text style={styles.text}>{locInfo}</Text>;
    }
  };

  const _handlePressButtonAsync = async (url: string) => {
    await WebBrowser.openBrowserAsync(url);
  };

  if (programEventLoading || !programEvent) {
    return <SplashScreen />;
  }

  return (
    <Container>
      <Content padder>
        <Card style={styles.cardShadow}>
          <CardItem bordered>
            <Left>
              <Body>
                <Text style={[styles.headerText, rootStyles.accentBorder]}>{programEvent.volunteerJob.name}</Text>
                <Text style={styles.subHeaderText}>{programEvent.description}</Text>
                <Text style={styles.text}>{getDateFromString(programEvent.startDateTime)}</Text>
                <Text style={styles.text}>
                  {getTimeFromString(programEvent.startDateTime)} -{' '}
                  {getEndTimeFromString(programEvent.startDateTime, programEvent.duration)}
                </Text>
                <Text style={styles.text}>{volunteerJobLocation(programEvent.volunteerJob)}</Text>
                <CalendarLinks programEvent={programEvent} timezone={currentCampaign?.timezone} />
              </Body>
            </Left>
          </CardItem>
          {currentCampaign && currentUser?.role?.name === RoleNames.VOLUNTEER ? (
            <CardItem footer bordered>
              <Button onPress={() => _handlePressButtonAsync(currentCampaign.attendanceLink)}>
                <Text style={{ fontWeight: 'bold' }}>Open The Attendance Sheet</Text>
              </Button>
            </CardItem>
          ) : null}
        </Card>
      </Content>
    </Container>
  );
}
