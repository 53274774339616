import {
  AccountActionTypes,
  AccountState,
  SET_SCHOOL_ACCOUNTS,
  SET_SPONSOR_ACCOUNTS,
  UPDATE_ERROR,
  UPDATE_LOADING,
} from './types';

const initialState: AccountState = {
  schoolAccounts: [],
  sponsorAccounts: [],
  loading: false,
};

export default function accountReducer(state = initialState, action: AccountActionTypes): AccountState {
  switch (action.type) {
    case UPDATE_LOADING:
      return { ...state, loading: action.newValue };
    case SET_SCHOOL_ACCOUNTS:
      return { ...state, schoolAccounts: action.schoolAccounts };
    case SET_SPONSOR_ACCOUNTS:
      return { ...state, sponsorAccounts: action.sponsorAccounts };
    case UPDATE_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
}
