import { User } from '../models';
import { RegistrationData, VolunteerRegistrationData } from '../store/registration/types';
import BaseService from './BaseService';

export interface ReenrollmentData {
  schoolAccountSfid: string;
  referrer: string;
}

export interface VolunteerReenrollmentData {
  schoolAccountSfid: string;
  firstName: string;
  lastName: string;
  title: string;
  designation: string;
  company: string;
  hundredWomenInFinance: boolean;
  collegeAttended: string;
}

export class UserService extends BaseService {
  async getSelf() {
    return (await this.getJson<User>('/users/me')).data;
  }

  async updateSelf(user: Partial<User>) {
    return (await this.putJson<User>('/users/me', user)).data;
  }

  async submitRegistration(
    registrationData: Partial<RegistrationData> | Partial<VolunteerRegistrationData>,
    currentUser: User,
  ) {
    return (await this.postJson<User>(`/users/${currentUser.id}/register`, registrationData)).data;
  }

  async submitSpringEnrollment(currentUser: User) {
    return (await this.postJson<any>(`/users/${currentUser.id}/spring-enroll`, {})).data;
  }

  async submitReenrollment(currentUser: User, data: ReenrollmentData | VolunteerReenrollmentData) {
    return (await this.postJson<any>(`/users/${currentUser.id}/reenroll`, data)).data;
  }
}

export default new UserService();
