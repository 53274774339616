import { Student } from '../../models';

export const SET_STUDENT = '@@students/SET_STUDENT';
export const SET_STUDENTS = '@@students/SET_STUDENTS';
export const UPDATE_LOADING = '@@students/UPDATE_LOADING';
export const UPDATE_ERROR = '@@students/UPDATE_ERROR';

export interface StudentState {
  currentStudent: Student | null;
  students: Student[];
  loading: boolean;
  error?: string;
}

export interface SetStudentAction {
  type: typeof SET_STUDENT;
  currentStudent: Student;
}

export interface SetStudentsAction {
  type: typeof SET_STUDENTS;
  students: Student[];
}

export interface UpdateLoadingAction {
  type: typeof UPDATE_LOADING;
  newValue: boolean;
}

export interface UpdateErrorAction {
  type: typeof UPDATE_ERROR;
  error?: string;
}

export type StudentActionTypes = SetStudentAction | SetStudentsAction | UpdateLoadingAction | UpdateErrorAction;
