import {
  RegistrationActionTypes,
  RegistrationState,
  UPDATE_ERROR,
  UPDATE_LOADING,
  UPDATE_REGISTRATION,
  UPDATE_REGISTRATION_SUBMITTED,
  UPDATE_VOLUNTEER_REGISTRATION,
} from './types';

const initialState: RegistrationState = {
  registrationData: null,
  volunteerRegistrationData: null,
  loading: false,
  registrationSubmitted: false,
};

export default function registrationReducer(state = initialState, action: RegistrationActionTypes): RegistrationState {
  switch (action.type) {
    case UPDATE_LOADING:
      return { ...state, loading: action.newValue };
    case UPDATE_REGISTRATION:
      return { ...state, registrationData: action.newRegistrationData };
    case UPDATE_VOLUNTEER_REGISTRATION:
      return { ...state, volunteerRegistrationData: action.newRegistrationData };
    case UPDATE_ERROR:
      return { ...state, error: action.error };
    case UPDATE_REGISTRATION_SUBMITTED:
      return { ...state, registrationSubmitted: action.registrationSubmitted };
    default:
      return state;
  }
}
