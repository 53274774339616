import { Feather } from '@expo/vector-icons';
import moment from 'moment-timezone';
import { Body, Card, CardItem, Container, Content, Right, Text } from 'native-base';
import React from 'react';
import { ScrollView, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import { RefreshControl } from 'react-native-web-refresh-control';
import { useDispatch, useSelector } from 'react-redux';
import { getProgramEvents } from '../../store/programEvent/actions';
import { State } from '../../store/types';
import { colors } from '../../styles';
import { SplashScreen } from '../SplashScreen';

const styles = StyleSheet.create({
  content: {
    paddingTop: 5,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 5,
  },
  h2: {
    marginTop: 20,
    fontSize: 16,
  },
});

export default function ProgramEventsScreen({ navigation }) {
  const dispatch = useDispatch();
  const currentCampaign = useSelector((state: State) => state.campaign.currentCampaign);
  const programEventState = useSelector((state: State) => state.programEvent);
  const { loading: programEventLoading, programEvents } = programEventState;

  const [refreshing, setRefreshing] = React.useState(false);

  const getDateFromString = (str) => {
    const timezone = currentCampaign?.timezone || 'UTC';
    return moment(str).tz(timezone).format('MMMM Do YYYY');
  };

  const getTimeFromString = (str) => {
    const timezone = currentCampaign?.timezone || 'UTC';
    return moment(str).tz(timezone).format('h:mm A z');
  };

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    dispatch(getProgramEvents());
    setRefreshing(false);
  }, []);

  if (programEventLoading) {
    return <SplashScreen />;
  }

  const EventCard = ({ programEvent }) => {
    return (
      <TouchableWithoutFeedback
        onPress={() =>
          navigation.navigate('ProgramEventDetail', {
            id: programEvent.id,
          })
        }
      >
        <Card>
          <CardItem>
            <Body>
              <Text>{programEvent.volunteerJob.name}</Text>
              <Text note>{getDateFromString(programEvent.startDateTime)}</Text>
              <Text note>{getTimeFromString(programEvent.startDateTime)}</Text>
            </Body>
            <Right>
              <Feather name="chevron-right" size={25} style={{ color: colors.primary }} />
            </Right>
          </CardItem>
        </Card>
      </TouchableWithoutFeedback>
    );
  };

  const renderEventCard = (programEvent, index) => <EventCard programEvent={programEvent} key={index} />;

  return (
    <Container>
      <ScrollView refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
        <Content style={styles.content}>
          {programEvents.length ? (
            programEvents
              .sort((a, b) => (a.startDateTime > b.startDateTime ? 1 : -1))
              .map((programEvent, index) => {
                return renderEventCard(programEvent, index);
              })
          ) : (
            <Body>
              <Text style={styles.h2}>You have no events on the calendar.</Text>
            </Body>
          )}
        </Content>
      </ScrollView>
    </Container>
  );
}
