import { Contact } from '../../models';
import { contactService } from '../../services';
import { ThunkResult } from '../types';
import { ContactActionTypes, SET_CONTACT, SET_CONTACTS, UPDATE_ERROR, UPDATE_LOADING } from './types';

export function updateLoading(newValue: boolean): ContactActionTypes {
  return {
    type: UPDATE_LOADING,
    newValue,
  };
}

export function setContact(currentContact: Contact): ContactActionTypes {
  return {
    type: SET_CONTACT,
    currentContact,
  };
}

export function setContacts(contacts: Contact[]): ContactActionTypes {
  return {
    type: SET_CONTACTS,
    contacts,
  };
}

export function updateContactError(error?: string): ContactActionTypes {
  return {
    type: UPDATE_ERROR,
    error,
  };
}

export function getContacts(): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(updateLoading(true));
    return contactService
      .getContacts()
      .then((contacts) => {
        dispatch(setContacts(Array.isArray(contacts) ? contacts : []));
      })
      .catch((error) => {
        dispatch(updateContactError(error.message));
      })
      .finally(() => dispatch(updateLoading(false)));
  };
}

export const contactActions = {
  getContacts,
  setContact,
  setContacts,
  updateContactError,
  updateLoading,
};
