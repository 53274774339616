import { NavigationContainer } from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RoleNames } from '../constants';
import { useSessionStart } from '../hooks';
import { SplashScreen } from '../screens';
import { InvalidRoleScreen } from '../screens/InvalidRoleScreen';
import { authActions } from '../store/actions';
import { State } from '../store/types';
import { AppNavigation } from './AppNavigation';
import { AuthNavigation } from './AuthNavigation';
import { RegistrationNavigation } from './RegistrationNavigation';

const linking = {
  config: {
    screens: {
      Redirect: 'redirect',
      Auth: {
        screens: {
          Login: 'login',
        },
      },
      RegistrationSelect: 'registration/select',
      StudentRegistration: 'registration/student',
      ParentAcknowledgement: 'registration/parent',
      VolunteerRegistration: 'registration/volunteer',
      App: {
        screens: {
          AppInstallationAlert: 'install',
          Drawer: {
            screens: {
              Tabs: {
                screens: {
                  HomeStack: {
                    screens: {
                      Home: 'home',
                    },
                  },
                  ProgramEventsStack: {
                    screens: {
                      ProgramEvents: 'events',
                      ProgramEventDetail: 'events/:id',
                    },
                  },
                  ResourcesStack: {
                    screens: {
                      Resources: 'resources',
                    },
                  },
                  SijPortalStack: {
                    screens: {
                      InternshipJobs: 'internships-and-jobs',
                    },
                  },
                  NotificationsStack: {
                    screens: {
                      Notifications: 'notifications',
                      NotificationDetail: 'notifications/:id',
                    },
                  },
                },
              },
              SpringEnrollment: 'spring-enrollment',
              Reenrollment: 'reenrollment/student',
              VolunteerReenrollment: 'reenrollment/volunteer',
              UserProfile: 'profile',
              About: 'about',
              Contacts: 'contacts',
            },
          },
        },
      },
    },
  },
} as any;

export const Nav: React.FC = () => {
  const dispatch = useDispatch();
  const { currentUser, loading } = useSelector((state: State) => state.auth);

  // If this is a post logout, then close the window
  if (window.opener) {
    if (new URLSearchParams(window.location.search).has('logout')) {
      window.close();
    }
  }

  const { dataLoading } = useSessionStart({
    onStartSession: useCallback(() => {
      console.log('Session started');
    }, []),
    onInvalidRole: useCallback(() => {
      console.log('Invalid role');
    }, []),
    onNoSession: useCallback(() => {
      console.log('No session');
    }, []),
  });

  useEffect(() => {
    dispatch(authActions.refreshSession());
  }, []);

  const innerNav = useMemo(() => {
    if (currentUser) {
      if (currentUser.role && [RoleNames.STUDENT, RoleNames.VOLUNTEER].includes(currentUser.role.name)) {
        return <AppNavigation />;
      } else if (currentUser.role && currentUser.role.name === RoleNames.UNREGISTERED) {
        return <RegistrationNavigation />;
      } else {
        return <InvalidRoleScreen />;
      }
    }
    return <AuthNavigation />;
  }, [currentUser]);

  if (loading || dataLoading) {
    return <SplashScreen />;
  }

  return <NavigationContainer linking={linking}>{innerNav}</NavigationContainer>;
};
