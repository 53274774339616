import { Feather } from '@expo/vector-icons';
import { DrawerActions, useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { colors } from '../styles';

export default function HeaderRight() {
  const navigation = useNavigation();

  const onMenuPressed = () => navigation.dispatch(DrawerActions.toggleDrawer());

  return (
    <View
      style={{
        flex: 1,
        marginRight: 15,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <TouchableOpacity onPress={() => onMenuPressed()} testID="headerMenuBtn">
        <Feather name="menu" size={24} color={colors.primary} />
      </TouchableOpacity>
    </View>
  );
}
