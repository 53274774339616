import Checkbox from 'expo-checkbox';
import { useFormik } from 'formik';
import { Body, Button, Container, Content, Form, Input, Item, Label, ListItem, Text } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Dimensions, ScrollView, StyleSheet } from 'react-native';
import AwesomeAlert from 'react-native-awesome-alerts';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { setRegistration, submitRegistration, updateRegistrationError } from '../../store/registration/actions';
import { RegistrationData } from '../../store/registration/types';
import { State } from '../../store/types';
import { colors } from '../../styles';
import { SplashScreen } from '../SplashScreen';

const screenHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  content: {
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 40,
    maxHeight: screenHeight - 64,
  },
  form: {
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: 40,
  },
  headerText: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },

  releaseHeader: {
    fontSize: 14,
    textAlign: 'center',
  },

  releaseSectionTitle: {
    fontWeight: 'bold',
    fontSize: 12,
  },

  bodyText: {
    fontSize: 12,
    textAlign: 'justify',
  },
  errorText: {
    color: colors.red,
    fontSize: 12,
  },
  releaseText: {
    fontSize: 12,
    marginTop: 5,
    textAlign: 'justify',
  },
  requiredAsterisk: {
    fontSize: 12,
    top: -6,
    position: 'relative',
  },
});

const registrationSchema = Yup.object().shape({
  parentFirstName: Yup.string().required('Required'),
  parentLastName: Yup.string().required('Required'),
  parentEmail: Yup.string().email('Please provide a valid email address').required('Required'),
  parentMobilePhone: Yup.string().required('Required'),
  parentAcknowledged: Yup.boolean().required('Acceptance is required').oneOf([true], 'Acceptance is required'),
});

export default function ParentAcknowledgementScreen({ navigation }) {
  const dispatch = useDispatch();

  const currentUser = useSelector((state: State) => state.auth.currentUser);
  const registrationState = useSelector((state: State) => state.registration);
  const { registrationData, error: registrationError, loading: registrationLoading } = registrationState;

  const [alertIsVisible, setAlertIsVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      parentFirstName: registrationData?.parentFirstName || '',
      parentLastName: registrationData?.parentLastName || '',
      parentEmail: registrationData?.parentEmail || '',
      parentMobilePhone: registrationData?.parentMobilePhone || '',
      parentAcknowledged: registrationData?.parentAcknowledged || false,
    },
    validationSchema: registrationSchema,
    onSubmit: (values) => {
      saveAndSubmit(values);
    },
  });

  const saveAndSubmit = async (values) => {
    if (!currentUser) {
      return;
    }

    const newRegistrationData: Partial<RegistrationData> = {
      ...registrationData,
      parentFirstName: values.parentFirstName,
      parentLastName: values.parentLastName,
      parentEmail: values.parentEmail,
      parentMobilePhone: values.parentMobilePhone,
      parentAcknowledged: values.parentAcknowledged,
      parentAcknowledgementTimestamp: Date.now(),
    };

    dispatch(setRegistration(newRegistrationData));
    dispatch(submitRegistration(newRegistrationData, currentUser));
  };

  useEffect(() => {
    if (registrationError) {
      setAlertTitle('Please try again');
      setAlertMessage(registrationError);
      setAlertIsVisible(true);
      dispatch(updateRegistrationError(undefined));
    }
  }, [registrationError]);

  if (registrationLoading) {
    return <SplashScreen />;
  }

  return (
    <Container style={{ flex: 1 }}>
      <ScrollView>
        <Content style={styles.content}>
          <Body>
            <Text style={styles.headerText}>This section to be completed by a parent or legal guardian.</Text>
          </Body>
          <ListItem>
            <Body>
              <Text style={styles.releaseHeader}>STUDENT​ ​RELEASE - PARENT SIGNATURE</Text>
              <Text style={styles.releaseText}>
                In consideration for the financial education instruction, which may include field trips and mentoring
                (“Instruction”), to be provided by Rock The Street, Wall Street, a Tennessee non-profit organization,
                and its present and former affiliates, subsidiaries, parents, successors, predecessors, directors,
                officers, owners, board members, agents, assignees, estates, heirs, volunteers and employees (“RTSWS”),
                and for other good and valuable consideration, the receipt and sufficiency of which is hereby
                acknowledged, on behalf of the above-named Student, I __________________________, the Undersigned Parent
                or Legal Guardian, hereby acknowledge that, on behalf of myself, the Student and any person claiming
                through Student (collectively referred to sometimes as the “Releasing Parties”), I understand and
                expressly agree as follows:
              </Text>

              <Text style={styles.releaseText}>
                <Text style={styles.releaseSectionTitle}>1. Release: Financial Information​.</Text> The financial
                information will be discussed in the Instruction and Student will be participating in financial learning
                activities. In consideration of the opportunity for Student to receive the Instruction, I, the
                Undersigned Parent or Legal Guardian, on behalf of Student and any person claiming through Student,
                hereby releases RTSWS from any responsibility or liability for any harm, including without limitation,
                personal injury and damage to or loss of personal property (including financial loss), that Student
                and/or any of the Releasing Parties may incur as a result of Student’s receipt of, reliance on, and/or
                participation in the Instruction or any principles, teaching or advice learned therein, regardless of
                whether such harm is the result of RTSWS’ negligence.
              </Text>
              <Text style={styles.releaseText}>
                <Text style={styles.releaseSectionTitle}>2. Release: Field Trips​.</Text> RTSWS may organize field trips
                supervised by RTSWS staff as part of the Instruction. In consideration of the opportunity for Student to
                participate in any such field trips, I, the Undersigned Parent or Legal Guardian, on behalf of Student
                and any person claiming through Student, hereby releases RTSWS from any responsibility or liability for
                any harm, including without limitation, personal injury and damage to or loss of personal property
                (including financial loss), that Student and/or any of the Releasing Parties may incur as a result of
                Student’s participation in such field trips, regardless of whether such harm is a result of RTSWS’
                negligence, whether ordinary or gross.
              </Text>
              <Text style={styles.releaseText}>
                <Text style={styles.releaseSectionTitle}>3. Consent and Release: Mode of Instruction.</Text> RTSWS may
                provide the Instruction and/or any one or more organized field trips or mentoring opportunities it
                offers in person, virtually, utilizing technology capable of providing the Instruction and/or a virtual
                field trip or a combination of both. RTSWS will utilize best practices whenever it utilizes virtual
                Instruction, field trips and/or mentoring (“Virtual Learning Experience”) to ensure that the Student’s
                RTSWS Virtual Learning Experience is secure. I, the Undersigned Parent or Legal Guardian, understand
                that to promote RTSWS’s security measures, the Student must utilize current versions of any browser,
                application or platform which RTSWS utilizes, and I agree to ensure that Student downloads any such
                updates as they are issued. I understand that despite RTSWS’s best efforts, it is impossible for it to
                ensure that Student’s Virtual Learning Experience will be free from unsolicited information, including,
                without limitation, information that is offensive and inappropriate. Further, I understand that the
                Family Educational Rights and Privacy Act (FERPA) establishes certain rights for parents regarding the
                privacy of their child’s educational record and that video recordings/streams such as Zoom, Google
                Classroom, and other platforms that may be utilized for the Virtual Learning Experience may be
                considered education records under certain circumstances and may require parental consent if a minor is
                under the age of 18. I, the Undersigned Parent or Legal Guardian expressly consent to RTSWS’s use,
                release and provision of access to any of Student’s education records protected by FERPA to RTSWS
                Virtual Learning Experience participants. Additionally, I, the Undersigned Parent or Legal Guardian, on
                behalf of Student and any person claiming through Student hereby release RTSWS from any responsibility
                or liability for harm of any nature that Student and/or any of the Releasing Parties may incur as a
                result of Student’s participation in RTSWS’s Virtual Learning Experience, including, without limitation,
                the release of any of Student’s personal information or educational records.
              </Text>

              <Text style={styles.releaseText}>
                <Text style={styles.releaseSectionTitle}>4. Hold Harmless.</Text> I, the Undersigned Parent or Legal
                Guardian shall fully release RTSWS and hold RTSWS harmless from and against any and all judgments,
                settlement payments, fines, sanctions and all other costs, liabilities, obligations and expenses
                (including, but not limited to, attorneys’ fees and expenses) incurred by RTSWS in connection with any
                action, suit, claim, allegation or proceeding (whether prosecution, defense, or otherwise) that is
                brought, made or threatened by any individual or entity in connection with any dispute or potential
                dispute arising directly or indirectly from (i) the Instruction provided by RTSWS to Student, (ii) any
                instructional materials or manuals provided to Student by RTSWS in connection with the Instruction,
                (iii) the Student’s implementation of principles and teachings learned during the Instruction, or (iv)
                Student’s use of technology.
              </Text>
              <Text style={styles.releaseText}>
                <Text style={styles.releaseSectionTitle}>5. Release:​ ​ ​LinkedIn​ ​Profile.​</Text> RTSWS requires each
                participating Student age of 16 or older to set up a LinkedIn profile as part of the educational
                program. The RTSWS LinkedIn group is a private group for the participants in the RTSWS program. The
                required information for this LinkedIn profile is an email address and name. In consideration of the
                opportunity for Student to participate in this education program, the Undersigned Parent or Legal
                Guardian, on behalf of Student and person claiming through Student, hereby releases RTSWS from any
                responsibility or liability for any harm, including without limitation, personal injury and damage to or
                loss of personal property (including financial loss), that Student or any of the Releasing Parties may
                incur as a result of Student obtaining a LinkedIn account.
              </Text>

              <Text style={styles.releaseText}>
                <Text style={styles.releaseSectionTitle}>6. Release:​ ​Mentoring​ ​Program.​</Text> Student may elect to
                participate in an additional program that pairs the Student with a Mentor. This mentoring program is a
                voluntary program that provides the Student with a female mentor who is a financial professional with
                the intent of providing career guidance for those Students interested in business careers. In
                consideration of the opportunity for Student to participate in any such mentoring program, the
                Undersigned Parent or Legal Guardian, on behalf of Student and any person claiming through Student,
                hereby releases RTSWS from any responsibility or liability for any harm, including without limitation,
                personal injury and damage to or loss of personal property (including financial loss), that Student or
                any of the Releasing Parties may incur as a result of Student’s participation in such mentoring
                programs, regardless of whether such harm is a result of RTSWS’ negligence, whether ordinary or gross.
              </Text>

              <Text style={styles.releaseText}>
                <Text style={styles.releaseSectionTitle}>7. Use of Name, Likeness or Image​.</Text> The Undersigned
                Parent or Legal Guardian acknowledges that Student may be photographed or videotaped during Instruction.
                The Undersigned Parent or Legal Guardian, on behalf of Student, hereby releases all rights to any such
                photographs or video footage and hereby agrees that RTSWS, its sponsors and third-party media may,
                without any compensation to, or additional authorization from Student and/or the Undersigned Parent or
                Legal Guardian, use Student’s name, likeness or image in connection with RTSWS’ organization and
                sponsors support of RTSWS or promotion thereof.
              </Text>

              <Text style={styles.releaseHeader}>PARENTAL ACKNOWLEDGEMENT </Text>
              <Text style={styles.releaseText}>
                The Undersigned (each individually “Parent” and collectively “Parents”) hereby warrants and represents
                that he/she is the biological parent and/or legal guardian of _________________________ (“Minor”) and
                has the full right, power, and authority to execute this acknowledgement and fulfill all of Parent’s
                obligations contained herein. The performance by Minor is hereby guaranteed by Parent, who agrees on
                his/her own behalf and on Minor’s behalf, to the above Release and to the participation of the Minor in
                the Instruction, and shall indemnify and hold RTSWS harmless from any loss, cost or expense in the event
                of or attempt at disaffirmance of the above Release by the Minor. In addition, Parent hereby releases,
                waives, and discharges RTSWS from any and all liability, claims, demands, actions and causes of action
                whatsoever arising out of or in relation to any loss, damage, or injury (including death) that may be
                sustained by the Minor or to any property owned by the Parent or by the Minor, however caused, in
                relation to the Instruction. The Undersigned Parent and/or Legal Guardian has read, understands, agrees
                and accepts full responsibility with the statements recited above and agrees to review the above
                statements and responsibilities with Student.
              </Text>
            </Body>
          </ListItem>
          <Form style={styles.form}>
            <ListItem>
              <Body>
                <Text style={styles.bodyText}>
                  I certify I am the biological parent and/or legal guardian of this registered user and have the full
                  right, power and authority to execute this acknowledgement and fulfill all of Parent’s obligations
                  contained herein. The performance by Minor is hereby guaranteed by Parent, who agrees on his/her own
                  behalf and on Minor’s behalf, to the above Release and to the participation of the Minor in the
                  Instruction, and shall indemnify and hold RTSWS harmless from any loss, cost or expense in the event
                  of or attempt at disaffirmance of the above Release by the Minor. In addition, Parent hereby releases,
                  waives and discharges RTSWS from any and all liability, claims, demands, actions and causes of action
                  whatsoever arising out of or in relation to any loss, damage, or injury (including death) that may be
                  sustained by the Minor or to any property owned by the Parent or by the Minor, however caused, in
                  relation to the Instruction.
                </Text>
              </Body>
            </ListItem>
            <Text style={styles.errorText}>
              {formik.touched.parentAcknowledged && formik.errors.parentAcknowledged
                ? formik.errors.parentAcknowledged
                : null}
            </Text>
            <ListItem>
              <Checkbox
                value={formik.values.parentAcknowledged}
                onValueChange={(value) => formik.setFieldValue('parentAcknowledged', value)}
                color={formik.values.parentAcknowledged ? colors.primary : undefined}
              />
              <Body>
                <Text>I accept the above statement.</Text>
              </Body>
            </ListItem>
            <Body style={{ width: '100%' }}>
              <Text style={{ fontSize: 12, alignSelf: 'flex-end' }}>* Required</Text>
            </Body>
            <Item stackedLabel error={formik.touched.parentFirstName && formik.errors.parentFirstName ? true : false}>
              <Label>
                Parent First Name<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.parentFirstName && formik.errors.parentFirstName ? formik.errors.parentFirstName : null}
              </Text>
              <Input
                placeholder=""
                onChangeText={formik.handleChange('parentFirstName')}
                autoCapitalize="none"
                returnKeyType="next"
                value={formik.values.parentFirstName}
              />
            </Item>
            <Item stackedLabel error={formik.touched.parentLastName && formik.errors.parentLastName ? true : false}>
              <Label>
                Parent Last Name<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.parentLastName && formik.errors.parentLastName ? formik.errors.parentLastName : null}
              </Text>
              <Input
                placeholder=""
                onChangeText={formik.handleChange('parentLastName')}
                autoCapitalize="none"
                returnKeyType="next"
                value={formik.values.parentLastName}
              />
            </Item>
            <Item
              stackedLabel
              error={formik.touched.parentMobilePhone && formik.errors.parentMobilePhone ? true : false}
            >
              <Label>
                Parent Mobile Phone<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.parentMobilePhone && formik.errors.parentMobilePhone
                  ? formik.errors.parentMobilePhone
                  : null}
              </Text>
              <Input
                placeholder=""
                onChangeText={formik.handleChange('parentMobilePhone')}
                autoCapitalize="none"
                returnKeyType="next"
                value={formik.values.parentMobilePhone}
              />
            </Item>

            <Item stackedLabel error={formik.touched.parentEmail && formik.errors.parentEmail ? true : false}>
              <Label>
                Parent Email Address<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.parentEmail && formik.errors.parentEmail ? formik.errors.parentEmail : null}
              </Text>
              <Input
                placeholder=""
                onChangeText={formik.handleChange('parentEmail')}
                autoCapitalize="none"
                returnKeyType="next"
                value={formik.values.parentEmail}
              />
            </Item>
          </Form>
          <Button onPress={() => formik.handleSubmit()} full>
            <Text>COMPLETE REGISTRATION</Text>
          </Button>
        </Content>
        <AwesomeAlert
          show={alertIsVisible}
          showProgress={false}
          title={alertTitle}
          message={alertMessage}
          closeOnHardwareBackPress={false}
          showConfirmButton
          confirmText="OK"
          confirmButtonColor="#5cb85c"
          alertContainerStyle={{ height: 'auto', minHeight: 700, position: 'absolute', left: 0 }}
          onConfirmPressed={() => {
            setAlertIsVisible(false);
          }}
        />
      </ScrollView>
    </Container>
  );
}
