import { Feather } from '@expo/vector-icons';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { Image, View } from 'react-native';
import HeaderRight from '../components/HeaderRight';
import {
  ParentAcknowledgementScreen,
  RegistrationSelectScreen,
  StudentRegistrationScreen,
  VolunteerRegistrationScreen,
} from '../screens';

const defaultScreenOptions = (navigation: any, title: string, otherOptions: any = {}) => {
  const commonOptions = {
    headerBackTitle: 'Back',
    headerRight: () => <HeaderRight />,
    title,
    headerTintColor: '#044959',
  };

  const state = navigation.getState();

  if (state.index === 0) {
    return {
      ...commonOptions,
      headerLeft: () => (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', width: 40, height: 40 }}>
          <Image
            // eslint-disable-next-line global-require
            source={require('../../assets/images/cropped-rtsws-widget-navy-2021-192x192.jpeg')}
            resizeMode="contain"
            style={{ width: 48, height: 48, left: 10 }}
          />
        </View>
      ),
      ...otherOptions,
    };
  }

  return { ...commonOptions, ...otherOptions };
};

const RegistrationStack = createStackNavigator();

export const RegistrationNavigation = () => {
  return (
    <RegistrationStack.Navigator initialRouteName="RegistrationSelect" screenOptions={{ gestureEnabled: true }}>
      <RegistrationStack.Screen
        name="RegistrationSelect"
        component={RegistrationSelectScreen}
        options={
          ({ navigation }) =>
            defaultScreenOptions(navigation, 'Registration', {
              headerRight: null,
              headerBackImage: () => <Feather name="x" size={25} style={{ left: 5 }} testID="sampleDetailsCloseBtn" />,
              headerBackTitleVisible: false,
              headerStyle: { borderBottomWidth: 0 },
              animationEnabled: true,
              guestureEnable: true,
            })
          // eslint-disable-next-line react/jsx-curly-newline
        }
      />
      <RegistrationStack.Screen
        name="StudentRegistration"
        component={StudentRegistrationScreen}
        options={
          ({ navigation }) =>
            defaultScreenOptions(navigation, 'Student Registration', {
              headerRight: null,
              headerBackTitleVisible: false,
              headerStyle: { borderBottomWidth: 0 },
              animationEnabled: true,
              guestureEnable: true,
            })
          // eslint-disable-next-line react/jsx-curly-newline
        }
      />
      <RegistrationStack.Screen
        name="ParentAcknowledgement"
        component={ParentAcknowledgementScreen}
        options={
          ({ navigation }) =>
            defaultScreenOptions(navigation, 'Parent Acknowledgement', {
              headerRight: null,
              headerBackTitleVisible: false,
              headerStyle: { borderBottomWidth: 0 },
              animationEnabled: true,
              guestureEnable: true,
            })
          // eslint-disable-next-line react/jsx-curly-newline
        }
      />

      <RegistrationStack.Screen
        name="VolunteerRegistration"
        component={VolunteerRegistrationScreen}
        options={
          ({ navigation }) =>
            defaultScreenOptions(navigation, 'Volunteer Registration', {
              headerRight: null,
              headerBackTitleVisible: false,
              headerStyle: { borderBottomWidth: 0 },
              animationEnabled: true,
              guestureEnable: true,
            })
          // eslint-disable-next-line react/jsx-curly-newline
        }
      />
    </RegistrationStack.Navigator>
  );
};
