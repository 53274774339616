import { useFormik } from 'formik';
import Moment from 'moment';
import { Body, Button, Container, Content, Form, Input, Item, Label, Picker, Text, View } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Dimensions, ScrollView, StyleSheet } from 'react-native';
import AwesomeAlert from 'react-native-awesome-alerts';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { states } from '../../constants/FormOptions';
import {
  RegistrationTypes,
  REGISTRATION_OTHER,
  REGISTRATION_RACE_AMERICAN_INDIAN,
  REGISTRATION_RACE_ASIAN,
  REGISTRATION_RACE_BLACK,
  REGISTRATION_RACE_HISPANIC,
  REGISTRATION_RACE_NATIVE_HAWAIIAN,
  REGISTRATION_RACE_NO_DISCLOSURE,
  REGISTRATION_RACE_TWO_OR_MORE,
  REGISTRATION_RACE_WHITE,
} from '../../constants/Registration';
import { updateAccountError } from '../../store/account/actions';
import { setRegistration, updateRegistrationError } from '../../store/registration/actions';
import { RegistrationData } from '../../store/registration/types';
import { State } from '../../store/types';
import { colors } from '../../styles';
import { SplashScreen } from '../SplashScreen';

/**
 * Options limited to the picklist values available in Salesforce.
 */
export const referrerOptions = [
  // {value:"Invited by a friend", label:"Invited by a friend"},
  // {value:"Club Fair", label:"Club Fair"},
  // {value:"School email", label:"School Email"},
  { value: 'Invited by Teacher', label: 'Invited by Teacher' },
  { value: 'Poster in School', label: 'Poster in School' },
  { value: 'PA announcement', label: 'PA Announcement' },
  { value: 'Invited by a Guidance Counselor', label: 'Invited by a Guidance Counselor' },
  { value: 'School Newsletter', label: 'School Newsletter' },
  { value: 'School website', label: 'School Website' },
  // {value:"School Parent email", label:"School Parent Email"},
  // {value:"Parent", label:"Parent"},
  { value: 'Social Media (Facebook/Instagram/Twitter)', label: 'Social Media (Facebook/Instagram/Twitter)' },
  { value: 'Google Search', label: 'Google Search' },
];

const screenHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  content: {
    flex: 1,
    paddingTop: 5,
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,
    maxHeight: screenHeight - 64,
  },
  form: {
    marginBottom: 40,
  },
  headerText: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  errorText: {
    color: colors.red,
    fontSize: 12,
  },
  requiredAsterisk: {
    fontSize: 12,
    top: -6,
    position: 'relative',
  },
});

const registrationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  middleInitial: Yup.string(),
  lastName: Yup.string().required('Required'),
  alternateEmail: Yup.string().email('Please provide a valid email address'),
  schoolAccountSfid: Yup.string().required('Please select a school'),
  mobilePhone: Yup.string().required('Required'),
  addressLine1: Yup.string().required('Required'),
  addressLine2: Yup.string(),
  addressCity: Yup.string().required('Required'),
  addressState: Yup.string().required('Required'),
  addressStateOther: Yup.string(),
  addressCountry: Yup.string().required('Required'),
  addressZip: Yup.string().required('Required'),
  dateOfBirth: Yup.date().required('Required'),
  graduationYear: Yup.string().required('Required'),
  genderIdentification: Yup.string().required('Required'),
  race: Yup.string().required('Required'),
  referrer: Yup.string().required('Required'),
});

export default function StudentRegistrationScreen({ navigation }) {
  const dispatch = useDispatch();

  const graduationYearOptionsCount = 5;

  const raceOptions = [
    { name: REGISTRATION_RACE_AMERICAN_INDIAN, value: REGISTRATION_RACE_AMERICAN_INDIAN },
    { name: REGISTRATION_RACE_ASIAN, value: REGISTRATION_RACE_ASIAN },
    { name: REGISTRATION_RACE_BLACK, value: REGISTRATION_RACE_BLACK },
    { name: REGISTRATION_RACE_HISPANIC, value: REGISTRATION_RACE_HISPANIC },
    { name: REGISTRATION_RACE_NATIVE_HAWAIIAN, value: REGISTRATION_RACE_NATIVE_HAWAIIAN },
    { name: REGISTRATION_RACE_WHITE, value: REGISTRATION_RACE_WHITE },
    { name: REGISTRATION_RACE_TWO_OR_MORE, value: REGISTRATION_RACE_TWO_OR_MORE },
    { name: REGISTRATION_RACE_NO_DISCLOSURE, value: REGISTRATION_RACE_NO_DISCLOSURE },
  ];

  const currentUser = useSelector((state: State) => state.auth.currentUser);
  const registrationState = useSelector((state: State) => state.registration);
  const { registrationData, error: registrationError, loading: registrationLoading } = registrationState;

  const accountState = useSelector((state: State) => state.account);
  const { schoolAccounts, error: accountError, loading: accountLoading } = accountState;

  const [alertIsVisible, setAlertIsVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      schoolAccountSfid: registrationData?.schoolAccountSfid || '',
      firstName: registrationData?.firstName || currentUser?.firstName || '',
      lastName: registrationData?.lastName || currentUser?.lastName || '',
      middleInitial: registrationData?.middleInitial || '',
      alternateEmail: registrationData?.alternateEmail || '',
      mobilePhone: registrationData?.mobilePhone || '',
      addressLine1: registrationData?.addressLine1 || '',
      addressLine2: registrationData?.addressLine2 || '',
      addressCity: registrationData?.addressCity || '',
      addressState: registrationData?.addressState || '',
      addressStateOther: registrationData?.addressStateOther || '',
      addressCountry: registrationData?.addressCountry || 'USA',
      addressZip: registrationData?.addressZip || '',
      dateOfBirth: '',
      birthDay: '',
      birthMonth: '',
      birthYear: '',
      graduationYear: registrationData?.graduationYear || '',
      genderIdentification: registrationData?.genderIdentification || '',
      race: registrationData?.race || '',
      referrer: registrationData?.referrer || '',
    },
    validationSchema: registrationSchema,
    onSubmit: (values) => {
      saveAndContinue(values);
    },
  });

  const saveAndContinue = (values) => {
    const addressState = values.addressState === 'other' ? values.addressStateOther : values.addressState;
    const newRegistrationData: Partial<RegistrationData> = {
      ...registrationData,
      type: RegistrationTypes.STUDENT,
      email: currentUser?.email,
      firstName: values.firstName,
      middleInitial: values.middleInitial,
      lastName: values.lastName,
      alternateEmail: values.alternateEmail,
      dateOfBirth: values.dateOfBirth,
      schoolAccountSfid: values.schoolAccountSfid,
      mobilePhone: values.mobilePhone,
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      addressCity: values.addressCity,
      addressState: addressState,
      addressCountry: values.addressCountry,
      addressZip: values.addressZip,
      graduationYear: values.graduationYear,
      genderIdentification: values.genderIdentification,
      race: values.race,
      referrer: values.referrer,
    };

    dispatch(setRegistration(newRegistrationData));
    navigation.navigate('ParentAcknowledgement');
  };

  const schoolPickerItems = schoolAccounts
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((account) => {
      return <Picker.Item label={account.name} value={account.sfid} key={account.id} />;
    });

  const graduationYearOptions = (numOptions) => {
    const thisYear = parseInt(Moment().format('Y'));
    const years: number[] = [];
    for (let i = 0; i <= numOptions; i++) {
      years.push(thisYear + i);
    }
    return years;
  };

  const graduationYearPickerItems = graduationYearOptions(graduationYearOptionsCount).map((option, index) => {
    return <Picker.Item label={option.toString()} value={option} key={index} />;
  });

  const birthYearOptions = () => {
    const thirteenYearsAgo = parseInt(Moment().subtract(13, 'years').format('Y'));
    const years: number[] = [];
    for (let i = 0; i <= 10; i++) {
      years.push(thirteenYearsAgo - i);
    }
    return years;
  };

  const birthYearPickerItems = birthYearOptions().map((option, index) => {
    return <Picker.Item label={option.toString()} value={option} key={index} />;
  });

  const racePickerItems = raceOptions.map((option, index) => {
    return <Picker.Item label={option.name} value={option.value} key={index} />;
  });

  useEffect(() => {
    let dateOfBirth = '';
    if (formik.values.birthDay && formik.values.birthMonth && formik.values.birthYear) {
      dateOfBirth = `${formik.values.birthYear}-${formik.values.birthMonth}-${formik.values.birthDay}`;
    }
    formik.setFieldValue('dateOfBirth', dateOfBirth);
  }, [formik.values.birthDay, formik.values.birthMonth, formik.values.birthYear]);

  useEffect(() => {
    if (accountError) {
      setAlertTitle('Error');
      setAlertMessage(accountError);
      setAlertIsVisible(true);
      dispatch(updateAccountError(undefined));
    }

    if (registrationError) {
      setAlertTitle('Please try again');
      setAlertMessage(registrationError);
      setAlertIsVisible(true);
      dispatch(updateRegistrationError(undefined));
    }
  }, [accountError, registrationError]);

  if (registrationLoading || accountLoading) {
    return <SplashScreen />;
  }

  return (
    <Container style={{ flex: 1 }}>
      <ScrollView>
        <Content style={styles.content}>
          <Body>
            <Text style={styles.headerText}>Please fill out the form below to complete your registration.</Text>
          </Body>
          <Body style={{ width: '100%' }}>
            <Text style={{ fontSize: 12, alignSelf: 'flex-end' }}>* Required</Text>
          </Body>
          <Form style={{ marginBottom: 20 }}>
            <Item
              stackedLabel
              error={formik.touched.schoolAccountSfid && formik.errors.schoolAccountSfid ? true : false}
            >
              <Label>
                School<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.schoolAccountSfid && formik.errors.schoolAccountSfid
                  ? formik.errors.schoolAccountSfid
                  : null}
              </Text>
              <Picker
                mode="dropdown"
                style={{ width: '100%' }}
                selectedValue={formik.values.schoolAccountSfid}
                onValueChange={formik.handleChange('schoolAccountSfid')}
              >
                <Picker.Item label="" value="" />
                {schoolPickerItems}
                <Picker.Item label="Other" value={REGISTRATION_OTHER} />
              </Picker>
            </Item>
            <Item stackedLabel error={formik.touched.firstName && formik.errors.firstName ? true : false}>
              <Label>
                First Name<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : null}
              </Text>
              <Input
                placeholder=""
                onChangeText={formik.handleChange('firstName')}
                autoCapitalize="none"
                returnKeyType="next"
                value={formik.values.firstName}
              />
            </Item>
            <Item stackedLabel error={formik.touched.middleInitial && formik.errors.middleInitial ? true : false}>
              <Label>
                Middle Initial<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.middleInitial && formik.errors.middleInitial ? formik.errors.middleInitial : null}
              </Text>
              <Input
                placeholder=""
                onChangeText={formik.handleChange('middleInitial')}
                autoCapitalize="none"
                returnKeyType="next"
                maxLength={1}
                value={formik.values.middleInitial}
              />
            </Item>
            <Item stackedLabel error={formik.touched.lastName && formik.errors.lastName ? true : false}>
              <Label>
                Last Name<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : null}
              </Text>
              <Input
                placeholder=""
                onChangeText={formik.handleChange('lastName')}
                autoCapitalize="none"
                returnKeyType="next"
                value={formik.values.lastName}
              />
            </Item>
            <Item stackedLabel error={formik.touched.mobilePhone && formik.errors.mobilePhone ? true : false}>
              <Label>
                Student Mobile Phone<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.mobilePhone && formik.errors.mobilePhone ? formik.errors.mobilePhone : null}
              </Text>
              <Input
                placeholder=""
                onChangeText={formik.handleChange('mobilePhone')}
                autoCapitalize="none"
                returnKeyType="next"
                value={formik.values.mobilePhone}
              />
            </Item>

            <Item stackedLabel error={formik.touched.alternateEmail && formik.errors.alternateEmail ? true : false}>
              <Label>Personal Email Address</Label>
              <Text style={styles.errorText}>
                {formik.touched.alternateEmail && formik.errors.alternateEmail ? formik.errors.alternateEmail : null}
              </Text>
              <Input
                placeholder=""
                onChangeText={formik.handleChange('alternateEmail')}
                autoCapitalize="none"
                returnKeyType="next"
                value={formik.values.alternateEmail}
              />
            </Item>
            <Item stackedLabel error={formik.touched.addressLine1 && formik.errors.addressLine1 ? true : false}>
              <Label>
                Address Line 1<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.addressLine1 && formik.errors.addressLine1 ? formik.errors.addressLine1 : null}
              </Text>
              <Input
                placeholder=""
                onChangeText={formik.handleChange('addressLine1')}
                autoCapitalize="none"
                returnKeyType="next"
                value={formik.values.addressLine1}
              />
            </Item>
            <Item stackedLabel error={formik.touched.addressLine2 && formik.errors.addressLine2 ? true : false}>
              <Label>Address Line 2</Label>
              <Text style={styles.errorText}>
                {formik.touched.addressLine2 && formik.errors.addressLine2 ? formik.errors.addressLine2 : null}
              </Text>
              <Input
                placeholder=""
                onChangeText={formik.handleChange('addressLine2')}
                autoCapitalize="none"
                returnKeyType="next"
                value={formik.values.addressLine2}
              />
            </Item>
            <Item stackedLabel error={formik.touched.addressCity && formik.errors.addressCity ? true : false}>
              <Label>
                City<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.addressCity && formik.errors.addressCity ? formik.errors.addressCity : null}
              </Text>
              <Input
                placeholder=""
                onChangeText={formik.handleChange('addressCity')}
                autoCapitalize="none"
                returnKeyType="next"
                value={formik.values.addressCity}
              />
            </Item>
            <Item stackedLabel error={formik.touched.addressState && formik.errors.addressState ? true : false}>
              <Label>
                State<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.addressState && formik.errors.addressState ? formik.errors.addressState : null}
              </Text>

              <Picker
                mode="dropdown"
                style={{ width: '100%', flex: 2, marginRight: 2 }}
                selectedValue={formik.values.addressState}
                onValueChange={formik.handleChange('addressState')}
              >
                <Picker.Item label="" value="" key={states.length + 1} />
                {states.map((state, index) => (
                  <Picker.Item label={state.label} value={state.value} key={index} />
                ))}
              </Picker>
            </Item>
            {formik.values.addressState === 'other' ? (
              <Item
                stackedLabel
                error={formik.touched.addressStateOther && formik.errors.addressStateOther ? true : false}
              >
                <Label>
                  State/Province/Region<Text style={styles.requiredAsterisk}>*</Text>
                </Label>
                <Text style={styles.errorText}>
                  {formik.touched.addressStateOther && formik.errors.addressStateOther
                    ? formik.errors.addressStateOther
                    : null}
                </Text>
                <Input
                  placeholder=""
                  onChangeText={formik.handleChange('addressStateOther')}
                  autoCapitalize="none"
                  returnKeyType="next"
                  value={formik.values.addressStateOther}
                />
              </Item>
            ) : null}

            <Item stackedLabel error={formik.touched.addressZip && formik.errors.addressZip ? true : false}>
              <Label>
                Zip Code<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.addressZip && formik.errors.addressZip ? formik.errors.addressZip : null}
              </Text>
              <Input
                placeholder=""
                onChangeText={formik.handleChange('addressZip')}
                autoCapitalize="none"
                returnKeyType="next"
                value={formik.values.addressZip}
              />
            </Item>
            <Item stackedLabel error={formik.touched.addressCountry && formik.errors.addressCountry ? true : false}>
              <Label>
                Country<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.addressCountry && formik.errors.addressCountry ? formik.errors.addressCountry : null}
              </Text>

              <Picker
                mode="dropdown"
                style={{ width: '100%', flex: 2, marginRight: 2 }}
                selectedValue={formik.values.addressCountry}
                onValueChange={formik.handleChange('addressCountry')}
              >
                <Picker.Item label="Canada" value="Canada" key="1" />
                <Picker.Item label="United Kingdom" value="UK" key="2" />
                <Picker.Item label="United States" value="USA" key="3" />
              </Picker>
            </Item>
            <Item stackedLabel error={formik.touched.dateOfBirth && formik.errors.dateOfBirth ? true : false}>
              <Label>
                Date of Birth<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? formik.errors.dateOfBirth : null}
              </Text>
              <View style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 41 }}>
                <Picker
                  mode="dropdown"
                  style={{ width: '100%', flex: 2, marginRight: 2 }}
                  selectedValue={formik.values.birthMonth}
                  onValueChange={formik.handleChange('birthMonth')}
                >
                  <Picker.Item label="" value="" />
                  <Picker.Item label="January" value="01" key="1" />
                  <Picker.Item label="February" value="02" key="2" />
                  <Picker.Item label="March" value="03" key="3" />
                  <Picker.Item label="April" value="04" key="4" />
                  <Picker.Item label="May" value="05" key="5" />
                  <Picker.Item label="June" value="06" key="6" />
                  <Picker.Item label="July" value="07" key="7" />
                  <Picker.Item label="August" value="08" key="8" />
                  <Picker.Item label="September" value="09" key="9" />
                  <Picker.Item label="October" value="10" key="10" />
                  <Picker.Item label="November" value="11" key="11" />
                  <Picker.Item label="December" value="12" key="12" />
                </Picker>
                <Picker
                  mode="dropdown"
                  style={{ width: '100%', flex: 1, marginRight: 2 }}
                  selectedValue={formik.values.birthDay}
                  onValueChange={formik.handleChange('birthDay')}
                >
                  <Picker.Item label="" value="" />
                  {[...Array(31).keys()].map((key, index) => {
                    const day = key + 1;
                    const val = day.toString().padStart(2, '0');
                    return <Picker.Item label={day.toString()} value={val} key={index} />;
                  })}
                </Picker>
                <Picker
                  mode="dropdown"
                  style={{ width: '100%', flex: 1 }}
                  selectedValue={formik.values.birthYear}
                  onValueChange={formik.handleChange('birthYear')}
                >
                  <Picker.Item label="" value="" />
                  {birthYearPickerItems}
                </Picker>
              </View>
            </Item>
            <Item stackedLabel error={formik.touched.graduationYear && formik.errors.graduationYear ? true : false}>
              <Label>
                Graduation Year<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.graduationYear && formik.errors.graduationYear ? formik.errors.graduationYear : null}
              </Text>
              <Picker
                mode="dropdown"
                style={{ width: '100%' }}
                selectedValue={formik.values.graduationYear}
                onValueChange={formik.handleChange('graduationYear')}
              >
                <Picker.Item label="" value="" />
                {graduationYearPickerItems}
              </Picker>
            </Item>
            <Item stackedLabel error={formik.touched.race && formik.errors.race ? true : false}>
              <Label>
                Race<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.race && formik.errors.race ? formik.errors.race : null}
              </Text>
              <Picker
                mode="dropdown"
                style={{ width: '100%' }}
                selectedValue={formik.values.race}
                onValueChange={formik.handleChange('race')}
              >
                <Picker.Item label="" value="" />
                {racePickerItems}
              </Picker>
            </Item>
            <Item
              stackedLabel
              error={formik.touched.genderIdentification && formik.errors.genderIdentification ? true : false}
            >
              <Label>
                Gender<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.genderIdentification && formik.errors.genderIdentification
                  ? formik.errors.genderIdentification
                  : null}
              </Text>
              <Picker
                mode="dropdown"
                style={{ width: '100%' }}
                selectedValue={formik.values.genderIdentification}
                onValueChange={formik.handleChange('genderIdentification')}
              >
                <Picker.Item label="" value="" />
                <Picker.Item label="I identify as female" value="i_identify_as_female" />
                <Picker.Item label="I identify as non-binary" value="i_identify_as_non_binary" />
                <Picker.Item label="I do not identify as female" value="i_do_not_identify_as_female" />
              </Picker>
            </Item>
            <Item stackedLabel error={formik.touched.referrer && formik.errors.referrer ? true : false}>
              <Label>
                How did you hear about RTSWS?<Text style={styles.requiredAsterisk}>*</Text>
              </Label>
              <Text style={styles.errorText}>
                {formik.touched.referrer && formik.errors.referrer ? formik.errors.referrer : null}
              </Text>
              <Picker
                mode="dropdown"
                style={{ width: '100%', flex: 2, marginRight: 2 }}
                selectedValue={formik.values.referrer}
                onValueChange={formik.handleChange('referrer')}
              >
                <Picker.Item label="" value="" key={referrerOptions.length + 1} />
                {referrerOptions.map((option, index) => (
                  <Picker.Item label={option.label} value={option.value} key={index} />
                ))}
              </Picker>
            </Item>
          </Form>
          <Button onPress={() => formik.handleSubmit()} full>
            <Text>SAVE &amp; CONTINUE</Text>
          </Button>
        </Content>
        <AwesomeAlert
          show={alertIsVisible}
          showProgress={false}
          title={alertTitle}
          message={alertMessage}
          closeOnHardwareBackPress={false}
          showConfirmButton
          confirmText="OK"
          confirmButtonColor="#5cb85c"
          alertContainerStyle={{ height: 'auto', minHeight: 700, position: 'absolute', left: 0 }}
          onConfirmPressed={() => {
            setAlertIsVisible(false);
          }}
        />
      </ScrollView>
    </Container>
  );
}
