import { Feather } from '@expo/vector-icons';
import * as WebBrowser from 'expo-web-browser';
import { Button, Text, View } from 'native-base';
import React from 'react';
import { Image, ScrollView, StyleSheet } from 'react-native';
import { colors } from '../styles';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 40,
  },
  copy: {
    color: colors.primary,
    fontSize: 16,
    textAlign: 'justify',
  },
  topContainer: {
    width: '95vw',
  },
  copyContainer: {
    alignItems: 'center',
    padding: 20,
    marginTop: 10,
    marginBottom: 10,
  },
  listContainer: {
    alignItems: 'flex-start',
    padding: 20,
    marginTop: 10,
    marginBottom: 10,
  },
  image: {
    resizeMode: 'cover',
    width: '95vw',
    height: '80%',
  },
  buttonContainer: {
    paddingBottom: 40,
  },
  header: {
    paddingTop: 25,
    paddingBottom: 25,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    flex: 1,
  },
  headerText: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginLeft: 20,
  },
  headerButton: {
    marginLeft: 11,
    marginRight: 11,
  },
  subHeader: {
    fontWeight: 'bold',
    marginBottom: 10,
  },
  list: {
    color: colors.primary,
    fontSize: 14,
  },
});

export default function AboutScreen({ navigation }) {
  const _handlePressButtonAsync = async (url: string) => {
    await WebBrowser.openBrowserAsync(url);
  };

  return (
    <ScrollView style={{ backgroundColor: '#fff' }}>
      <View style={styles.container}>
        <View style={styles.header}>
          <View style={styles.headerButton}>
            <Button transparent onPress={() => navigation.navigate('Tabs')}>
              <Feather name="x" size={25} style={{ marginLeft: 10 }} />
            </Button>
          </View>
          <Text style={styles.headerText}>About RTSWS</Text>
        </View>
        <View style={styles.topContainer}>
          <Image source={require('../../assets/images/RTSWS_Jumping_rectangle.jpg')} style={styles.image} />
          <View style={styles.copyContainer}>
            <Text style={styles.copy}>
              Rock The Street, Wall Street is a financial and investment literacy program specifically designed to spark
              the interest of a diverse population of high school girls into one of the M in STEM careers - finance.
            </Text>
          </View>

          <Image source={require('../../assets/images/two_girls_tees.jpg')} style={styles.image} />
          <View style={styles.listContainer}>
            <Text style={styles.subHeader}>RTSWS has 4 components: </Text>
            <Text style={styles.list}>
              1) Financial &amp; Investment Literacy Workshops (Fall) {'\n'}2) Wall Street Experience Field Trip (Fall)
              {'\n'}3) Mentoring (Spring){'\n'}4) Job Board &amp; Alumnae Connections (Post Graduation)
            </Text>
          </View>
          <View style={styles.buttonContainer}>
            <Button onPress={() => _handlePressButtonAsync('https://rockthestreetwallstreet.com')}>
              <Text style={{ marginLeft: 'auto', marginRight: 'auto' }}>Visit Our Website</Text>
            </Button>
          </View>
        </View>
      </View>
    </ScrollView>
  );
}
