import { APP_ENV, SENTRY_DEBUG, SENTRY_DSN } from '@env';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { StyleProvider } from 'native-base';
import React, { useCallback } from 'react';
import { View } from 'react-native';
import { Provider } from 'react-redux';
import * as Sentry from 'sentry-expo';
import { Nav } from './src/navigation';
import store from './src/store';
import getTheme from './src/styles/native-base-theme/components';
import material from './src/styles/native-base-theme/variables/material';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: APP_ENV,
  enableInExpoDevelopment: true,
  debug: SENTRY_DEBUG, // Sentry will try to print out useful debugging information if something goes wrong with sending an event. Set this to `false` in production.
});

SplashScreen.preventAutoHideAsync();

export default function App() {
  const [fontsLoaded] = useFonts({
    Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),
    Roboto: require('native-base/Fonts/Roboto.ttf'),
    Ionicons: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Ionicons.ttf'),
    MaterialIcons: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialIcons.ttf'),
    FontAwesome: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/FontAwesome.ttf'),
    MaterialCommunityIcons: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'),
    Entypo: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Entypo.ttf'),
    Feather: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Feather.ttf'),
    EvilIcons: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/EvilIcons.ttf'),
    AntDesign: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/AntDesign.ttf'),
    Foundation: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Foundation.ttf'),
    Octicons: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Octicons.ttf'),
    Zocial: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Zocial.ttf'),
  });

  const onLayoutRootView = useCallback(async () => {
    console.log('Fonts loaded?', fontsLoaded);

    if (fontsLoaded) {
      await SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);

  if (!fontsLoaded) {
    return null;
  }

  return (
    <View onLayout={onLayoutRootView} style={{ width: '100%', height: '100%' }}>
      <Provider store={store}>
        <StyleProvider style={getTheme(material as any)}>
          <Nav />
        </StyleProvider>
      </Provider>
    </View>
  );
}
