import moment from 'moment-timezone';
import { Body, Card, CardItem, Container, Content, Left, Text } from 'native-base';
import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import NotificationLink from '../../components/NotificationLink';
import { State } from '../../store/types';
import rootStyles, { colors } from '../../styles';
import { SplashScreen } from '../SplashScreen';

const styles = StyleSheet.create({
  content: {
    paddingTop: 5,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 5,
  },
  titleText: {
    fontWeight: 'bold',
  },
  text: {
    fontSize: 14,
  },
  urgentTitleText: {
    fontWeight: 'bold',
    color: colors.red,
  },
  urgentText: {
    fontSize: 14,
    color: colors.red,
  },
});

export default function NotificationDetailScreen({ route }) {
  const { userNotifications } = useSelector((state: State) => state.notification);

  const { id } = route.params;

  const userNotification = useMemo(() => {
    return userNotifications.find((un) => un.id === id);
  }, [id, userNotifications]);

  const notification = userNotification?.notification;

  const getDateTimeFromString = (str) => {
    return moment(str).format('MMMM Do YYYY h:mm A');
  };

  if (!notification) {
    return <SplashScreen />;
  }

  return (
    <Container>
      <Content style={styles.content}>
        <Card transparent>
          <CardItem style={rootStyles.accentBorder}>
            <Left>
              <Body>
                <Text style={notification.urgency ? styles.urgentTitleText : styles.titleText}>
                  {notification.subject}
                </Text>
                <Text style={notification.urgency ? styles.urgentText : styles.text} note>
                  {getDateTimeFromString(notification.createdAt)}
                </Text>
              </Body>
            </Left>
          </CardItem>
          <CardItem>
            <Left>
              <Body>
                <Text style={notification.urgency ? styles.urgentText : styles.text}>{notification.message}</Text>
                <NotificationLink notification={notification} />
              </Body>
            </Left>
          </CardItem>
        </Card>
      </Content>
    </Container>
  );
}
