import { DeviceActionTypes, DeviceState, SET_DEVICE, SET_DEVICES, UPDATE_ERROR, UPDATE_LOADING } from './types';

const initialState: DeviceState = {
  currentDevice: null,
  devices: [],
  loading: false,
};

export default function resourceReducer(state = initialState, action: DeviceActionTypes): DeviceState {
  switch (action.type) {
    case UPDATE_LOADING:
      return { ...state, loading: action.newValue };
    case SET_DEVICE:
      return { ...state, currentDevice: action.currentDevice };
    case SET_DEVICES:
      return { ...state, devices: action.devices };
    case UPDATE_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
}
