import { API_URL } from '@env';
import { CalendarEvent, google, outlook } from 'calendar-link';
import moment from 'moment-timezone';
import { Button, Text, View } from 'native-base';
import React from 'react';
import { Linking } from 'react-native';
import { colors } from '../styles';

export default function CalendarLinks({ programEvent, timezone }) {
  const downloadFile = () => {
    Linking.openURL(API_URL + '/events/' + programEvent.sfid);
  };

  const openGoogle = () => {
    const event: CalendarEvent = getEvent();
    Linking.openURL(google(event));
  };

  const openOutlook = () => {
    const event: CalendarEvent = getEvent();
    Linking.openURL(outlook(event));
  };

  const getEvent = (): CalendarEvent => {
    return {
      title: programEvent.volunteerJob ? programEvent.volunteerJob.name : 'Rock The Street, Wall Street Event',
      description: programEvent.description,
      location: programEvent.volunteerJob
        ? programEvent.volunteerJob.locationInformation
        : 'Check with your RTSWS instructor',
      start: getStartFromString(programEvent.startDateTime),
      end: getEndFromString(programEvent.startDateTime, programEvent.duration),
      busy: true,
      url: 'http://www.rockthestreetwallstreet.com/',
    };
  };

  const getStartFromString = (startDateTime: string) => {
    return moment(startDateTime).tz(timezone).format();
  };

  const getEndFromString = (startDateTime: string, duration: number) => {
    const startMoment = moment(startDateTime).tz(timezone);
    return startMoment.add(duration, 'hours').format();
  };

  return (
    <View style={{ paddingTop: 5 }}>
      <Button small transparent onPress={() => downloadFile()}>
        <Text style={{ color: colors.secondary, fontSize: 12, paddingLeft: 0, paddingRight: 0 }}>Download Event</Text>
      </Button>
      <Button small transparent onPress={() => openGoogle()}>
        <Text style={{ color: colors.secondary, fontSize: 12, paddingLeft: 0, paddingRight: 0 }}>Add to Google</Text>
      </Button>
      <Button small transparent onPress={() => openOutlook()}>
        <Text style={{ color: colors.secondary, fontSize: 12, paddingLeft: 0, paddingRight: 0 }}>Add to Outlook</Text>
      </Button>
    </View>
  );
}
