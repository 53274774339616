import { Setting } from '../../models';
import { settingService } from '../../services';
import { ThunkResult } from '../types';
import { SettingActionTypes, SET_SETTING, SET_SETTINGS, UPDATE_ERROR, UPDATE_LOADING } from './types';

export function updateLoading(newValue: boolean): SettingActionTypes {
  return {
    type: UPDATE_LOADING,
    newValue,
  };
}

export function setSetting(currentSetting: Setting): SettingActionTypes {
  return {
    type: SET_SETTING,
    currentSetting,
  };
}

export function setSettings(settings: Setting[]): SettingActionTypes {
  return {
    type: SET_SETTINGS,
    settings,
  };
}

export function updateSettingError(error?: string): SettingActionTypes {
  return {
    type: UPDATE_ERROR,
    error,
  };
}

export function getSettings(): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(updateLoading(true));
    return settingService
      .getSettings()
      .then((settings) => {
        dispatch(setSettings(Array.isArray(settings) ? settings : []));
      })
      .catch((error) => {
        dispatch(updateSettingError(error.message));
      })
      .finally(() => dispatch(updateLoading(false)));
  };
}

export const settingActions = {
  getSettings,
  setSetting,
  setSettings,
  updateSettingError,
  updateLoading,
};
