import { Alert, Linking, Platform } from 'react-native';
import { Campaign, Setting } from '../models';

export function openMaps(address: string) {
  if (address) {
    let baseUrl = 'http://maps.google.com/maps?daddr=';

    if (Platform.OS === 'ios') {
      baseUrl = 'http://maps.apple.com/maps?daddr=';
    }

    Linking.openURL(`${baseUrl}${encodeURIComponent(address)}`).catch(() => {
      Alert.alert('Maps Error', 'Unable to open address in mapping application.');
    });
  }
}

export function openPhone(phone: string) {
  if (phone) {
    Linking.openURL(`tel:${phone}`).catch(() => {
      Alert.alert('Dialing Error', 'Unable to initiate phone call.');
    });
  }
}

export function openEmail(email: string) {
  if (email) {
    Linking.openURL(`mailto:${email}?subject=Hello`).catch(() => {
      Alert.alert('Email Error', 'Unable to launch email editor.');
    });
  }
}

export function getValueForSlug(settings: Setting[], slug: string): string {
  const matches = settings.filter((setting) => {
    return setting.slug === slug;
  });

  return matches.length > 0 ? matches[0].value : '';
}

export function getCampaignNameElement(campaign: Campaign, element: string): string {
  const nameRegexPattern = /^(\w{4})\/(.+)\/(.+),.([SVPM]{1}\w+$)/i;
  const elements = campaign.name.match(nameRegexPattern);

  if (!elements) return '';

  switch (element) {
    case 'year':
      return elements[1] || '';
    case 'city':
      return elements[2] || '';
    case 'school':
      return elements[3] || '';
    case 'program':
      return elements[4] || '';
    default:
      break;
  }

  return '';
}

export const generateShortUUID = () => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let uuid = '';

  for (let i = 0; i < 6; i++) {
    uuid += chars[Math.floor(Math.random() * chars.length)];
  }

  return uuid;
};
