export const RegistrationTypes = {
  STUDENT: 'student',
  VOLUNTEER: 'volunteer',
};

export const RegistrationStatus = {
  PENDING: 'pending',
  COMPLETE: 'complete',
  ERROR: 'error',
};

export const REGISTRATION_RACE_AMERICAN_INDIAN = 'American Indian or Alaska Native';
export const REGISTRATION_RACE_ASIAN = 'Asian';
export const REGISTRATION_RACE_BLACK = 'Black or African American';
export const REGISTRATION_RACE_HISPANIC = 'Hispanic or Latino';
export const REGISTRATION_RACE_NATIVE_HAWAIIAN = 'Native Hawaiian or Other Pacific Islander';
export const REGISTRATION_RACE_WHITE = 'White';
export const REGISTRATION_RACE_TWO_OR_MORE = 'Two or More Races';
export const REGISTRATION_RACE_NO_DISCLOSURE = 'Choose Not to Disclose';
export const REGISTRATION_OTHER = 'other';
