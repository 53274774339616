import moment, { Moment } from 'moment-timezone';
import { Card, CardItem, Text } from 'native-base';
import React from 'react';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  alertText: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 14,
  },

  noteText: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 14,
  },
});

export default function BgCheckAlert({ contact }) {
  const validateBgCheck = (): boolean => {
    if (!contact.bgCheckDate) return false;

    const todayMoment: Moment = moment().tz('UTC').startOf('day');
    const julyFirstDayOfYear: number = todayMoment.isLeapYear() ? 183 : 182;

    let earliestValidYear: number;

    if (todayMoment.dayOfYear() < julyFirstDayOfYear) {
      // If today is before the start of the current year's fiscal year, set the earliest valid year to 3 years ago.
      earliestValidYear = todayMoment.subtract(3, 'years').year();
    } else {
      // If today is after the start of the current year's fiscal year, set the earliest valid year to 2 years ago.
      earliestValidYear = todayMoment.subtract(2, 'years').year();
    }

    // Earliest valid moment is July 1 (fiscal year beginning) of the earliest valid year
    const earliestValidMoment = moment(earliestValidYear + '-07-01', 'YYYY-MM-DD')
      .tz('UTC')
      .startOf('day');
    const bgCheckMoment = moment(contact.bgCheckDate);
    return bgCheckMoment.isSameOrAfter(earliestValidMoment);
  };

  if (validateBgCheck()) {
    return null;
  }

  return (
    <Card>
      <CardItem cardBody>
        <Text style={styles.alertText}>
          You need a background check to complete your registration and participate with RTSWS. In the next 48 hours you
          will receive an email inviting you to complete your background check through Verified First. Please check your
          spam box if you do not receive the email.
        </Text>
      </CardItem>
      <CardItem>
        <Text style={styles.noteText} note>
          This notification will disappear when your background check is returned and approved.
        </Text>
      </CardItem>
    </Card>
  );
}
