import { Card, CardItem, Text } from 'native-base';
import React from 'react';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  alertText: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 14,
  },

  noteText: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 14,
  },
});

export default function TrainingAlert({ contact }) {
  const validateTrainingCompleted = (): boolean => {
    if (!contact.trainingCompleted) return false;
    return contact.trainingCompleted;
  };

  if (validateTrainingCompleted()) {
    return null;
  }

  return (
    <Card>
      <CardItem cardBody>
        <Text style={styles.alertText}>
          Volunteer Training needs to be completed.
          <br />
          Access at{' '}
          <a href="https://rockthestreetwallstreet.com/curriculum-training" target="_blank">
            https://rockthestreetwallstreet.com/curriculum-training
          </a>
          <br />
          Password: Training2022
        </Text>
      </CardItem>
      <CardItem>
        <Text style={styles.noteText} note>
          After completion, this notice will be removed within 3 days.
        </Text>
      </CardItem>
    </Card>
  );
}
