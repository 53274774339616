import { Resource } from '../../models';
import { resourceService } from '../../services';
import { ThunkResult } from '../types';
import { ResourceActionTypes, SET_RESOURCE, SET_RESOURCES, UPDATE_ERROR, UPDATE_LOADING } from './types';

export function updateLoading(newValue: boolean): ResourceActionTypes {
  return {
    type: UPDATE_LOADING,
    newValue,
  };
}

export function setResource(currentResource: Resource): ResourceActionTypes {
  return {
    type: SET_RESOURCE,
    currentResource,
  };
}

export function setResources(resources: Resource[]): ResourceActionTypes {
  return {
    type: SET_RESOURCES,
    resources,
  };
}

export function updateResourceError(error?: string): ResourceActionTypes {
  return {
    type: UPDATE_ERROR,
    error,
  };
}

export function getResources(): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(updateLoading(true));
    return resourceService
      .getResources()
      .then((resources) => {
        dispatch(setResources(Array.isArray(resources) ? resources : []));
      })
      .catch((error) => {
        dispatch(updateResourceError(error.message));
      })
      .finally(() => dispatch(updateLoading(false)));
  };
}

export const resourceActions = {
  getResources,
  setResource,
  setResources,
  updateResourceError,
  updateLoading,
};
