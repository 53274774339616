import Checkbox from 'expo-checkbox';
import * as WebBrowser from 'expo-web-browser';
import moment from 'moment-timezone';
import { Body, Button, Card, CardItem, Container, Content, Icon, Left, ListItem, Text, View } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Image, ImageBackground, ScrollView, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import { RefreshControl } from 'react-native-web-refresh-control';
import { useDispatch, useSelector } from 'react-redux';
import { LogoBar } from '../components/';
import BgCheckAlert from '../components/BgCheckAlert';
import CalendarLinks from '../components/CalendarLinks';
import ConvenienceMessage from '../components/ConvenienceMessage';
import TrainingAlert from '../components/TrainingAlert';
import { CAMPAIGN_TYPE_PROTEGE } from '../constants/Campaign';
import { RegistrationStatus } from '../constants/Registration';
import { RoleNames } from '../constants/Roles';
import { useRegisterForNotifications } from '../hooks';
import { Campaign, ProgramEvent, User } from '../models';
import { updateCurrentUser } from '../store/auth/actions';
import { State } from '../store/types';
import rootStyles, { colors } from '../styles';
import { getCampaignNameElement } from '../utils';
import { SplashScreen } from './SplashScreen';

const styles = StyleSheet.create({
  content: {
    paddingTop: 90,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 55,
  },
  topButton: {
    marginTop: 5,
    marginLeft: 2,
    marginRight: 2,
  },
  topButtonText: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  alertButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#fff',
    paddingTop: 4,
    paddingBottom: 4,
  },

  alertButtonText: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 5,
  },

  alertButtonTextPrimary: {
    color: '#000',
    fontSize: 14,
  },

  alertButtonTextSecondary: {
    color: colors.red,
    fontSize: 14,
  },

  alertCard: {
    backgroundColor: colors.red,
  },

  infoCard: {
    backgroundColor: colors.primary,
  },

  infoCardText: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 5,
  },

  infoCardTextPrimary: {
    color: colors.white,
    fontSize: 14,
  },

  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    alignItems: 'center',
    width: '100%',
  },

  topContainer: {
    flex: 1,
    display: 'flex',
    width: '100vw',
  },

  middleContainer: {
    flex: 3,
    display: 'flex',
    width: '80vw',
  },

  bottomContainer: {
    justifyContent: 'flex-end',
    margin: 20,
    padding: 10,
  },
  text: {
    fontSize: 24,
    fontWeight: 'bold',
    marginTop: 10,
    textAlign: 'center',
    paddingLeft: 30,
    paddingRight: 30,
  },
  subtext: {
    marginTop: 20,
    textAlign: 'center',
    fontSize: 14,
    paddingLeft: 30,
    paddingRight: 30,
  },
  notificationTitleText: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  notificationText: {
    fontSize: 14,
  },
  notificationUrgentTitleText: {
    fontWeight: 'bold',
    color: colors.red,
    fontSize: 14,
  },
  notificationUrgentText: {
    fontSize: 14,
    color: colors.red,
  },
  image: {
    flex: 1,
    resizeMode: 'contain',
  },
  welcomeImage: {
    flex: 1,
    paddingTop: 10,
    paddingBottom: 10,
  },
  welcomeMessage: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 50,
    paddingRight: 50,
  },
  logoImage: {
    flex: 1,
    resizeMode: 'contain',
    maxHeight: 100,
    marginTop: 10,
    marginBottom: 10,
  },
});

export default function HomeScreen({ navigation }) {
  const dispatch = useDispatch();

  const { registerForNotifications } = useRegisterForNotifications();

  const authState = useSelector((state: State) => state.auth);
  const { loading: authLoading, currentUser, error: authError } = authState;

  const campaignState = useSelector((state: State) => state.campaign);
  const { loading: campaignLoading, currentCampaign, error: campaignError } = campaignState;

  const programEventState = useSelector((state: State) => state.programEvent);
  const { loading: programEventLoading, programEvents } = programEventState;

  const settingState = useSelector((state: State) => state.setting);
  const { loading: settingLoading } = settingState;

  const notificationState = useSelector((state: State) => state.notification);
  const { loading: notificationLoading, userNotifications } = notificationState;

  const registrationState = useSelector((state: State) => state.registration);
  const { registrationSubmitted: registrationSubmitted } = registrationState;

  const [firstProgramEvent, setFirstProgramEvent] = useState<ProgramEvent | null>(null);
  const [fifthProgramEvent, setFifthProgramEvent] = useState<ProgramEvent | null>(null);
  const [futureEvents, setFutureEvents] = useState<ProgramEvent[]>([]);
  const [turnOffWelcome, setTurnOffWelcome] = useState(false);
  const [welcomeIsDismissed, setWelcomeIsDismissed] = useState(false);

  const [refreshing, setRefreshing] = React.useState(false);

  const getProgramEventDateFromString = (str) => {
    const timezone = currentCampaign?.timezone || 'UTC';
    return moment(str).tz(timezone).format('MMMM Do YYYY');
  };

  const getProgramEventTimeFromString = (str) => {
    const timezone = currentCampaign?.timezone || 'UTC';
    return moment(str).tz(timezone).format('h:mm A z');
  };

  const getDateTimeFromString = (str) => {
    return moment(str).format('MMMM Do YYYY h:mm A z');
  };

  const dismissWelcome = async () => {
    if (turnOffWelcome) {
      const updatedUser = { ...currentUser, showWelcome: false };
      dispatch(updateCurrentUser(updatedUser));
    }

    setWelcomeIsDismissed(true);
  };

  const bodyLength: number = 40;

  const truncate = (str) => {
    return str.length > bodyLength ? str.substr(0, bodyLength - 1) + '...' : str;
  };

  const processEvents = () => {
    if (programEvents.length > 0) {
      setFirstProgramEvent(programEvents[0]);

      if (programEvents.length > 4) {
        setFifthProgramEvent(programEvents[4]);
      }

      setFutureEvents(
        programEvents.filter((programEvent) => {
          const timezone = currentCampaign?.timezone || 'UTC';
          return moment(programEvent.startDateTime).tz(timezone).isAfter(new Date());
        }),
      );
    }
  };

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    window.location.reload();
    setRefreshing(false);
  }, []);

  useEffect(() => {
    registerForNotifications();
  }, [registerForNotifications]);

  useEffect(() => {
    processEvents();
  }, [programEvents]);

  const _handlePressButtonAsync = async (url?: string) => {
    if (url) {
      await WebBrowser.openBrowserAsync(url);
    }
  };

  const campaignIsProtege = (campaign: Campaign | null): boolean => {
    if (campaign?.name) {
      return getCampaignNameElement(campaign, 'program') === CAMPAIGN_TYPE_PROTEGE;
    }

    return false;
  };

  const userIsMentorInterested = (user: User): boolean => {
    if (!user.contact) {
      return false;
    }

    return user.contact.mentorInterested === 'Yes';
  };

  const isFirstEventTime = () => {
    if (firstProgramEvent) {
      const timezone = currentCampaign?.timezone || 'UTC';
      const momentStartDateTime = moment(firstProgramEvent.startDateTime).tz(timezone);
      const momentStartDateTimePlusSixtyMinutes = moment(firstProgramEvent.startDateTime)
        .tz(timezone)
        .add(60, 'minutes');
      const momentNow = moment().tz(timezone);
      return (
        momentStartDateTime.isSameOrBefore(momentNow) && momentStartDateTimePlusSixtyMinutes.isSameOrAfter(momentNow)
      );
    }
  };

  const isFifthEventTime = () => {
    if (fifthProgramEvent) {
      const timezone = currentCampaign?.timezone || 'UTC';
      const momentStartDateTime = moment(fifthProgramEvent.startDateTime).tz(timezone);
      const momentStartDateTimePlusSixtyMinutes = moment(fifthProgramEvent.startDateTime)
        .tz(timezone)
        .add(60, 'minutes');
      const momentNow = moment().tz(timezone);
      return (
        momentStartDateTime.isSameOrBefore(momentNow) && momentStartDateTimePlusSixtyMinutes.isSameOrAfter(momentNow)
      );
    }
  };

  const todayIsFieldTripDate = () => {
    if (currentCampaign?.fieldTripDate) {
      const timezone = currentCampaign?.timezone || 'UTC';
      const momentFieldTripDate = moment(currentCampaign?.fieldTripDate).tz(timezone);
      return momentFieldTripDate.isSame(new Date(), 'day');
    }
  };

  const todayIsAfterOctoberOfCurrentCampaignYear = (): boolean => {
    const timezone = currentCampaign?.timezone || 'UTC';
    const momentToday = moment().tz(timezone);
    const acceptableMonths = [0, 1, 2, 3, 4, 10, 11];
    return acceptableMonths.includes(momentToday.month());
  };

  const todayIsAfterMayOfCurrentYear = (): boolean => {
    const timezone = currentCampaign?.timezone || 'UTC';
    const currentDate = new Date();
    const momentCurrentYearJuneFirst = moment(`${currentDate.getFullYear()}-06-01`).tz(timezone);
    const momentToday = moment().tz(timezone);
    return momentCurrentYearJuneFirst.isSameOrBefore(momentToday);
  };

  const todayIsAfterTheCampaignSecondVolunteerShift = (campaign: Campaign | null): boolean => {
    //// • the current date is before the date of the current campaign's second volunteer shift date
    const timezone = campaign?.timezone || 'UTC';
    const momentToday = moment().tz(timezone);
    const unacceptableMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    return unacceptableMonths.includes(momentToday.month());
  };

  const currentUserIsStudent = (): boolean => {
    return currentUser?.role?.name === RoleNames.STUDENT;
  };

  const currentUserIsEligibleForReenrollment = (): boolean => {
    return !!currentUser?.eligibleForReenrollment && registrationSubmitted === false;
  };

  const NotificationCard = ({ userNotification }) => (
    <TouchableWithoutFeedback
      onPress={() =>
        navigation.navigate('NotificationDetail', {
          id: userNotification.id,
        })
      }
    >
      <Card>
        <CardItem>
          <Left>
            <Icon
              type="MaterialIcons"
              style={{
                color: userNotification.notification.urgency ? colors.red : colors.primary,
                marginLeft: 0,
                marginRight: 0,
              }}
              name="notifications"
            />
            <Body>
              <Text
                style={userNotification.notification.urgency ? styles.notificationUrgentText : styles.notificationText}
                note
              >
                {getDateTimeFromString(userNotification.notification.createdAt)}
              </Text>
              <Text
                style={
                  userNotification.notification.urgency
                    ? styles.notificationUrgentTitleText
                    : styles.notificationTitleText
                }
              >
                {userNotification.notification.urgency ? 'URGENT: ' : null}
                {userNotification.notification.subject}
              </Text>
            </Body>
          </Left>
        </CardItem>
        <CardItem style={{ paddingTop: 0 }}>
          <Left>
            <Body>
              <Text
                style={userNotification.notification.urgency ? styles.notificationUrgentText : styles.notificationText}
              >
                {truncate(userNotification.notification.message)}
              </Text>
            </Body>
          </Left>
        </CardItem>
      </Card>
    </TouchableWithoutFeedback>
  );

  const UserNotification = (userNotification, index) => {
    return userNotification.notification ? <NotificationCard userNotification={userNotification} key={index} /> : null;
  };

  const UpcomingClassCard = () => {
    if (!currentUser) {
      return null;
    }

    let message: string | undefined;

    if (currentUser.registration && currentUser.registration.status === RegistrationStatus.PENDING) {
      message = 'Your registration is still being processed.';
    }

    if (!message && futureEvents.length < 1) {
      message = 'No Upcoming Events';
    }

    if (message) {
      return (
        <Card>
          <CardItem>
            <Left>
              <Body>
                <Text>{message}</Text>
                <Text note>Check back soon...</Text>
              </Body>
            </Left>
          </CardItem>
          <CardItem cardBody>
            <Image
              source={require('../../assets/images/red_green_ticker_panel.png')}
              style={{ height: 100, flex: 1 }}
            />
          </CardItem>
        </Card>
      );
    }

    const nextProgramEvent = futureEvents[0];

    return (
      <TouchableWithoutFeedback onPress={() => navigation.navigate('ProgramEventDetail', { id: nextProgramEvent.id })}>
        <Card>
          <CardItem>
            <Left>
              <Body>
                <Text style={rootStyles.accentBorder}>{nextProgramEvent.volunteerJob.name}</Text>
                <Text note>{nextProgramEvent.description}</Text>
              </Body>
            </Left>
          </CardItem>
          <CardItem cardBody>
            <Image
              source={{
                uri: 'https://images.unsplash.com/photo-1503428593586-e225b39bddfe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80',
              }}
              style={{ height: 100, flex: 1 }}
            />
          </CardItem>
          <CardItem>
            <Left>
              <Body>
                <Text>{getProgramEventDateFromString(nextProgramEvent.startDateTime)}</Text>
                <Text note>{getProgramEventTimeFromString(nextProgramEvent.startDateTime)}</Text>
                <CalendarLinks programEvent={nextProgramEvent} timezone={currentCampaign?.timezone} />
              </Body>
            </Left>
          </CardItem>
        </Card>
      </TouchableWithoutFeedback>
    );
  };

  const ReenrollmentButton = () => {
    if (!currentUserIsEligibleForReenrollment()) return null;

    const currentYear = new Date().getFullYear();
    const nextSchoolYearString = `${currentYear}/${currentYear + 1}`;

    return currentUserIsStudent() ? (
      <Card>
        <CardItem style={styles.alertCard}>
          <Button style={styles.alertButton} onPress={() => navigation.navigate('Reenrollment')}>
            <Text style={styles.alertButtonText}>
              <Text style={styles.alertButtonTextSecondary}>CLICK HERE </Text>
              <Text style={styles.alertButtonTextPrimary}>
                TO ENROLL{'\n'}FOR SCHOOL YEAR {nextSchoolYearString}
              </Text>
            </Text>
          </Button>
        </CardItem>
      </Card>
    ) : (
      <Card>
        <CardItem style={styles.alertCard}>
          <Button style={styles.alertButton} onPress={() => navigation.navigate('VolunteerReenrollment')}>
            <Text style={styles.alertButtonText}>
              <Text style={styles.alertButtonTextSecondary}>CLICK HERE </Text>
              <Text style={styles.alertButtonTextPrimary}>
                TO ENROLL{'\n'}FOR SCHOOL YEAR {nextSchoolYearString}
              </Text>
            </Text>
          </Button>
        </CardItem>
      </Card>
    );
  };

  const SpringEnrollmentButton = () => {
    if (!currentUser) {
      return null;
    }

    if (!currentUserIsStudent()) return null;

    if (userIsMentorInterested(currentUser)) return null;

    if (campaignIsProtege(currentCampaign)) return null;

    if (currentCampaign?.name) {
      if (!todayIsAfterOctoberOfCurrentCampaignYear()) return null;
    } else {
      if (todayIsAfterMayOfCurrentYear()) return null;
    }

    return (
      <Card>
        <CardItem style={styles.alertCard}>
          <Button style={styles.alertButton} onPress={() => navigation.navigate('SpringEnrollment')}>
            <Text style={styles.alertButtonText}>
              <Text style={styles.alertButtonTextSecondary}>CLICK HERE </Text>
              <Text style={styles.alertButtonTextPrimary}>TO ENROLL{'\n'}FOR SPRING MENTORSHIP</Text>
            </Text>
          </Button>
        </CardItem>
      </Card>
    );
  };

  const SpringEnrollmentConfirmationBanner = () => {
    if (!currentUser) {
      return null;
    }

    if (!currentUserIsStudent()) return null;

    if (!userIsMentorInterested(currentUser)) return null;

    if (campaignIsProtege(currentCampaign)) {
      if (todayIsAfterTheCampaignSecondVolunteerShift(currentCampaign)) return null;
    }

    return (
      <Card>
        <CardItem style={styles.infoCard}>
          <Text style={styles.infoCardText}>
            <Text style={styles.infoCardTextPrimary}>YOU ARE ENROLLED FOR SPRING MENTORSHIP!</Text>
          </Text>
        </CardItem>
      </Card>
    );
  };

  if (!currentUser || authLoading || programEventLoading || campaignLoading || notificationLoading) {
    return <SplashScreen />;
  }

  if (currentUser.showWelcome && !welcomeIsDismissed) {
    if (currentUser.role && currentUser.role.name === RoleNames.STUDENT) {
      return (
        <View style={styles.container}>
          <View style={styles.topContainer}>
            <Text style={styles.text}>Welcome to Rock The Street, Wall Street, {currentUser.firstName}!</Text>
            {currentUser.registration && currentUser.registration.status === RegistrationStatus.PENDING ? (
              <Text style={styles.subtext}> Your registration is being processed.</Text>
            ) : null}
            {currentUser.registeredSchoolAccount && currentUser.registeredSchoolAccount.logoUrl ? (
              <Image source={{ uri: currentUser.registeredSchoolAccount.logoUrl }} style={styles.logoImage} />
            ) : null}
            <Image style={styles.welcomeImage} source={require('../../assets/images/red_green_ticker_panel.png')} />
          </View>

          <View style={styles.bottomContainer}>
            <ListItem>
              <Checkbox
                value={turnOffWelcome}
                onValueChange={(value) => setTurnOffWelcome(value)}
                color={turnOffWelcome ? colors.primary : undefined}
              />
              <Body>
                <Text>Don't Show This Again</Text>
              </Body>
            </ListItem>
            <Button onPress={() => dismissWelcome()} full>
              <Text>CLOSE</Text>
            </Button>
          </View>
        </View>
      );
    }

    if (currentUser.role && currentUser.role.name === RoleNames.VOLUNTEER) {
      return (
        <View style={styles.container}>
          <View style={styles.topContainer}>
            <Text style={styles.text}>Welcome to Rock The Street, Wall Street, {currentUser.firstName}!</Text>
            {currentUser.registration && currentUser.registration.status === RegistrationStatus.PENDING ? (
              <Text style={styles.subtext}> Your volunteer registration is being processed.</Text>
            ) : null}
            {currentUser.companyAccount && currentUser.companyAccount.logoUrl ? (
              <Image source={{ uri: currentUser.companyAccount.logoUrl }} style={styles.logoImage} />
            ) : null}
            <Image style={styles.welcomeImage} source={require('../../assets/images/red_green_ticker_panel.png')} />
          </View>

          <View style={styles.bottomContainer}>
            <ListItem>
              <Checkbox
                value={turnOffWelcome}
                onValueChange={(value) => setTurnOffWelcome(value)}
                color={turnOffWelcome ? colors.primary : undefined}
              />
              <Body>
                <Text>Don't Show This Again</Text>
              </Body>
            </ListItem>
            <Button onPress={() => dismissWelcome()} full>
              <Text>CLOSE</Text>
            </Button>
          </View>
        </View>
      );
    }
  }

  return (
    <Container style={{ overflow: 'hidden' }}>
      <ImageBackground
        source={require('../../assets/images/homepage_background_white.png')}
        style={{}}
        imageStyle={{
          resizeMode: 'contain',
          alignSelf: 'flex-start',
          display: 'flex',
          flexDirection: 'row',
          height: '80px',
          position: 'absolute',
          top: '5px',
          left: 0,
          right: 0,
          bottom: 0,
        }}
      ></ImageBackground>
      <ScrollView refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
        <Content style={styles.content}>
          <ConvenienceMessage showMessage={window.matchMedia('(display-mode: browser)').matches} />

          {currentUser.role.name === RoleNames.VOLUNTEER && currentUser.contact ? (
            <>
              <BgCheckAlert contact={currentUser.contact} />
              <TrainingAlert contact={currentUser.contact} />
            </>
          ) : null}

          {isFirstEventTime() && currentUserIsStudent() ? (
            <Button
              style={styles.topButton}
              onPress={() => _handlePressButtonAsync(currentCampaign?.preAssessmentLink)}
            >
              <Text style={styles.topButtonText}>Take The Pre-Assessment</Text>
            </Button>
          ) : null}

          {isFifthEventTime() && currentUserIsStudent() ? (
            <Button
              style={styles.topButton}
              onPress={() => _handlePressButtonAsync(currentCampaign?.postAssessmentLink)}
            >
              <Text style={styles.topButtonText}>Take The Post-Assessment</Text>
            </Button>
          ) : null}

          {todayIsFieldTripDate() ? (
            <Button
              style={styles.topButton}
              onPress={() => _handlePressButtonAsync(currentCampaign?.endOfCourseSurvey)}
            >
              <Text style={styles.topButtonText}>Take The Program Survey</Text>
            </Button>
          ) : null}

          <SpringEnrollmentButton />
          <ReenrollmentButton />
          <SpringEnrollmentConfirmationBanner />
          <UpcomingClassCard />

          {userNotifications.slice(0, 1).map((userNotification, index) => (
            <UserNotification key={userNotification.id} userNotification={userNotification} index={index} />
          ))}
        </Content>
      </ScrollView>
      <LogoBar />
    </Container>
  );
}
