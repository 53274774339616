import { ProgramEvent } from '../models';
import BaseService from './BaseService';

export class ProgramEventService extends BaseService {
  async getProgramEvents() {
    return (await this.getJson<ProgramEvent[]>('/volunteers/shifts')).data;
  }
}

export default new ProgramEventService();
