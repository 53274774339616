import {
  AuthActionTypes,
  AuthState,
  AuthStatus,
  UPDATE_ERROR,
  UPDATE_LOADING,
  UPDATE_STATUS,
  UPDATE_TOKEN,
  UPDATE_USER,
} from './types';

const initialState: AuthState = {
  status: AuthStatus.IDLE,
  currentUser: null,
  loading: true,
};

export default function authReducer(state = initialState, action: AuthActionTypes): AuthState {
  switch (action.type) {
    case UPDATE_STATUS:
      return { ...state, status: action.newStatus };
    case UPDATE_LOADING:
      return { ...state, loading: action.newValue };
    case UPDATE_USER:
      return { ...state, currentUser: action.newUser };
    case UPDATE_TOKEN:
      return { ...state, token: action.token };
    case UPDATE_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
}
